
import { format, parseISO } from 'date-fns';
import Rating from 'react-rating';
import DialogEditEvaluate from './DialogEditEvaluate';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const DateTimeFormatting = (createdAtEvaluate) => {
    const date = parseISO(createdAtEvaluate);

    const daysOfWeek = ['Chủ Nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'];
    const dayOfWeekIndex = date.getDay();

    const formattedDate = format(date, "dd/MM/yyyy");
    const formattedTime = format(date, "HH:mm");
    const formattedDayOfWeek = daysOfWeek[dayOfWeekIndex];
    return `${formattedDayOfWeek}, ${formattedDate} lúc ${formattedTime}`;
}

const DataEvaluate = ({ dataShowDetailRating }) => {
    const [visibleEvaluate, setVisibleEvaluate] = useState(false);
    const [dataEdit, setDataEdit] = useState(null);

    const [showMoreButton, setShowMoreButton] = useState(false);
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
    const totalComments = dataShowDetailRating.evaluates.length;
    const [visibleComments, setVisibleComments] = useState(totalComments < 5 ? totalComments : 5); // Số lượng bình luận ban đầu hiển thị
    const [initialLoad, setInitialLoad] = useState(false);

    const onEditEvaluate = (evaluates) => {
        setVisibleEvaluate(true);
        setDataEdit(evaluates);
    }
    const onHideFormEvaluate = () => {
        setVisibleEvaluate(false);
        setDataEdit(null);
    }

    useEffect(() => {
        const scrollTab = document.querySelector('.scroll-data-evaluate');

        if (!scrollTab) {
            return;
        }
        const handleScroll = () => {
            const scrollTop = scrollTab.scrollTop;
            const scrollHeight = scrollTab.scrollHeight;
            const clientHeight = scrollTab.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setHasScrolledToBottom(true);
            } else {
                setHasScrolledToBottom(false);
            }

             // Kiểm tra xem có cần hiển thị nút "Xem thêm" không
             if (!initialLoad) {
                // Chỉ hiển thị nút "Xem thêm" lần đầu tiên
                setInitialLoad(true);
            } else if (visibleComments < totalComments) {
                setShowMoreButton(true);
            } else {
                setShowMoreButton(false);
            }

        };

        scrollTab.addEventListener("scroll", handleScroll);

        return () => {
            scrollTab.removeEventListener("scroll", handleScroll);
        };
    }, [visibleComments, totalComments, initialLoad]);

    const handleShowMoreComments = () => {
        if (visibleComments + 5 >= totalComments) {
            setVisibleComments(totalComments);
            setShowMoreButton(false);
        } else {
            setVisibleComments(visibleComments + 5);
        }
    };  

    const userPer = useSelector((state) => state.authReducer.userData);
    return (
        <div className='evaluate-boxshadow'>
            <div className='p-p-2 evaluate-boxshadow-title'>
                <h5 className='p-pt-2 p-pl-3 p-mb-1'>Đánh giá</h5>
            </div>
            <div className='scroll-data-evaluate'>
                {dataShowDetailRating.evaluates.length > 0 ? (
                    dataShowDetailRating.evaluates.slice(0, visibleComments).map(item => {
                        return (
                            <div key={item.id} 
                                className='p-pt-2 p-pl-3 p-m-4' 
                                style={{ backgroundColor: "#F4F4F4", borderRadius: "20px" }}
                            >
                            <div className='p-d-flex'>
                                <div className='avatar' />
                                <div className='information'>
                                    <p className='p-mb-0' style={{ color: '#79AFE8', fontWeight: '500' }}>
                                        {item.user.fullname}
                                        {item.user_id === userPer.data.id ?
                                            <i className="pi pi-pencil p-ml-2 pointer text-success"
                                            title="Chỉnh sửa đánh giá"
                                            onClick={() => onEditEvaluate(item)} />
                                            : ""
                                        }
                                        <DialogEditEvaluate
                                            visibleEvaluate={visibleEvaluate}
                                            onHideFormEvaluate={onHideFormEvaluate}
                                            dataEdit={dataEdit}
                                        />
                                    </p>
                                    <p className='p-mb-0' style={{ fontSize: "12px" }}>
                                        {DateTimeFormatting(item.created_at)}
                                    </p>
                                    <Rating
                                        className='pointer'
                                        readonly
                                        initialRating={item.stars}
                                        fractions={20}
                                        emptySymbol={<span className="icon">&#9734;</span>}
                                        fullSymbol={<span className="icon full">&#9733;</span>}
                                    />
                                    <div className='content p-mb-2 p-pr-3' style={{ width: '500px', wordWrap: 'break-word' }}>
                                        {item.content}
                                    </div>
                                </div>
                            </div>
                            </div>
                        );
                    })
                ) 
                : 
                (
                    <div>
                        <div className='p-d-flex p-justify-center p-p-3'>
                            Chưa có đánh giá nào về cuộc họp này
                        </div>
                        {/* <div className='p-d-flex p-justify-center'>
                            <i className="pi pi-sign-in pointer p-pb-5" style={{ fontSize: '2.5rem' }} title='Tham gia đánh giá' />
                        </div> */}
                    </div>
                )}
            </div>
            <div className='p-d-flex p-justify-between p-ml-4 p-mr-4'>
                {initialLoad && totalComments > 5 && hasScrolledToBottom && showMoreButton ? (
                    <p className='pointer' onClick={handleShowMoreComments}>Xem thêm đánh giá</p>
                ) : (
                    ""
                )}
                {totalComments > 0 && <p className='p-ml-auto'>{visibleComments}/{totalComments}</p>}
            </div>
        </div>
    );
}

export default DataEvaluate;
