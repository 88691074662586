import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import PropTypes from 'prop-types';
import './import-user-dialog.scss';

function ErrorsImportUserDialog(props) {
    const { user, visible, setVisible } = props;
    let data = [];
    data = user?.errors ? JSON.parse(user.errors) : [];

    const deleteDialogFooter = (
        <React.Fragment>
            <Button
                label="Đóng"
                icon="pi pi-times"
                className="p-button-raised p-button-text p-button-danger"
                onClick={() => setVisible(false)}
            />
        </React.Fragment>
    );
    return (
        <div>
            <Dialog
                visible={visible}
                className="p-dialog-default import-user-dialog"
                header={
                    <strong className="import-user-dialog__header-title">Thông tin lỗi</strong>
                }
                modal
                footer={deleteDialogFooter}
                onHide={() => setVisible(false)}
            >
                {
                    <div class="import-user-dialog__import-file--errors">
                        {data && data.length > 0 && <div>Import file lỗi:</div>}
                        {data &&
                            data?.length > 0 &&
                            data?.map((item) => (
                                <div class="import-user-dialog__import-file--errors-item">
                                    <div>Dòng {item?.row}</div>
                                    {item?.error?.map((error) => (
                                        <div>- {error}</div>
                                    ))}
                                </div>
                            ))}
                    </div>
                }
            </Dialog>
        </div>
    );
}

ErrorsImportUserDialog.propTypes = {
    adData: PropTypes.object,
};
ErrorsImportUserDialog.defaultProps = {
    adData: {
        name: '',
    },
};
export default ErrorsImportUserDialog;
