import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import notification from 'helpers/constant/notification'
import titleDefault from 'helpers/constant/titleDefault'
import React from 'react'

const LocationDialogAddEdit = props => {
    const { showAlert, fetData, visible, onHideDialog, servicePlace, time, typeAd,
        adData, setAdData, errData, setErrData } = props;
    const updateField = (data, field) => {
        setAdData({
            ...adData,
            [field]: data,
        });
    };
    const saveData = async () => {
        let newAdData = { ...adData };
        if (typeAd === 1) {
            try {
                const result = await servicePlace.create(newAdData);
                const { error, message,status } = result;
                if (status === 2) {
                    if (error) {
                        showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                        setErrData(error);
                    } else {
                        showAlert('error', notification.CREATE_FAIL, message);
                    }
                    return;
                }
                if (status === 1) {
                    showAlert('success', notification.CREATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_ERROR);
            }
        } else {
            try {
                const result = await servicePlace.update(newAdData);
                const { error, message,status } = result;
                if (status === 2) {
                    if (error) {
                        showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_VALIDATE);
                        setErrData(error);
                    } else {
                        showAlert('error', notification.UPDATE_FAIL, message);
                    }
                    return
                }
                if (status === 1) {
                    showAlert('success', notification.UPDATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
            }
        }
    };
    const dialogFooter = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised" onClick={onHideDialog} />
            <Button label="Lưu" icon="pi pi-check" className="p-button-raised" onClick={saveData} />
        </React.Fragment>
    );
    return (
        <div >
            <Dialog visible={visible}
                 header={typeAd === 1 ? titleDefault.PLACE_CREATE : titleDefault.PLACE_UPDATE}
                modal className="p-fluid p-dialog-default wr--30 LocationDialogAddEdit"
                footer={dialogFooter} onHide={onHideDialog}>
                <div className=" p-field">
                    <label htmlFor="name" className="label-bold">Tên địa điểm <span className="text-danger">*</span></label>
                    <InputText id="name" value={adData.name}
                        placeholder="Nhập địa điểm"
                        onChange={(e) => updateField(e.target.value, 'name')} />
                    <span id="username2-help" className="p-invalid p-d-block">{errData.name}</span>
                </div>
                <div className=" p-field">
                    <label htmlFor="address" className="label-bold">Địa chỉ</label>
                    <InputText id="address" value={adData.address}
                        placeholder="Nhập địa chỉ"
                        onChange={(e) => updateField(e.target.value, 'address')} />
                    <span id="username2-help" className="p-invalid p-d-block">{errData.address}</span>
                </div>
                <div className="p-field">
                <label htmlFor="start_time_book" className="label-bold ">Khung giờ đặt phòng</label>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-col-12 p-md-6 p-field ">
                        <Dropdown id="start_time_book" value={adData.start_time_book} options={time} onChange={(e) => updateField(e.target.value, 'start_time_book')}
                            optionLabel="name" filter showClear filterBy="name"
                            placeholder="Chọn giờ bắt đầu" tooltip="Chọn giờ bắt đầu" tooltipOptions={{ position: 'top' }}
                            optionValue="code"
                        />
                        <span id="username2-help" className="p-invalid p-d-block">{errData.start_time_book}</span>
                    </div>
                    <div className="p-col-12 p-md-6 p-field ">
                        <Dropdown id="end_time_book" value={adData.end_time_book} options={time} onChange={(e) => updateField(e.target.value, 'end_time_book')}
                            optionLabel="name" filter showClear filterBy="name"
                            placeholder="Chọn giờ kết thúc" tooltip="Chọn giờ kết thúc" tooltipOptions={{ position: 'top' }}
                            optionValue="code"
                        />
                        <span id="username2-help" className="p-invalid p-d-block">{errData.end_time_book}</span>
                    </div>
                </div>
                </div>

            </Dialog>
        </div>
    )
}
export default (LocationDialogAddEdit);
