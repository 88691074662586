import * as types from './constantsStore'
export const setAuthData = (payload) => {
    return {
        type: types.SET_AUTH_DATA,
        payload
    }
}
export const unsetAuthData = () => {
    return {
        type: types.UNSET_AUTH_DATA
    }
}
