import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";
class EvaluateServices extends FetchAPI {
    //Lấy danh sách cuộc họp
    public async getAll(data) {
        const url = `lich-dat/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async getEvaluateMeeting(data) {
        const url = `lich-dat/evaluate-meeting`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async getHostAndRoom(data) {
        const url = `lich-dat/get-host-and-room`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async createEvaluate(data) {
        const url = `evaluate/create`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async updateEvaluate(data) {
        const url = `evaluate/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
}
export default EvaluateServices;
