import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toolbar } from 'primereact/toolbar';
import { useApp } from 'context/AppContext';
import checkPermission from 'helpers/checkPermisson';
import pages from 'helpers/constant/pages';
import permissions from 'helpers/constant/permissions';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import MailTemplateServices from 'services/MailTemplateServices';
import DialogAddEdit from './DialogAddEdit';
import notification from 'helpers/constant/notification';

export const MailTemplate = (props) => {
    const emptyData = {
        code: '',
        name: '',
        title: '',
        content: '',
        description: '',
    };
    const { showAlert, pageLimit } = useApp();
    const serviceMailTemplate = new MailTemplateServices();
    //danh sách
    const [listData, setListData] = useState();
    // data thêm mới,  xóa
    const [adData, setAdData] = useState(emptyData);
    // loại thêm mới hay sửa
    const [typeAd, setTypeAd] = useState(1);
    // data lỗi validate
    const [errData, setErrData] = useState({});
    // data phân trang
    const filter = props.history.location.search;
    const [first, setFirst] = useState(0);
    const [pagination, setPagination] = useState({
        from: 0,
        to: 0,
        row: 0,
        total: 0,
        page: 0,
    });
    const [dataSearch, setDataSearch] = useState({
        text_search: '',
    });
    const [row, setRow] = useState(10);
    const [totalRecord, setTotalRecord] = useState();
    // dialog thêm, sửa
    const [visibleDialog, setVisibleDialog] = useState(false);
    // data xóa nhiều
    const [selectedDatas, setSelectedDatas] = useState([]);
    // check quyền
    const [typeRole, setTypeRole] = useState(1);
    useEffect(() => {
        fetData();
        // eslint-disable-next-line
    }, [filter]);
    useEffect(() => {
        const typePer = checkPermission(permissions.MAIL_UPDATE);
        setTypeRole(typePer);
        // eslint-disable-next-line
    }, []);
    const fetData = async () => {
        const query = queryString.parse(props.location.search);
        const response = await serviceMailTemplate.getFilter({ ...dataSearch, ...query });
        if (!response) {
            return;
        }
        const { data, status, message } = response;
        if (status === 2) {
            showAlert('error', notification.FAIL, message);
            return;
        }
        setListData(data.data);
        setTotalRecord(data.total);
        setPagination({
            from: data.from,
            to: data.to,
            row: data.per_page,
            total: data.total,
            page: data.current_page,
        });
    };

    // action sửa xóa

    // tìm kiếm
    const onSearchClick = (e) => {
        e.preventDefault();
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const pramSearch = { ...jsonDataSearch, text_search: dataSearch.text_search };
        const query = queryString.stringify(pramSearch);
        props.history.push({
            search: query,
        });
    };
    // Phân trang
    const onPageChange = (event) => {
        setFirst(event.first);
        setRow(event.rows);
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const dataSearch = {
            ...jsonDataSearch,
            row: event.rows,
            page: event.page + 1,
        };
        const query = queryString.stringify(dataSearch);
        props.history.push({
            search: query,
        });
    };
    const handleChangeDataSearch = (e) => {
        setDataSearch({ ...dataSearch, [e.target.name]: e.target.value });
    };
    // f5 lại trang xóa dữ liệu tìm kiếm trên URL
    window.onload = () => {
        props.history.push({
            search: '',
        });
    };
    // dialog sửa
    const onEditClick = (rowData) => {
        setAdData(rowData);
        setTypeAd(2);
        setVisibleDialog(true);
    };
    // export dữ liệu
    //Ẩn dialog thêm mới, cập nhật
    const onHideDialog = () => {
        setErrData({});
        setVisibleDialog(false);
    };

    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">#</span>
                {column.rowIndex + 1 + first}
            </React.Fragment>
        );
    };
    const codeBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Mã</div>
                    <div className="max-line-3" title={rowData.code}>
                        {rowData.code}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const nameBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Tên</div>
                    <div className="max-line-3" title={rowData.name}>
                        {rowData.name}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const titleBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Tiêu đề</div>
                    <div className="max-line-3" title={rowData.title}>
                        {rowData.title}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    // action sửa xóa
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {typeRole === 1 && (
                    <i className="pi pi-pencil p-mr-2 icon-medium pointer text-success action-table-task"
                    onClick={() => onEditClick(rowData)} />
                )}
                {/* <i className="pi pi-trash icon-medium text-danger pointer" onClick={() => confirmDelete(rowData)}></i> */}
            </React.Fragment>
        );
    };
    // tìm kiếm
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <InputText
                    value={dataSearch.text_search}
                    onChange={(e) => handleChangeDataSearch(e)}
                    placeholder={'Tìm theo tên hoặc mã'}
                    name="text_search"
                    className="w-xs-100 w-md-auto p-mb-2"
                />
            </React.Fragment>
        );
    };
    // Thêm mới, xóa,...
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label="Tìm kiếm"
                    icon="pi pi-search"
                    type="submit"
                    className="p-button-primary p-mb-2  w-xs-100 w-md-auto"
                    onClick={(e) => onSearchClick(e)}
                />
            </React.Fragment>
        );
    };
    return (
        <div className="category-mail">
            <DialogAddEdit
                visible={visibleDialog}
                onHideDialog={onHideDialog}
                adData={adData}
                setAdData={setAdData}
                serviceMailTemplate={serviceMailTemplate}
                showAlert={showAlert}
                errData={errData}
                setErrData={setErrData}
                fetData={fetData}
                typeAd={typeAd}
            />
            <div className="content-section implementation datatable-responsive-demo">
                <div className="card">
                    <div className="card-header">
                        <h5>QUẢN LÝ MẪU MAIL</h5>
                    </div>
                    <form onSubmit={(e) => onSearchClick(e)}>
                        <Toolbar className="p-mb-4 p-d-flex p-flex-wrap"
                        left={leftToolbarTemplate}
                        right={rightToolbarTemplate} />
                    </form>
                    <DataTable
                        rowHover
                        value={listData}
                        dataKey="id"
                        className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                        emptyMessage="Không có data"
                        selection={selectedDatas}
                        onSelectionChange={(e) => {
                            if (
                                !e?.originalEvent?.target?.classList.contains('action-table-task')
                            ) {
                                setSelectedDatas(e.value);
                            }
                        }}
                    >
                        <Column body={renderRowIndex} header="#" className="stt-table" />
                        <Column field="code" header="Mã" sortable body={codeBodyTemplate} />
                        <Column field="name" header="Tên" sortable body={nameBodyTemplate} />
                        <Column field="title" header="Tiêu đề" sortable body={titleBodyTemplate} />
                        <Column
                            header="Tác vụ"
                            body={actionBodyTemplate}
                            className="action-table p-text-center"
                        />
                    </DataTable>
                    {/* phân trang */}
                    <div className="p-d-flex p-flex-wrap">
                        <div className="text-normal p-mt-2">
                            <span>
                                Hiển thị từ <b>{pagination.from}</b> đến <b>{pagination.to}</b> /
                                Tổng <b>{pagination.total}</b> bản ghi
                            </span>
                        </div>
                        <div className="p-ml-auto p-mt-2">
                            <Paginator
                                first={first}
                                rows={row}
                                totalRecords={totalRecord}
                                rowsPerPageOptions={pages}
                                onPageChange={(event) => onPageChange(event)}
                                template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                                pageLinkSize={pageLimit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(MailTemplate);
