import ViewPdfIframe from 'components/view-pdf-iframe/ViewPdfIframe';
import { useApp } from 'context/AppContext';
import notification from 'helpers/constant/notification';
import getIconFile from 'helpers/file/getIconFile';
import validateAcceptFile from 'helpers/file/validateAcceptFile';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AiOutlineFile } from 'react-icons/ai';
import { FileUpload } from 'primereact/fileupload';
import ConfigServices from 'services/ConfigServices';
import ViewImage from 'components/view-image/ViewImage';
import getUrlFilePreview from 'helpers/file/getUrlFilePreview';
import UploadFileService from 'services/UploadFileService';

const InputUploadFile = (props) => {
    const { showAlert } = useApp();
    const serviceConfig = new ConfigServices();
    const fileService = new UploadFileService();

    const {
        multiple,
        acceptFile,
        maxFileSize,
        disabled,
        chooseLabel,
        nameInput,
        labelViewFile,
        onUploadFile,
        files,
        maxFile,
        inputId,
        refFile,
        title,
    } = props;

    useEffect(() => {
        getConfig();

        if (maxFile) {
            setMaxFileUpload(maxFile);
        }
        if (files) {
            const listFileAttach = [];
            for (const fi of files) {
                listFileAttach.push({
                    name: fi,
                    nameUp: fi,
                });
            }
            listFileAttach.map((file, index) => {
                getIconFile(file, false);
                return file;
            });
            setFileUpload(listFileAttach);
            return;
        }
        setFileUpload([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const [dialogViewFile, setDialogViewFile] = useState(false);
    const [fileUpload, setFileUpload] = useState([]);
    const [typeFiles, setTypeFiles] = useState(1);
    const [dataImg, setDataImg] = useState('#');
    const [maxFileUpload, setMaxFileUpload] = useState(20);
    const [base64Pdf, setBase64Pdf] = useState('');
    const [, setConfig] = useState({});

    const onHideDialogViewFile = () => {
        setDialogViewFile(false);
    };

    const getConfig = async () => {
        try {
            const result = await serviceConfig.getAll();
            if (result) {
                setConfig(result?.data);
            }
        } catch (error) {
        }
    };

    // Upload file into MinIo
    const onChangeUploadFiles = async (e) => {
        const newFileArr = [];
        const lengthFileUpload = fileUpload.length;
        if (lengthFileUpload >= maxFileUpload) {
            setTimeout(() => {
                onUploadFile(fileUpload);
                refFile.current.clear();
                showAlert('error', notification.FAIL, `Số lượng file vượt quá ${maxFile}`);
            }, 100);
            return;
        }

        const eFileData = e.files;
        const filesArr = Array.prototype.slice.call(eFileData);
        const lengthFilesArr = filesArr.length;
        if (lengthFilesArr > maxFileUpload) {
            setTimeout(() => {
                onUploadFile(fileUpload);
                refFile.current.clear();
                showAlert('error', notification.FAIL, `Số lượng file vượt quá ${maxFile}`);
            }, 100);
            return;
        }

        await filesArr.map(async (file, index) => {
            const fileName = file.name;
            const newCheckFileName = [];
            fileUpload.map((e) => newCheckFileName.push(e.name));
            if (fileUpload.length > 0 && newCheckFileName.includes(fileName)) {
                setTimeout(() => {
                    removeFile(file);
                    refFile.current.clear();
                    onUploadFile(fileUpload);
                    showAlert(
                        'error',
                        notification.FAIL,
                        <React.Fragment>
                            File <b>{fileName}</b> đã tồn tại!!
                        </React.Fragment>
                    );
                }, 100);
                return;
            }

            const fileSize = file.size;
            const fileSizeCount = fileSize / 1024 / 1024;
            if (fileSizeCount > maxFileSize) {
                setTimeout(() => {
                    removeFile(file);
                    refFile.current.clear();
                    showAlert(
                        'error',
                        notification.FAIL,
                        <React.Fragment>
                            File <b>{fileName}</b> quá dung lượng {maxFileSize}MB!
                        </React.Fragment>
                    );
                }, 100);
                return;
            }

            if (index > maxFileUpload) {
                setTimeout(() => {
                    removeFile(file);
                    refFile.current.clear();
                    showAlert('error', notification.FAIL, `Số lượng file vượt quá ${maxFile}`);
                }, 100);
                return;
            }
            // đúng địng dạng file
            if (!validateAcceptFile(fileName, acceptFile)) {
                setTimeout(() => {
                    removeFile(file);
                    refFile.current.clear();
                    showAlert(
                        'error',
                        notification.FAIL,
                        <React.Fragment>
                            File <b>{fileName}</b> không đúng định dạng!
                        </React.Fragment>
                    );
                }, 100);
                return;
            }

            if (!file) {
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            const result = await fileService.uploadFileMinio(formData);

            if (result) {
                file.nameUp = result.data;
                newFileArr.push(file);
                newFileArr.map((file) => getIconFile(file));
                file.typeGet = 1;
                setFileUpload([...fileUpload, ...newFileArr]);
                onUploadFile([...fileUpload, ...newFileArr]);
                refFile.current.clear();
                return;
            }
            await fileService.uploadFileMinio(formData);
            removeFile(file);
            showAlert('error', notification.FAIL, notification.MESSAGE_ERROR);
        });
    };

    const removeFile = (indexFileRemove) => {
        try {
            const newFiles = [...fileUpload];
            const filterFiles = newFiles.filter((x, index) => {
                return x !== indexFileRemove;
            });
            setFileUpload(filterFiles);
            onUploadFile(filterFiles);
        } catch (error) {
            console.error('error: ', error);
            showAlert('error', notification.FAIL, notification.MESSAGE_ERROR);
        }
    };

    const watchFile = (fileAction) => {
        setDialogViewFile(true);
        if (fileAction.type === 'application/pdf') {
            const base64File = getUrlFilePreview(fileAction);
            setBase64Pdf(base64File);
            setTypeFiles(1);
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(fileAction);
        reader.onload = (e) => {
            setDataImg(reader.result);
        };
        setTypeFiles(2);
    };
    return (
        <div className={`UploadFile ${disabled && 'p-disabled'}`} title={title}>
            <FileUpload
                id={inputId}
                mode="basic"
                uploadHandler={onChangeUploadFiles}
                customUpload={true}
                auto
                name={nameInput}
                chooseLabel={chooseLabel}
                ref={refFile}
                accept={acceptFile}
                disabled={disabled}
                multiple={multiple}
                className="w-xs-100 w-md-auto"
            />
            {fileUpload.length > 0 && (
                <div className=" p-mt-2">
                    <h5>{labelViewFile || 'Danh sách tài liệu'}</h5>
                </div>
            )}
            <div className="file-preview">
                {fileUpload.length > 0 &&
                    fileUpload.map((x, index) => (
                        <div className="p-mt-2" key={index}>
                            <span className="p-mr-2 p-text-file">
                                {x.icon || <AiOutlineFile />}
                            </span>
                            <span className="p-mr-2 p-text-file p-text-file-name">{x.nameUp || x.name}</span>
                            <span className="p-mr-2 p-text-file">
                                {x.size
                                    ? x.size / 1024 >= 1024
                                        ? `${(x.size / 1024 / 1024).toFixed(2)} MB`
                                        : `${(x.size / 1024).toFixed(2)} KB`
                                    : ''}
                            </span>
                            <span className="action p-text-file">
                                {x.typeFile === 1 && (
                                    <i
                                        className="pi pi-eye p-mr-2 text-primary pointer"
                                        onClick={() => watchFile(x)}
                                        tooltip="Xem chi tiết"
                                    />
                                )}
                                <i
                                    className="pi pi-times text-danger pointer"
                                    onClick={() => removeFile(x)}
                                    tooltip="Xóa file"
                                />
                            </span>
                        </div>
                    ))}
            </div>
            <Dialog
                visible={dialogViewFile}
                className="w-xs-95 w-lg-auto"
                maximized
                maximizable
                header="Xem chi tiết file"
                modal
                onHide={onHideDialogViewFile}
            >
                <div className="p-text-center">
                    {typeFiles === 1 && <ViewPdfIframe html={base64Pdf} />}
                    {typeFiles === 2 && (
                        <ViewImage
                            dataImg={dataImg}
                            alt="anh-xem-truoc"
                            className="upload-img-preview"
                        />
                    )}
                </div>
            </Dialog>
        </div>
    );
};
export default InputUploadFile;

InputUploadFile.propTypes = {
    multiple: PropTypes.bool,
    acceptFile: PropTypes.string,
    maxFileSize: PropTypes.number,
    disabled: PropTypes.bool,
    chooseLabel: PropTypes.string,
    nameInput: PropTypes.string,
    labelViewFile: PropTypes.string,
    onUploadFile: PropTypes.func,
    files: PropTypes.array,
    maxFile: PropTypes.number,
    refFile: PropTypes.object,
    inputId: PropTypes.string,
    title: PropTypes.string,
};

InputUploadFile.defaultProps = {
    multiple: false,
    maxFileSize: 20,
    disabled: false,
    chooseLabel: 'Add',
    labelViewFile: 'List file upload',
    maxFile: 20,
    files: [],
};
