import { useApp } from 'context/AppContext';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
// import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import Service from 'services/UserServices';
import AgencyPackageService from 'services/AgencyPackageService';
import { DialogPro } from './DiaLogPro';
import { TabView, TabPanel } from 'primereact/tabview';
import notification from 'helpers/constant/notification';
import { format, parseISO } from 'date-fns';
import './profile.scss';

export const Profile = () => {
    const { showAlert, pageLimit } = useApp();
    const service = new Service();
    const agencyPackageService = new AgencyPackageService();
    const [packages, setPackages] = useState([]);
    const [dataViewInfo, setDataViewInfo] = useState({
        phone: '',
        fullname: '',
        username: '',
        email: '',
    });
    const [dataViewHistory, setDataViewHistory] = useState([]);

    const onHideDialog = () => {
        setVisibleDialog(false);
    };

    const [visibleDialog, setVisibleDialog] = useState(false);
    const [first, setFirst] = useState(0);
    const [agencyActive, setAgencyActive] = useState({
        address: '',
        bank_branch: '',
        bank_number: '',
        code: '',
        created_at: '',
        created_by: '',
        cyberid_oid: '',
        deputy: '',
        deputy_position: '',
        district_code: '',
        email: '',
        fax: '',
        fullname: '',
        logo: '',
        note: '',
        office_address: '',
        phone: '',
        province_code: '',
        sortname: '',
        status: '',
        system: '',
        tax_code: '',
        website: ''
    });
    const [totalRecord, setTotalRecord] = useState();
    const [currentPage, setCurrentPage] = useState();
    const onPageChange = (event) => {
        setCurrentPage(event.page + 1);
        setFirst(event.first);
    };

    useEffect(() => {
        fetData();
        fetHistoryData();
        getAllAgencyPackage();
        // eslint-disable-next-line
    }, [currentPage]);

    // const onEditUser = () => {
    //     setVisibleDialog(true);
    // };

    const getAllAgencyPackage = async () => {
        const result = await agencyPackageService.getAllAgencyPackage({});
        if (!result) {
            return;
        }
        const { data } = result;
        setPackages(data);
    }

    const fetData = async () => {
        const result = await service.getUserInfo({});
        if (!result) {
            return;
        }
        const { status, data, message } = result;
        if (status === 2) {
            showAlert('error', notification.FAIL, message);
            return;
        }
        if (status === 1) {
            setAgencyActive(result.activeAgency);
            setDataViewInfo({
                ...dataViewInfo,
                username: data.username,
                email: data.email,
                fullname: data.fullname,
                phone: data.phone,
                role: data.user_role.role.display_name,
                status: data.status,
                agency: result.agency,
            });
        }
    };

    const fetHistoryData = async () => {
        const result = await service.getLogHistoryUser(currentPage);
        if (!result) {
            return;
        }
        const { status, data, message } = result;
        if (status === 2) {
            showAlert('error', notification.FAIL, message);
            return;
        }
        if (status === 1) {
            setDataViewHistory(data.data);
            setTotalRecord(data.total);
        }
    };

    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                {column.rowIndex + 1 + first}
            </React.Fragment>
        );
    };

    const statusBodyTemplate = rowData => {
        let statusButton = "";
        if(rowData.status === 1){ 
            statusButton =  <Tag value="Hoạt động" severity="success" />;
        } else if(rowData.status === 2) {
            statusButton =  <Tag value="Không hoạt động" severity="danger" />;
        }
        return (
            <React.Fragment>
                { statusButton }
            </React.Fragment>
        );
    };

    const packageCodeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.package_code}
            </React.Fragment>
        );
    };

    const userNumBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.user_num}
            </React.Fragment>
        );
    };

    const expireDateBodyTemplate = (rowData) => {
        const isoDate = parseISO(rowData.expire_date);
        const formattedDate = format(isoDate, 'dd/MM/yyyy');
        const formattedTime = format(isoDate, 'HH:mm:ss');

        return (
            <React.Fragment>
                <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
                    <p className="p-p-0 p-m-0">{formattedDate}</p>
                    <p className="p-p-0 p-m-0">{formattedTime}</p>
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="content-section implementation formlayout-demo Profile">
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-header p-mb-0">
                        <h5>Thông tin chi tiết</h5>
                    </div>
                </div>
                <div className="p-col-12 p-md-6">
                    <TabView className='card p-0' scrollable>
                        <TabPanel header="Tài khoản" leftIcon="pi pi-user mx-2">
                            <div className="card h--100 ">
                                <div className="p-d-flex">
                                    <div className="p-fluid p-formgrid">
                                    <div className="p-col">
                                        <label className="p-d-inline">
                                            <b>Họ tên : </b>
                                        </label>
                                        <p id="fullname" name="fullname" className="p-d-inline">
                                            {' '}
                                            {dataViewInfo.fullname}
                                        </p>
                                    </div>
                                    <div className="p-col">
                                        <label className="p-d-inline">
                                            <b>Email : </b>
                                        </label>
                                        <p id="email" name="email" className="p-d-inline">
                                            {dataViewInfo.email}{' '}
                                        </p>
                                    </div>
                                    <div className="p-col">
                                        <label className="p-d-inline">
                                            <b>Phone : </b>
                                        </label>
                                        <p id="email" name="email" className="p-d-inline">
                                            {dataViewInfo.phone}
                                        </p>
                                    </div>
                                    <div className="p-col">
                                        <label className="p-d-inline ">
                                            <b>Quyền : </b>
                                        </label>
                                        <span id="status" name="status" className=" p-tag p-tag-success">
                                            {dataViewInfo.role}
                                        </span>
                                    </div>
                                    <div className="p-col">
                                        <label className="p-d-inline ">
                                            <b>Trạng thái : </b>
                                        </label>
                                        <span id="status" name="status" className=" p-tag p-tag-success">
                                            Hoạt động
                                        </span>
                                    </div>
                                    </div>
                                    {/* <Button
                                        label=""
                                        icon="pi pi-user-edit"
                                        tooltip="Chỉnh sửa"
                                        style={{ height: '35px' }}
                                        className="p-button-raised p-button-text p-button-plain p-d-inline p-button p-component p-ml-auto p-button-icon-only"
                                        onClick={onEditUser}
                                    /> */}
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Doanh ngiệp đang sử dụng" leftIcon="pi pi-briefcase mx-2">
                            <div className="card h--100 agency-active--user">
                                <div className='row pb-3 border-bottom'>
                                    <div className='col-4'>
                                        <b>CÔNG TY</b>
                                    </div>
                                    <div className='col-8'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Tên đầy đủ</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.fullname ?? "--" }</b> </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Tên giao dịch/viết tắt</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.sortname  ?? "--"}</b> </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Mã số thuế</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.tax_code  ?? "--"}</b> </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Ngày thành lập</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ "--" }</b> </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Logo công ty</p>
                                            </div>
                                            <div className='col-6'>
                                                <p> 
                                                    { 
                                                        agencyActive.logo !== null ? 
                                                        <img className='logo-agency' src={agencyActive.logo} alt='Có ảnh' /> : 
                                                        <div className='logo-agency text-center p-logo-agency'> 
                                                            <p>Chưa có ảnh</p>
                                                        </div>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row py-3 border-bottom'>
                                    <div className='col-4'>
                                        <b>NGƯỜI ĐẠI ĐIỆN</b>
                                    </div>
                                    <div className='col-8'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Tên</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.deputy ?? "--" }</b> </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Chức danh</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.deputy_position ?? "--" }</b> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row py-3'>
                                    <div className='col-4'>
                                        <b>THÔNG TIN KHÁC</b>
                                    </div>
                                    <div className='col-8'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Địa chỉ</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.address ?? "--" }</b> </p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Địa chỉ văn phòng giao dịch</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.office_address ?? "--" }</b></p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Điện thoại</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.phone ?? "--" }</b></p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Fax</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.fax ?? "--" }</b></p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Email</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.email ?? "--" }</b></p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p>Website</p>
                                            </div>
                                            <div className='col-6'>
                                                <p><b>{ agencyActive.website ?? "--" }</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Gói dịch vụ" leftIcon="pi pi-book mx-2">
                            <div className="card h--100 ">
                                { packages.length !== 0  &&
                                    <DataTable 
                                        value={packages} 
                                        paginator 
                                        dataKey="id"
                                        emptyMessage="Không có data"
                                        rows={5} 
                                        rowsPerPageOptions={[5, 10, 25, 50]} 
                                    >
                                        <Column header="STT" className="stt-table" body={renderRowIndex}/>
                                        <Column field="package_code" header="Mã gói" sortable body={packageCodeBodyTemplate} />
                                        <Column field="user_num" header="Số lượng người" sortable body={userNumBodyTemplate} />
                                        <Column field="expire_date" header="Ngày hết hạn" sortable body={expireDateBodyTemplate} />
                                        <Column field="status" header="Trạng thái" body={statusBodyTemplate} />
                                    </DataTable>
                                }
                                {
                                    packages.length === 0 && <p className='text-center p-fw-bold'>Chưa có gói dịch vụ nào</p>
                                }
                            </div>         
                        </TabPanel>
                    </TabView>
                </div>
                <div className=" p-col-12 p-md-6">
                    <div className="card h--100">
                        <div>
                            <h5>Lịch sử hoạt động</h5>
                        </div>
                        {dataViewHistory.map((detail, index) => {
                            return (
                                <div className="p-datatable" key={index}>
                                    <div className="p-col p-datatable-header">
                                        <label>[{first + index}] </label>
                                        <label className="p-d-inline ">
                                            <b>{dataViewInfo.fullname}</b> vào
                                        </label>
                                        <p className="p-d-inline">
                                            {' '}
                                            {detail.category_name} [{detail.action_name}]{' '}
                                        </p>
                                        <br />
                                        <span id="status" name="status" className=" p-tag ">
                                            {Moment(detail.created_at).format(
                                                'DD-MM-YYYY HH:mm:ss'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                        <br />
                        <Paginator
                            first={first}
                            rows={10}
                            totalRecords={totalRecord}
                            onPageChange={(event) => onPageChange(event)}
                            pageLinkSize={pageLimit}
                        />
                    </div>
                </div>
                <DialogPro
                    visible={visibleDialog}
                    onHideDialog={onHideDialog}
                    dataViewInfo={dataViewInfo}
                    fetData={fetData}
                />
            </div>
        </div>
    );
};

export default withRouter(Profile);
