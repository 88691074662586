import { withRouter } from "react-router-dom";
import ColumnChart from "./ColumnChart";
import PieChart from "./PieChart";
import queryString from 'query-string';
import DashboardServices from "services/DashboardServices";
import React, { useState } from "react";

export const Home = (props) => {
    const filter = props.history.location.search;
    const history = props.history;
    const queryColumn = props.queryColumn;
    const queryPie = props.queryPie;
    const jsonDataSearch = queryString.parse(props.history.location.search);
    const serviceDashboard = new DashboardServices();

    const [filterColumn, setFilterColumn] = useState(filter);
    const [filterPie, setFilterPie] = useState(filter);
    return (
        <div className="home">
            <div className="features">
                <p className="p-fsw-18">
                    Congratulations!{" "}
                    <span role="img" aria-label="celebrate" className="p-mx-2">
                        🎉
                    </span>{" "}
                    Chào mừng bạn đến phần mềm quản lý phòng họp của CyberLotus.
                </p>
                <div className="p-grid ">
                    <div className="p-col-12 p-xl-6 chart-home">
                        <ColumnChart 
                            filter={filterColumn}
                            setFilterColumn={setFilterColumn}
                            queryColumn={queryColumn}
                            jsonDataSearch={jsonDataSearch}
                            serviceDashboard={serviceDashboard}
                            history={history}
                            />
                        
                    </div>
                    <PieChart 
                        filter={filterPie}
                        setFilterPie={setFilterPie}
                        queryPie={queryPie}
                        jsonDataSearch={jsonDataSearch}
                        serviceDashboard={serviceDashboard}
                        history={history}
                    />   
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-4 p-xl-4 p-py-2">
                            <div className="feature-card">
                                <img
                                    alt="opensource"
                                    src="showcase/images/react-opensource.png"
                                />
                                <div className="feature-card-detail">
                                    <span className="feature-name">
                                        ĐẶT LỊCH HỌP ONLINE
                                    </span>
                                    <p>
                                        Bạn có thể đặt phòng{" "}
                                        <a
                                            href="/#/lich-dat-cua-toi/tao-moi"
                                            className="layout-content-link"
                                            target="_self"
                                        >
                                        Tại đây
                                        </a>
                                        , để đặt phòng trực tuyến nhanh chóng và
                                        chính xác.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-4 p-xl-4 p-py-2">
                            <div className="feature-card ">
                                <img
                                    alt="themes"
                                    src="showcase/images/react-themes.png"
                                />
                                <div className="feature-card-detail">
                                    <span className="feature-name">
                                        QUẢN LÝ - HỖ TRỢ PHÒNG HỌP
                                    </span>
                                    <p>
                                        Nắm bắt thông tin đặt phòng của tất cả
                                        mọi người. Sẵn sàng chuẩn bị chu đáo và
                                        chuẩn xác.{" "}
                                        <a
                                            href="/#/lich-dat"
                                            className="layout-content-link"
                                            target="_self"
                                        >
                                            Xem tất cả lịch họp
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-4 p-xl-4 p-py-2">
                            <div className="feature-card">
                                <img
                                    alt="templates"
                                    src="showcase/images/react-templates.png"
                                />
                                <div className="feature-card-detail">
                                    <span className="feature-name">
                                        TIỆN ÍCH - DỄ DÀNG
                                    </span>
                                    <p>
                                        Dễ dàng sử dụng với giao diện thân
                                        thiện, xem hướng dẫn sử dụng{" "}
                                        <a
                                            href="/#/lich-dat-cua-toi"
                                            className="layout-content-link"
                                            target="_self"
                                        >
                                            tại đây
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Home);
