import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState  } from 'react';
import { Route } from 'react-router-dom';
import { setAuthData } from 'app/actions';
import { connect } from 'react-redux';
import AuthService from 'services/AuthService';
import Page403Error from 'pages/errors/Page403Error';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { keycloak, initialized } = useKeycloak();
    const [user,setUser] = useState({});

    useEffect(() => {
        if (initialized) {
            if (!keycloak.authenticated) {
                keycloak.login();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized]);

    useEffect(() => {
        keycloak.onAuthLogout = async function (e) {
            keycloak.login();
        };

        keycloak.onTokenExpired = async function (e) {
            keycloak.login();
        };

        keycloak.onAuthSuccess = async function (e) {
            localStorage.setItem(
                'currentUser',
                JSON.stringify({
                    token: keycloak?.token,
                })
            );
            fetchDataUser();
        };
        // eslint-disable-next-line
    }, [keycloak]);

    const fetchDataUser = async () => {
        if (!keycloak.authenticated) {
            return;
        }

        const res = await AuthService.getUserInfo();
        setUser(res);
        if (res) {
            rest.setAuthData(res);
        }
    };

    if(!user){
        return <Page403Error/>
    }

    return (
        <div>
            {
                keycloak.authenticated && <Route {...rest} render={props => ((<Component {...props} user={user} />))} />
            }
        </div>
    );
};

function mapStateToProps(state) {
    return { userData: state.authReducer };
}

const mapDispatchToProps = {
    setAuthData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
