import store from "app/store";

let typeRole = 2;
const checkPermission = (permission) => {
    const userPer = store.getState().authReducer.userData;
    if (userPer) {
        const listPer = userPer.permission;
        typeRole = listPer.includes(permission) ? 1 : 2;
    }
    return typeRole;
}

export default checkPermission
