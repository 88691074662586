import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";

class AgencyPackageService extends FetchAPI {
    //Lấy danh sách khách hàng
    public async getAllAgencyPackage(data) {
        const url = `package/get-all-package`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
}
export default AgencyPackageService;
