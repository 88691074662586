import { useApp } from 'context/AppContext';
import checkPermission from 'helpers/checkPermisson';
import pages from 'helpers/constant/pages';
import permissions from 'helpers/constant/permissions';
import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toolbar } from 'primereact/toolbar';

import Service from 'services/UserServices';
import queryString from 'query-string';
import notification from 'helpers/constant/notification';
import { AiFillEye } from 'react-icons/ai';
import ErrorsImportUserDialog from './ErrorsImportUserDialog';

export const ImportUser = (props) => {
    const { showAlert, pageLimit } = useApp();
    const services = new Service();
    const [data, setData] = useState();
    const [first, setFirst] = useState(0);
    const [dataSearch, setDataSearch] = useState({
        text_search: '',
        status: '',
    });
    const [row, setRow] = useState(10);
    // check quyền
    const [typeRole, setTypeRole] = useState(1);

    const [pagination, setPagination] = useState({
        from: 0,
        to: 0,
        row: 0,
        total: 0,
        page: 0,
    });
    const [visibleErrorsImportUserDialog, setVisibleErrorsImportUserDialog] = useState(false);
    const [importUserCurrent, setImportUserCurrent] = useState({});

    const fetData = async () => {
        const result = await services.getImportUsers();
        if (!result) {
            return;
        }
        const { data, status, message } = result;
        if (status === 2) {
            showAlert('error', notification.FAIL, message);
            return;
        }
        setData(data);
        setPagination({
            from: data.from,
            to: data.to,
            row: data.per_page,
            total: data.total,
            page: data.current_page,
        });
    };
    useEffect(() => {
        fetData();
        // eslint-disable-next-line
    }, [props.history.location.search]);
    useEffect(() => {
        const typePer = checkPermission(permissions.USER_UPDATE);
        setTypeRole(typePer);
        // eslint-disable-next-line
    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <InputText
                        value={dataSearch.text_search}
                        onChange={(e) => handleChangeDataSearch(e)}
                        placeholder={'Trạng thái'}
                        className=" w-xs-100 w-md-auto p-mb-2"
                        name="text_search"
                    />
                </div>
            </React.Fragment>
        );
    };

    const onSearchClick = (e) => {
        e.preventDefault();
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const pramSearch = {
            ...jsonDataSearch,
            text_search: dataSearch.text_search,
        };
        const query = queryString.stringify(pramSearch);
        props.history.push({
            search: query,
        });
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRow(event.rows);
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const dataSearch = {
            ...jsonDataSearch,
            row: event.rows,
            page: event.page + 1,
        };
        const query = queryString.stringify(dataSearch);
        props.history.push({
            search: query,
        });
    };

    const handleChangeDataSearch = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({ ...dataSearch, [name]: value });
    };

    const rightToolbarTemplate = () => {
        const classSearch = typeRole === 1 ? 'p-mr-2' : '';
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <Button
                        label="Tìm kiếm"
                        icon="pi pi-search"
                        type="submit"
                        className={`p-button-primary w-xs-100 w-md-auto p-mb-2 ${classSearch}`}
                        onClick={(e) => onSearchClick(e)}
                    />
                </div>
            </React.Fragment>
        );
    };

    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">STT</span>
                {column.rowIndex + 1 + first}
            </React.Fragment>
        );
    };
    const FileBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <span className="p-column-title">File</span>
                    <span className="max-line-3" style={{ color: 'blue' }}>
                        {rowData?.file.substring(rowData?.file.lastIndexOf('/') + 1)}
                    </span>
                </div>
            </React.Fragment>
        );
    };
    const ErrorBodyTemplate = (rowData, column) => {
        const errors = JSON.parse(rowData?.errors);
        return (
            <React.Fragment>
                <span className="p-column-title">Lỗi</span>
                {errors && errors.length > 0 && (
                    <AiFillEye
                        color="red"
                        fontSize={18}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setVisibleErrorsImportUserDialog(true);
                            setImportUserCurrent(rowData);
                        }}
                    />
                )}
            </React.Fragment>
        );
    };

    const CreatedAtBodyTemplate = (rowData, column) => {
        const isoDate = parseISO(rowData.created_at);
        const formattedDate = format(isoDate, 'dd/MM/yyyy');
        const formattedTime = format(isoDate, 'HH:mm:ss');

        return (
            <React.Fragment>
                <span className="p-column-title">Thời gian tạo</span>
                <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
                    <p className="p-p-0 p-m-0">{formattedDate}</p>
                    <p className="p-p-0 p-m-0">{formattedTime}</p>
                </div>
            </React.Fragment>
        );
    };

    const StatusBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Trạng thái</span>
                <div className="p-d-flex p-jc-center p-ai-center">
                    {rowData.status === 1 && (
                        <Button
                            label="Đang xử lý tập tin"
                            className="p-button-warning py-1 px-3 w-xs-100 w-md-auto"
                            style={{ background: '#FBF0B2', color: '#FFC436', fontWeight: '500' }}
                        />
                    )}
                    {rowData.status === 2 && (
                        <Button
                            label="Tập tin xử lý không hợp lệ"
                            className="p-button-danger py-1 px-3 w-xs-100 w-md-auto"
                            style={{ background: '#E8D5D6', color: '#AF716F', fontWeight: '500' }}
                        />
                    )}
                    {rowData.status === 3 && (
                        <Button
                            label="Import dữ liệu thành công"
                            className="p-button-success py-1 px-3 w-xs-100 w-md-auto"
                            style={{ background: '#DFFFD6', color: '#95D385', fontWeight: '500' }}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className="user-css">
            <div className="content-section implementation datatable-responsive-demo">
                <div className="card">
                    <div className="card-header">
                        <h5>DANH SÁCH YÊU CẦU IMPORT</h5>
                    </div>
                    <form onSubmit={(e) => onSearchClick(e)}>
                        <Toolbar className="p-mb-4 p-d-flex p-flex-wrap"
                        left={leftToolbarTemplate}
                        right={rightToolbarTemplate} />
                    </form>
                    <DataTable
                        value={data}
                        emptyMessage="Không có data"
                        className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                    >
                        <Column body={renderRowIndex} header="STT" className="stt-table" />
                        <Column field="file" header="File" sortable body={FileBodyTemplate} />
                        <Column
                            field="status"
                            header="Trạng thái"
                            sortable
                            body={StatusBodyTemplate}
                        />
                        <Column field="errors" header="Lỗi" sortable body={ErrorBodyTemplate} />
                        <Column
                            field="status"
                            header="Ngày tạo"
                            sortable
                            body={CreatedAtBodyTemplate}
                        />
                    </DataTable>
                    <div className="p-d-flex p-flex-wrap">
                        <div className="text-normal p-mt-2">
                            <span>
                                Hiển thị từ <b>{pagination.from}</b> đến <b>{pagination.to}</b> /
                                Tổng <b>{pagination.total}</b> bản ghi
                            </span>
                        </div>
                        <div className="p-ml-auto p-mt-2">
                            <Paginator
                                first={first}
                                rows={row}
                                totalRecords={pagination.total}
                                rowsPerPageOptions={pages}
                                onPageChange={(event) => onPageChange(event)}
                                template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                                pageLinkSize={pageLimit}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ErrorsImportUserDialog
                user={importUserCurrent}
                visible={visibleErrorsImportUserDialog}
                setVisible={setVisibleErrorsImportUserDialog}
            />
        </div>
    );
};
export default withRouter(ImportUser);
