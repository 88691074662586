import { useApp } from 'context/AppContext';
import checkPermission from 'helpers/checkPermisson';
import pages from 'helpers/constant/pages';
import permissions from 'helpers/constant/permissions';
import titleDefault from 'helpers/constant/titleDefault';
import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { Toolbar } from 'primereact/toolbar';
import { useSelector } from 'react-redux';
import Service from 'services/UserServices';
import { DialogUser } from './DialogUser';
import queryString from 'query-string';
import notification from 'helpers/constant/notification';
import ImportUserDialog from './ImportUserDialog';

export const Users = (props) => {
    const { showAlert, pageLimit } = useApp();
    const services = new Service();
    const userPer = useSelector((state) => state.authReducer.userData);
    const [data, setData] = useState();
    const [first, setFirst] = useState(0);
    const [dataSearch, setDataSearch] = useState({
        text_search: '',
        status: '',
    });
    const [row, setRow] = useState(10);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [idDelete, setIdDelete] = useState();
    const [idSwitch, setIdSwitch] = useState();
    const [idReset, setIdReset] = useState({
        id: '',
    });
    // check quyền
    const [typeRole, setTypeRole] = useState(1);
    const [idDeleteMultiple, setIdDeleteMultiple] = useState({
        id: [],
    });
    const [typeAd, setTypeAd] = useState(1);

    const [dataEdit, setDataEdit] = useState(null);
    const [pagination, setPagination] = useState({
        from: 0,
        to: 0,
        row: 0,
        total: 0,
        page: 0,
    });
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
    const [switchStatusUserDialog, setStatusUserDialog] = useState(false);
    const [currentDialogTitle, setCurrentDialogTitle] = useState(data);
    const [currentDialogContent, setCurrentDialogContent] = useState(data);
    const [typeDelete, setTypeDelete] = useState(null);
    const [selectedUser, setSelectedUser] = useState([]);
    const [statusUser, setStatusUser] = useState(null);
    const [visibleImportUserDialog, setVisibleImportUserDialog] = useState(false);

    const status = [
        { name: 'Tất cả', status: '' },
        { name: 'Hoạt động', status: '0' },
        { name: 'Không hoạt động', status: '1' },
    ];

    const fetData = async () => {
        const query = queryString.parse(props.location.search);
        const result = await services.getListUser({
            ...dataSearch,
            ...query,
        });
        if (!result) {
            return;
        }
        const { data, status, message } = result;
        if (status === 2) {
            showAlert('error', notification.FAIL, message);
            return;
        }
        setData(data.data);
        setPagination({
            from: data.from,
            to: data.to,
            row: data.per_page,
            total: data.total,
            page: data.current_page,
        });
    };
    useEffect(() => {
        fetData();
        // eslint-disable-next-line
    }, [props.history.location.search]);
    useEffect(() => {
        const typePer = checkPermission(permissions.USER_UPDATE);
        setTypeRole(typePer);
        // eslint-disable-next-line
    }, []);

    const onEditUser = (rowData) => {
        setDataEdit(rowData);
        setTypeAd(2);
        setVisibleDialog(true);
    };

    const onSwitchStatus = (data) => {
        setStatusUserDialog(true);
        setIdSwitch(data);
        setCurrentDialogTitle(
            data.status === '0' ? 'Xác nhận khóa người dùng' : 'Xác nhận mở khóa người dùng'
        );
        setCurrentDialogContent(
            data.status === '0'
                ? 'Bạn có chắc muốn khóa người dùng này không?'
                : 'Bạn có chắc muốn mở khóa người dùng này không?'
        );
    };

    const onDeleteUser = (data) => {
        setDeleteUserDialog(true);
        setIdDelete(data);
        setTypeDelete(1);
    };

    const onResetPasswordUser = (data) => {
        setResetPasswordDialog(true);
        setIdReset({ id: data });
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <InputText
                        value={dataSearch.text_search}
                        onChange={(e) => handleChangeDataSearch(e)}
                        placeholder={'Mã, tên hoặc email'}
                        className=" w-xs-100 w-md-auto p-mb-2"
                        name="text_search"
                    />

                    <Dropdown
                        value={statusUser}
                        onChange={(e) => {
                            setStatusUser(e.value);
                            handleChangeDataSearch(e);
                        }}
                        options={status}
                        optionLabel="name"
                        name="status"
                        placeholder="Trạng thái người dùng"
                        className="w-xs-100 w-md-auto p-mb-2 p-ml-2"
                    />
                </div>
            </React.Fragment>
        );
    };

    const onSearchClick = (e) => {
        e.preventDefault();
        const jsonDataSearch = queryString.parse(props.history.location.search);
        if (dataSearch?.text_search) {
            jsonDataSearch.text_search = dataSearch.text_search;
        } else {
            delete jsonDataSearch.text_search;
        }

        if (statusUser?.status === '') {
            delete jsonDataSearch.statusUser;
        } else {
            jsonDataSearch.statusUser = statusUser?.status;
        }

        const query = queryString.stringify(jsonDataSearch);
        props.history.push({
            search: query,
        });
    };

    const onCreateClick = () => {
        setTypeAd(1);
        setVisibleDialog(true);
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRow(event.rows);
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const dataSearch = {
            ...jsonDataSearch,
            row: event.rows,
            page: event.page + 1,
        };
        const query = queryString.stringify(dataSearch);
        props.history.push({
            search: query,
        });
    };

    const handleChangeDataSearch = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({ ...dataSearch, [name]: value });
    };

    const onHideDialog = () => {
        setDataEdit(null);
        setVisibleDialog(false);
    };

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
        setSelectedUser([]);
    };

    const hideResetPasswordDialog = () => {
        setResetPasswordDialog(false);
    };

    const hideswitchStatusUserDialog = () => {
        setStatusUserDialog(false);
    };

    const deleteUser = async (type) => {
        if (type === 1) {
            const result = await services.onDeleteUser(idDelete);
            if (result && result.status === 1) {
                showAlert('success', 'Xóa thành công', result.message);
                hideDeleteUserDialog();
                await fetData();
            } else if (result && result.status === 2) {
                showAlert('error', 'Xóa thất bại', result.message);
                hideDeleteUserDialog();
            }
        } else {
            const result = await services.onDeleteMultiple(idDeleteMultiple);
            if (result && result.status === 1) {
                showAlert('success', 'Xóa thành công', result.message);
                hideDeleteUserDialog();
                await fetData();
            } else if (result && result.status === 2) {
                showAlert('error', 'Xóa thất bại', result.message);
                hideDeleteUserDialog();
            }
        }
    };
    const resetPass = async () => {
        const result = await services.onResetPassword(idReset);
        if (result && result.status === 1) {
            showAlert('success', 'Cập nhật thành công', result.message);
            hideResetPasswordDialog();
            await fetData();
            return;
        }
        if (result && result.status === 2) {
            showAlert('error', 'Cập nhật thất bại', result.message);
            hideResetPasswordDialog();
        }
    };

    const switchStatusUser = async () => {
        const result = await services.onSwitchStatusUser(idSwitch);
        if (result && result.status === 1) {
            const message = result.user === '0' ? 'Mở khóa thành công' : 'Đã khóa tài khoản';
            showAlert('success', 'Cập nhật thành công', message);
            hideswitchStatusUserDialog();
            await fetData();
            return;
        }
        if (result && result.status === 2) {
            showAlert('error', 'Cập nhật thất bại', result.message);
            hideswitchStatusUserDialog();
        }
    };

    const handleAsyncUsersOdoo = async () => {
        const result = await services.asyncUsersOdoo();
        if (result && result.status === 1) {
            showAlert('success', 'Cập nhật thành công', 'Đồng bộ người dùng từ Odoo thành công!');
            await fetData();
        } else if (result && result.status === 2) {
            showAlert('error', 'Cập nhật thất bại', result.message);
        }
    };

    const onDeleteMultiple = async () => {
        setDeleteUserDialog(true);
        setTypeDelete(2);
        const listIdUser = [];
        selectedUser.forEach((answer) => {
            listIdUser.push(answer.id);
        });
        setIdDeleteMultiple({ id: listIdUser });
    };

    const rightToolbarTemplate = () => {
        const classSearch = typeRole === 1 ? 'p-mr-2' : '';
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <Button
                        label="Tìm kiếm"
                        icon="pi pi-search"
                        type="submit"
                        className={`p-button-primary w-xs-100 w-md-auto p-mb-2 p-mr-2 ${classSearch}`}
                        onClick={(e) => onSearchClick(e)}
                    />

                    {userPer.data.is_admin_cyber === 1 && typeRole === 1 &&
                        <Button
                            label="Đồng bộ"
                            icon="pi pi-spin pi-cog"
                            className="p-button-info p-mr-2
                                p-mb-2 w-xs-100 w-md-auto"
                            onClick={() => handleAsyncUsersOdoo()}
                        />
                    }
                    {typeRole === 1 && (
                        <Button
                            label="Import"
                            icon="pi pi-file-excel"
                            type="submit"
                            style={{
                                background: 'rgb(123 78 116)',
                                border: '1px solid rgb(123 78 116)',
                            }}
                            className={`w-xs-100 w-md-auto p-mb-2 p-mr-2`}
                            onClick={(e) => setVisibleImportUserDialog(true)}
                        />
                    )}
                    {typeRole === 1 && (
                        <Button
                            label="Xóa"
                            icon="pi pi-trash"
                            className="p-button-danger p-mr-2 p-mb-2  w-xs-100 w-md-auto"
                            onClick={onDeleteMultiple}
                            disabled={selectedUser.length === 0}
                        />
                    )}
                    {typeRole === 1 && (
                        <Button
                            label="Thêm mới"
                            icon="pi pi-plus"
                            className="p-button-success p-mb-2  w-xs-100 w-md-auto"
                            onClick={onCreateClick}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    };
    const deleteUserDialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={hideDeleteUserDialog}
            />
            <Button
                label="Xóa"
                icon="pi pi-check"
                className="p-button-danger p-button-raised"
                onClick={() => deleteUser(typeDelete)}
            />
        </React.Fragment>
    );

    const switchStatusUserDialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={hideswitchStatusUserDialog}
            />
            <Button
                label="Xác nhận"
                icon="pi pi-check"
                className="p-button-primary p-button-raised"
                onClick={() => switchStatusUser()}
            />
        </React.Fragment>
    );

    const resetPasswordDialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={hideResetPasswordDialog}
            />
            <Button
                label="Xác nhận"
                icon="pi pi-check"
                className="p-button-primary p-button-raised"
                onClick={() => resetPass()}
            />
        </React.Fragment>
    );
    const checkBoxBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Chọn</span>
            </React.Fragment>
        );
    };
    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">STT</span>
                {column.rowIndex + 1 + first}
            </React.Fragment>
        );
    };
    const codeBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Mã</span>
                {rowData.code}
            </React.Fragment>
        );
    };
    const emailBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <span className="p-column-title">Email</span>
                    <span className="max-line-3">{rowData.email}</span>
                </div>
            </React.Fragment>
        );
    };
    const fullnameBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Họ tên</span>
                {rowData.fullname}
            </React.Fragment>
        );
    };
    const phoneBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Số điện thoại</span>
                {rowData.phone}
            </React.Fragment>
        );
    };

    const createdAtBodyTemplate = (rowData, column) => {
        const isoDate = parseISO(rowData.created_at);
        const formattedDate = format(isoDate, 'dd/MM/yyyy');
        const formattedTime = format(isoDate, 'HH:mm:ss');

        return (
            <React.Fragment>
                <span className="p-column-title">Thời gian tạo</span>
                <div className="p-d-flex p-flex-column p-jc-center p-ai-center">
                    <p className="p-p-0 p-m-0">{formattedDate}</p>
                    <p className="p-p-0 p-m-0">{formattedTime}</p>
                </div>
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Trạng thái</div>
                    <div className={`p-tag p-tag-${rowData.status === '0' ? 'success' : 'danger'}`}>
                        {rowData.status === '0' ? 'Hoạt động' : 'Không hoạt động'}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    // Lock/Unlock icon follow user status
    const lockStatusUserIcon = (rowData) => {
        const lockStatus =
            rowData.status === '0' ? 'pi-lock text-danger' : 'pi-unlock text-success';
        return `pi ${lockStatus} icon-medium pointer`;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <i
                    className="pi pi-pencil p-mr-2 icon-medium pointer text-success action-table-task"
                    title="Cập nhật"
                    onClick={() => {
                        onEditUser(rowData);
                    }}
                />
                <i
                    className="pi pi-replay icon-medium p-mr-2 text-primary pointer action-table-task"
                    title="Reset password"
                    onClick={() => onResetPasswordUser(rowData)}
                />
                <i
                    className="pi pi-trash icon-medium p-mr-2 text-danger pointer action-table-task"
                    title="Xóa người dùng"
                    onClick={() => onDeleteUser(rowData)}
                />
                <i
                    className={`${lockStatusUserIcon(rowData)} action-table-task`}
                    title={rowData.status === '0' ? 'Khóa người dùng' : 'Mở khóa người dùng'}
                    onClick={() => onSwitchStatus(rowData)}
                />
            </React.Fragment>
        );
    };

    return (
        <div className="user-css">
            <div className="content-section implementation datatable-responsive-demo">
                <div className="card">
                    <div className="card-header">
                        <h5>QUẢN LÝ NGƯỜI DÙNG</h5>
                    </div>
                    <form onSubmit={(e) => onSearchClick(e)}>
                        <Toolbar
                            className="p-mb-4 p-d-flex p-flex-wrap"
                            left={leftToolbarTemplate}
                            right={rightToolbarTemplate}
                        />
                    </form>
                    <DataTable
                        rowHover
                        value={data}
                        emptyMessage="Không có data"
                        className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                        selection={selectedUser}
                        onSelectionChange={(e) => {
                            if (
                                !e?.originalEvent?.target?.classList.contains('action-table-task')
                            ) {
                                setSelectedUser(e.value);
                            }
                        }}
                    >
                        <Column
                            selectionMode="multiple"
                            className="stt-table checkbox-table"
                            body={checkBoxBodyTemplate}
                        />
                        <Column body={renderRowIndex} header="STT" className="stt-table" />
                        <Column field="code" header="Mã" sortable body={codeBodyTemplate} />
                        <Column field="email" header="Email" sortable body={emailBodyTemplate} />
                        <Column
                            field="fullname"
                            header="Họ tên"
                            sortable
                            body={fullnameBodyTemplate}
                        />
                        <Column
                            field="phone"
                            header="Số điện thoại"
                            sortable
                            body={phoneBodyTemplate}
                        />
                        <Column
                            field="status"
                            header="Ngày tạo"
                            sortable
                            body={createdAtBodyTemplate}
                        />
                        <Column
                            field="status"
                            header="Trạng thái"
                            sortable
                            body={statusBodyTemplate}
                        />
                        {typeRole === 1 && (
                            <Column
                                header="Tác vụ"
                                body={actionBodyTemplate}
                                className="p-text-center action-table"
                            />
                        )}
                    </DataTable>
                    <div className="p-d-flex p-flex-wrap">
                        <div className="text-normal p-mt-2">
                            <span>
                                Hiển thị từ <b>{pagination.from}</b> đến <b>{pagination.to}</b> /
                                Tổng <b>{pagination.total}</b> bản ghi
                            </span>
                        </div>
                        <div className="p-ml-auto p-mt-2">
                            <Paginator
                                first={first}
                                rows={row}
                                totalRecords={pagination.total}
                                rowsPerPageOptions={pages}
                                onPageChange={(event) => onPageChange(event)}
                                template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                                pageLinkSize={pageLimit}
                            />
                        </div>
                    </div>
                </div>
                <DialogUser
                    visible={visibleDialog}
                    onHideDialog={onHideDialog}
                    typeAd={typeAd}
                    feData={fetData}
                    dataEdit={dataEdit}
                />

                <Dialog
                    visible={deleteUserDialog}
                    className="p-dialog-default"
                    header={titleDefault.USER_DELETE}
                    typeDelete={typeDelete}
                    modal
                    footer={deleteUserDialogFooter}
                    onHide={hideDeleteUserDialog}
                >
                    <div className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: '2rem' }}
                        />
                        <span> Bạn có chắc chắn muốn xóa người dùng này không?</span>
                    </div>
                </Dialog>

                <Dialog
                    visible={switchStatusUserDialog}
                    className="p-dialog-default"
                    header={currentDialogTitle}
                    typeDelete={typeDelete}
                    modal
                    footer={switchStatusUserDialogFooter}
                    onHide={hideswitchStatusUserDialog}
                >
                    <div className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: '2rem' }}
                        />
                        <span>{currentDialogContent}</span>
                    </div>
                </Dialog>

                <Dialog
                    visible={resetPasswordDialog}
                    className="p-dialog-default"
                    header="Đặt lại mật khẩu mặc định"
                    typeDelete={typeDelete}
                    modal
                    footer={resetPasswordDialogFooter}
                    onHide={hideResetPasswordDialog}
                >
                    <div className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: '2rem' }}
                        />
                        <span> Bạn có chắc chắn muốn reset mật khẩu người dùng này không?</span>
                    </div>
                </Dialog>

                <ImportUserDialog
                    userService={services}
                    visible={visibleImportUserDialog}
                    setVisible={setVisibleImportUserDialog}
                    showAlert={showAlert}
                    notification={notification}
                />
            </div>
        </div>
    );
};
export default withRouter(Users);
