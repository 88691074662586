import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useApp } from 'context/AppContext';
import queryString from 'query-string';
import notification from 'helpers/constant/notification';
import checkPermission from 'helpers/checkPermisson';
import pages from 'helpers/constant/pages';
import permissions from 'helpers/constant/permissions';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toolbar } from 'primereact/toolbar';
import DialogAddEdit from './DialogAddEdit';
import DialogDelete from './DialogDelete';
import GroupServices from 'services/GroupServices';
import UserService from 'services/UserServices';
import { useSelector } from 'react-redux';

export const Group = (props) => {
    //Const
    const filter = props.history.location.search;
    const userPer = useSelector((state) => state.authReducer.userData);
    window.onload = () => {
        props.history.push({
            search: '',
        });
    };
    const { showAlert, pageLimit } = useApp();

    const serviceGroup = new GroupServices();
    const serviceUser = new UserService();
    //State
    const [listUser, setListUser] = useState([]);
    const [listData, setListData] = useState();
    const [typeRole, setTypeRole] = useState(1);
    const [first, setFirst] = useState(0);
    const [row, setRow] = useState(10);
    const [pagination, setPagination] = useState({
        from: 0,
        to: 0,
        row: 0,
        total: 0,
        page: 0,
    });
    const [dataSearch, setDataSearch] = useState({
        name: '',
    });
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [adData, setAdData] = useState({});
    const [typeAd, setTypeAd] = useState(1);
    const [errData, setErrData] = useState({});
    const [dialogDelete, setDialogDelete] = useState(false);
    const [typeDelete, setTypeDelete] = useState(1);
    const [selectedDatas, setSelectedDatas] = useState([]);

    //Effect
    useEffect(() => {
        const typePer = checkPermission(permissions.DEVICE_UPDATE);
        setTypeRole(typePer);
        getListUser();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetData();
        // eslint-disable-next-line
    }, [filter]);

    //Fetch
    const fetData = async () => {
        const query = queryString.parse(props.location.search);
        const response = await serviceGroup.get({ ...dataSearch, ...query });
        if (!response) {
            return;
        }
        const { data, message, status } = response;
        if (status === 2) {
            showAlert('error', notification.FAIL, message);
            return;
        }
        const updatedData = data?.data.map((item) => {
            const group_users = item.users.map((userGroup) => {
                return {
                    code: userGroup?.id,
                    name: userGroup?.email,
                };
            });
            return {
                ...item,
                group_users,
            };
        });
        setListData(updatedData);
        setPagination({
            from: data.from,
            to: data.to,
            row: data.per_page,
            total: data.total,
            page: data.current_page,
        });
    };

    const getListUser = async () => {
        const response = await serviceUser.getAll();
        if (!response) {
            return;
        }
        const { data } = response;
        const arr = [];
        data.map((e) => arr.push({ code: e.id, name: e.email }));
        setListUser(arr);
    };

    //Handle
    const onSearchClick = (e) => {
        e.preventDefault();
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const pramSearch = { ...jsonDataSearch, name: dataSearch.name };
        const query = queryString.stringify(pramSearch);
        props.history.push({
            search: query,
        });
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRow(event.rows);
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const dataSearch = {
            ...jsonDataSearch,
            size: event.rows,
            page: event.page + 1,
        };
        const query = queryString.stringify(dataSearch);
        props.history.push({
            search: query,
        });
    };

    const handleChangeDataSearch = (e) => {
        setDataSearch({ ...dataSearch, [e.target.name]: e.target.value });
    };

    const onCreateClick = () => {
        setAdData({});
        setVisibleDialog(true);
        setTypeAd(1);
    };

    const synchronizedGroupWithOdoo = async () => {
        const response = await serviceGroup.synchronizedGroupWithOdoo('');
        const { message, status } = response;
        if (status === 1) {
            showAlert('success', notification.SUCCESS, message);
            return;
        }
        showAlert('error', notification.FAIL, 'Đồng bộ nhóm thất bại');
        return;
    };

    const onEditClick = (rowData) => {
        setAdData(rowData);
        setTypeAd(2);
        setVisibleDialog(true);
    };

    const onHideDialog = () => {
        setErrData({});
        setVisibleDialog(false);
    };

    const confirmDelete = (rowData) => {
        setTypeDelete(1);
        setAdData(rowData);
        setDialogDelete(true);
    };

    const confirmDeletes = (data) => {
        setTypeDelete(2);
        setDialogDelete(true);
    };

    const handelChangeDeletes = () => {
        setSelectedDatas([]);
        fetData();
    };

    //Template
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <InputText
                    value={dataSearch.name}
                    onChange={(e) => handleChangeDataSearch(e)}
                    placeholder={'Tìm theo tên nhóm'}
                    className=" w-xs-100 w-md-auto p-mb-2"
                    name="name"
                />
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        const classSearch = typeRole === 1 ? 'p-mr-2' : '';

        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <Button
                        label="Tìm kiếm"
                        icon="pi pi-search"
                        type="submit"
                        className={`p-button-primary w-xs-100 w-md-auto p-mb-2 ${classSearch}`}
                        onClick={(e) => onSearchClick(e)}
                    />
                    {typeRole === 1 && userPer.data.is_admin_cyber === 1 && (
                        <Button
                            label="Đồng bộ"
                            icon="pi pi-spin pi-cog"
                            className="p-button-info p-mr-2
                            p-mb-2 w-xs-100 w-md-auto"
                            onClick={(e) => {
                                e.preventDefault();
                                synchronizedGroupWithOdoo();
                            }}
                        />
                    )}
                    {typeRole === 1 && (
                        <Button
                            label="Xóa"
                            icon="pi pi-trash"
                            className="p-button-danger p-mr-2
                    p-mb-2  w-xs-100 w-md-auto"
                            onClick={confirmDeletes}
                            disabled={selectedDatas.length === 0}
                        />
                    )}
                    {typeRole === 1 && (
                        <Button
                            label="Thêm mới"
                            icon="pi pi-plus"
                            className="p-button-success
                    p-mb-2  w-xs-100 w-md-auto"
                            onClick={onCreateClick}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    };

    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">#</span>
                {column.rowIndex + 1 + first}
            </React.Fragment>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Tên</div>
                    <div className="max-line-3" title={rowData.name}>
                        {rowData.name}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const displayNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Tên hiển thị</div>
                    <div className="max-line-3" title={rowData.display_name}>
                        {rowData.display_name}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const displayTypeBodyTemplate = (rowData) => {
        const nameTypeShow = rowData.type.includes('self')
            ? 'Bản thân'
            : rowData.type === 'system'
            ? 'Hệ thống'
            : rowData.type;
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Kiểu tạo</div>
                    <div className="max-line-3" title={nameTypeShow}>
                        {nameTypeShow}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Mô tả</div>
                    <div className="max-line-3" title={rowData.description}>
                        {rowData.description}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            !rowData.type.includes('ERP') && (
                <React.Fragment>
                    <i
                        className="pi pi-pencil p-mr-2 icon-medium pointer text-success action-table-task"
                        onClick={() => onEditClick(rowData)}
                    />
                    <i
                        className="pi pi-trash icon-medium text-danger pointer action-table-task"
                        onClick={() => confirmDelete(rowData)}
                    />
                </React.Fragment>
            )
        );
    };

    return (
        <div className="category-device">
            <div className="content-section implementation datatable-responsive-demo">
                <div className="card">
                    <div className="card-header">
                        <h5>QUẢN LÝ NHÓM</h5>
                    </div>
                    <form onSubmit={(e) => onSearchClick(e)}>
                        <Toolbar
                            className="p-mb-4 p-d-flex p-flex-wrap"
                            left={leftToolbarTemplate}
                            right={rightToolbarTemplate}
                        />
                    </form>
                    <DataTable
                        rowHover
                        value={listData}
                        dataKey="uuid"
                        className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                        emptyMessage="Không có data"
                        selection={selectedDatas}
                        onSelectionChange={(e) => {
                            if (
                                !e?.originalEvent?.target?.classList.contains('action-table-task')
                            ) {
                                setSelectedDatas(e.value);
                            }
                        }}
                    >
                        <Column selectionMode="multiple" className="stt-table checkbox-table" />
                        <Column body={renderRowIndex} header="#" className="stt-table" />
                        <Column field="name" header="Tên" sortable body={nameBodyTemplate} />
                        <Column
                            field="display_name"
                            header="Tên hiển thị"
                            sortable
                            body={displayNameBodyTemplate}
                        />

                        <Column
                            field="type"
                            header="Kiểu tạo"
                            sortable
                            body={displayTypeBodyTemplate}
                        />

                        <Column
                            field="description"
                            header="Mô tả"
                            sortable
                            body={descriptionBodyTemplate}
                        />
                        {typeRole === 1 && (
                            <Column
                                header="Tác vụ"
                                body={actionBodyTemplate}
                                className="action-table p-text-center"
                            />
                        )}
                    </DataTable>
                    {/* phân trang */}
                    <div className="p-d-flex p-flex-wrap">
                        <div className="text-normal p-mt-2">
                            <span>
                                Hiển thị từ <b>{pagination.from}</b> đến <b>{pagination.to}</b> /
                                Tổng <b>{pagination.total}</b> bản ghi
                            </span>
                        </div>
                        <div className="p-ml-auto p-mt-2">
                            <Paginator
                                first={first}
                                rows={row}
                                totalRecords={pagination.total}
                                rowsPerPageOptions={pages}
                                onPageChange={(event) => onPageChange(event)}
                                template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                                pageLinkSize={pageLimit}
                            />
                        </div>
                    </div>
                </div>
                <DialogAddEdit
                    visible={visibleDialog}
                    onHideDialog={onHideDialog}
                    adData={adData}
                    setAdData={setAdData}
                    serviceGroup={serviceGroup}
                    showAlert={showAlert}
                    errData={errData}
                    setErrData={setErrData}
                    fetData={fetData}
                    typeAd={typeAd}
                    listUser={listUser}
                />
                {/* Xóa thiết bị */}
                <DialogDelete
                    dialogDelete={dialogDelete}
                    setDialogDelete={setDialogDelete}
                    adData={adData}
                    serviceGroup={serviceGroup}
                    showAlert={showAlert}
                    typeDelete={typeDelete}
                    selectedDatas={selectedDatas}
                    onDeletes={handelChangeDeletes}
                    fetData={fetData}
                />
            </div>
        </div>
    );
};
export default withRouter(Group);
