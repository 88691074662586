import { SelectedTemplate } from 'components/dropdow-value/SelectedTemplate';
import { OptionTemplate } from 'components/dropdow-value/OptionTemplate';
import { useApp } from 'context/AppContext';
import notification from 'helpers/constant/notification';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import Service from 'services/UserServices';
import titleDefault from 'helpers/constant/titleDefault';

export const DialogUser = (props) => {
    const { showAlert } = useApp();
    const service = new Service();
    const emptyData = {
        email: '',
        password: '',
        confirm_password: '',
        phone: '',
        fullname: '',
        role: '',
        type: '',
    };
    const [adData, setAdData] = useState(emptyData);
    const [dataError, setDataError] = useState(emptyData);
    const [roleOption, setRoleOption] = useState();
    const [groupOption, setGroupOption] = useState();
    const [selectedGroups, setSelectedGroups] = useState([]);
    const typeUser = [
        { name: 'Quản trị phần mềm', code: 1 },
        { name: 'Quản trị phòng họp', code: 2 },
        { name: 'Nhân viên ', code: 3 },
    ];
    const handleChangeInput = (e) => {
        setAdData({ ...adData, [e.target.name]: e.target.value });
    };
    const getDataEdit = () => {
        if (props.dataEdit) {
            const selectedGroups = props.dataEdit.groups.map((groupId) => {
                return groupOption.find((group) => group.uuid === groupId.uuid);
            });

            typeUser.forEach((answer) => {
                if (answer.code === props.dataEdit.type) {
                    setAdData({
                        type: answer,
                        email: props.dataEdit.email,
                        phone: props.dataEdit.phone || '',
                        fullname: props.dataEdit.fullname,
                        role: {
                            name: props.dataEdit.user_role.role.name,
                            code: props.dataEdit.user_role.role_id,
                        },
                        code: props.dataEdit.code,
                        id: props.dataEdit.id,
                    });
                }
            });

            setSelectedGroups(selectedGroups);
            return;
        }
        clearData();
    };
    const onCreateClick = async () => {
        const userToCreate = {
            ...adData,
            group_id: selectedGroups.map((group) => group.uuid),
            // Thêm mảng các nhóm đã chọn vào đối tượng
        };
        const result = await service.onCreateUser(userToCreate);
        try {
            const { error, message, status } = result;
            if (status === 2) {
                if (error) {
                    setDataError(error);
                    showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                } else {
                    showAlert('error', notification.CREATE_FAIL, message);
                }
                return;
            }
            if (status === 1) {
                showAlert('success', notification.CREATE_SUCCESS, message);
                clearData();
                props.onHideDialog();
                await props.feData();
            }
        } catch (error) {
            showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_ERROR);
        }
    };

    const getRole = async () => {
        const result = await service.getListRole();
        if (!result) {
            return;
        }
        const { data, message, status } = result;
        if (status === 2) {
            showAlert('error', notification.CREATE_FAIL, message);
            return;
        }
        setRoleOption(data);
    };

    const getGroup = async () => {
        const result = await service.getAllGroup();
        if (!result) {
            return;
        }
        const { data, message, status } = result;
        if (status === 2) {
            showAlert('error', notification.CREATE_FAIL, message);
            return;
        }
        setGroupOption(data.data);
    };

    const onEditClick = async () => {
        const userToUpdate = {
            ...adData,
            group_id: selectedGroups.map((group) => group.uuid),
        };
        const result = await service.onEditUser(userToUpdate);
        try {
            const { error, message, status } = result;
            if (status === 2) {
                if (error) {
                    setDataError(error);
                    showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                } else {
                    showAlert('error', notification.CREATE_FAIL, message);
                }
                return;
            }
            if (status === 1) {
                showAlert('success', notification.UPDATE_SUCCESS, message);
                clearData();
                props.onHideDialog();
                await props.feData();
            }
        } catch (error) {
            showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
        }
    };

    const clearData = () => {
        setAdData(emptyData);
        setDataError(emptyData);
        setSelectedGroups([]);
    };
    const onHide = () => {
        props.onHideDialog();
        clearData();
    };

    const groupFooterTemplate = () => {
        const length = selectedGroups ? selectedGroups.length : 0;
        return (
            <div className="py-2 px-3">
                <b>{length}</b> nhóm được chọn.
            </div>
        );
    };

    const productDialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={onHide}
            />
            <Button
                label="Lưu"
                icon="pi pi-check"
                className="p-button-raised"
                onClick={props.dataEdit ? onEditClick : onCreateClick}
            />
        </React.Fragment>
    );
    useEffect(() => {
        getRole();
        getDataEdit();
        getGroup();
        // eslint-disable-next-line
    }, [props.dataEdit]);

    return (
        <div>
            <Dialog
                visible={props.visible}
                header={props.typeAd === 1 ? titleDefault.USER_CREATE : titleDefault.USER_UPDATE}
                modal
                className="p-fluid p-dialog-default p-dialog-lg"
                footer={productDialogFooter}
                onHide={onHide}
            >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-col-12 p-md-6 p-field">
                        <label htmlFor="fullname" className="label-bold">
                            Họ tên <font className="text-danger">*</font>
                        </label>
                        <InputText
                            id="fullname"
                            value={adData.fullname}
                            onChange={handleChangeInput}
                            autoFocus
                            name="fullname"
                            placeholder="Nhập họ tên"
                        />
                        <span className="p-invalid">{dataError?.fullname}</span>
                    </div>
                    <div className="p-col-12 p-md-6 p-field">
                        <label htmlFor="phone" className="label-bold">
                            Số điện thoại
                        </label>
                        <InputText
                            id="phone"
                            value={adData.phone}
                            onChange={handleChangeInput}
                            name="phone"
                            placeholder="Nhập số điện thoại"
                        />
                        <span className="p-invalid">{dataError?.phone}</span>
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor="email" className="label-bold">
                            Email <font className="text-danger">*</font>
                        </label>
                        <InputText
                            id="email"
                            value={adData.email}
                            onChange={handleChangeInput}
                            name="email"
                            placeholder="Nhập email"
                        />
                        <span className="p-invalid">{dataError?.email}</span>
                    </div>
                    <div className="p-col-12 p-md-6 p-field">
                        <label htmlFor="type" className="label-bold">
                            Loại người dùng <font className="text-danger">*</font>
                        </label>
                        <Dropdown
                            optionLabel="name"
                            value={adData.type}
                            options={typeUser}
                            onChange={handleChangeInput}
                            placeholder="Chọn loại người dùng"
                            name="type"
                            filter
                            valueTemplate={SelectedTemplate}
                            itemTemplate={OptionTemplate}
                        />
                        <span className="p-invalid">{dataError?.type}</span>
                    </div>
                    <div className="p-col-12 p-md-6 p-field">
                        <label htmlFor="role" className="label-bold">
                            Vai trò <font className="text-danger">*</font>
                        </label>
                        <Dropdown
                            optionLabel="name"
                            value={adData.role}
                            options={roleOption}
                            onChange={handleChangeInput}
                            placeholder="Chọn loại vai trò"
                            name="role"
                            resetFilterOnHide
                            valueTemplate={SelectedTemplate}
                            itemTemplate={OptionTemplate}
                        />
                        <span className="p-invalid">{dataError?.role}</span>
                    </div>
                    <div className="p-col-12 p-md-12 p-field">
                        {/* Nhóm */}
                        <label htmlFor="group_id" className="align-sub-text label-bold">
                            Nhóm
                        </label>
                        <MultiSelect
                            value={selectedGroups}
                            options={groupOption}
                            id="group_id"
                            display="chip"
                            name="group_id"
                            maxSelectedLabels={5}
                            panelFooterTemplate={groupFooterTemplate}
                            onChange={(e) => setSelectedGroups(e.value)}
                            optionLabel="display_name"
                            placeholder="Chọn nhóm"
                            filter
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

DialogUser.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHideDialog: PropTypes.func.isRequired,
    dataEdit: PropTypes.object,
    feData: PropTypes.func.isRequired,
};
