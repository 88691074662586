import classNames from 'classnames'
import { Button } from 'primereact/button'
import { ColorPicker } from 'primereact/colorpicker'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { TabPanel, TabView } from 'primereact/tabview'
import { SelectedTemplate } from 'components/dropdow-value/SelectedTemplate';
import { OptionTemplate } from 'components/dropdow-value/OptionTemplate';
import notification from 'helpers/constant/notification'
import titleDefault from 'helpers/constant/titleDefault'
import React, { useEffect, useState } from 'react'
import DeviceServices from 'services/DeviceServices'
import UserServices from 'services/UserServices'
import { v4 as uuidv4 } from 'uuid'

const RoomMeetingDialogAddEdit = props => {
    const { showAlert, visible, onHideDialog, meetingDevice, fetData, submitted, setSubmitted,
        serviceRoomMeetting, typeAd, adData, setAdData, errData, setErrData, activeIndex, setActiveIndex, listPlace
    } = props;

    const serviceUser = new UserServices();
    const serviceDevice = new DeviceServices();
    const [listUser, setListUser] = useState([]);
    const [listDevice, setListDevice] = useState([]);
    const emptyDevice = { id: uuidv4(), id_device: null, deviceInfo: null, note: '', amount: 1 };
    const [inputFields, setInputFields] = useState([
        emptyDevice,
    ]);
    // Lấy danh sách User
    const getUsers = async () => {
        const typeUser = {
            type: [1, 2]
        }
        const response = await serviceUser.getAllByType(typeUser);
        try {
            const { data } = response;
            const arr = [];
            data.map((e) => arr.push({ code: e.id, name: e.email }));
            setListUser(arr);
        } catch (error) {
        }
    }
    // Lấy danh sách thiết bị
    const getDevices = async () => {
        const response = await serviceDevice.getAll();
        try {
            const { data } = response;
            const arr = [];
            data.map((e) => arr.push({ code: e.id, name: e.name }));
            setListDevice(arr);
        } catch (error) {
        }
    }
    useEffect(() => {
        getUsers();
        getDevices();
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (meetingDevice) {
            setInputFields(meetingDevice);
        }
        // eslint-disable-next-line
    }, [meetingDevice])
    const updateField = (data, field) => {
        setAdData({
            ...adData,
            [field]: data,
        });
    };
    const handleChangeInput = (id, data, field) => {
        const newInputFields = inputFields.map(i => {
            if (id === i.id) {
                i[field] = data;
                if (i.deviceInfo != null) {
                    i.id_device = i.deviceInfo.code;
                }
            }
            return i;
        })
        setInputFields(newInputFields);
    }
    // add thêm thiết bị trống trong phòng
    const handleAddFields = () => {
        setInputFields([...inputFields, emptyDevice])
    }
    const handleRemoveFields = id => {
        const values = [...inputFields];
        const index = values.findIndex(r => r.id === id);
        values.splice(index, 1);
        setInputFields(values);
    }
    const saveData = async (e) => {
        e.preventDefault();
        let saveForm = false;
        inputFields.map((e, index) => {
            saveForm = e.deviceInfo === null ? false : true;
            return saveForm;
        })
        setSubmitted(true);
        if (!saveForm) {
            return;
        }
        const newAdData = { ...adData };
        if (inputFields) {
            newAdData.device = inputFields;
        }
        if (typeAd === 1) {
            try {
                const result = await serviceRoomMeetting.create(newAdData);
                const { error, message } = result;
                if (result && result.status === 2) {
                    if (error) {
                        setActiveIndex(0);
                        showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                        setErrData(error);
                    } else {
                        showAlert('error', notification.CREATE_FAIL, message);
                    }
                }
                else {
                    showAlert('success', notification.CREATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_ERROR);
            }
        } else {
            try {
                const result = await serviceRoomMeetting.update(newAdData);
                const { error, message } = result;
                if (result && result.status === 2) {
                    if (error) {
                        setActiveIndex(0);
                        showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_VALIDATE);
                        setErrData(error);
                    } else {
                        showAlert('error', notification.UPDATE_FAIL, message);
                    }
                }
                else {
                    showAlert('success', notification.UPDATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
            }
        }
    }
    const dialogFooterPrevios = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised p-mr-2" onClick={onHideDialog} />
            <Button label="Tiếp" icon="pi pi-angle-right" className="p-button-raised" onClick={() => setActiveIndex(1)} />
        </React.Fragment>
    );
    const dialogFooterNext = (
        <React.Fragment>
            <div>
                <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised p-mr-2" onClick={onHideDialog} />
                <Button label="Lùi" icon="pi pi-angle-left" className="p-button-raised p-mr-2 " onClick={() => setActiveIndex(0)} />
                <Button label="Lưu" icon="pi pi-check" type="submit" className="p-button-raised" onClick={(e) => saveData(e)} />
            </div>
        </React.Fragment>
    );
    return (
        <div className="">
            <Dialog visible={visible}
                header={typeAd === 1 ? titleDefault.ROOM_MEETING_CREATE : titleDefault.ROOM_MEETING_UPDATE}
                footer={activeIndex === 1 ? dialogFooterNext : dialogFooterPrevios}
                modal className="p-fluid p-dialog-default p-dialog-lg wr--50 RoomMeetingDialogAddEdit"
                onHide={onHideDialog}>
                <form onSubmit={(e) => saveData(e)}>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Thông tin phòng họp">
                            <div className="p-grid">
                                <div className=" p-col-12 p-field">
                                    <label htmlFor="name" className="label-bold">Tên phòng <span className="text-danger">*</span></label>
                                    <InputText id="name" value={adData.name}
                                        onChange={(e) => updateField(e.target.value, 'name')}
                                        placeholder={"Nhập tên phòng họp"} tooltip="Nhập tên phòng họp" tooltipOptions={{ position: 'top' }}
                                    />
                                    <span id="username2-help" className="p-invalid p-d-block">{errData.name}</span>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className=" p-col-12 p-ml-0 p-md-6 p-field">
                                    <label htmlFor="place_id" className="label-bold">Chọn địa điểm <span className="text-danger">*</span></label>
                                    <Dropdown id="place_id" value={adData.place_id} options={listPlace}
                                        onChange={(e) => updateField(e.target.value, 'place_id')} optionLabel="name" filter
                                        showClear filterBy="name" placeholder="Chọn địa điểm"
                                        tooltip="Chọn địa điểm" tooltipOptions={{ position: 'top' }}
                                        valueTemplate={SelectedTemplate} itemTemplate={OptionTemplate}
                                        optionValue="code"
                                    />
                                    <span id="username2-help" className="p-invalid p-d-block">{errData.place_id}</span>
                                </div>
                                <div className="p-col-12 p-ml-0 p-md-6 p-field">
                                    <label htmlFor="capacity" className="label-bold">Sức chứa</label>
                                    <InputNumber id="capacity" value={adData.capacity}
                                        onChange={(e) => updateField(e.value, 'capacity')} mode="decimal" useGrouping={false} min={1} />
                                    <span id="username2-help" className="p-invalid p-d-block">{errData.capacity}</span>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-ml-0 p-md-6 p-field">
                                    <label htmlFor="owner" className="label-bold">Người phụ trách phòng <span className="text-danger">*</span></label>
                                    <Dropdown id="owner" value={adData.owner} options={listUser}
                                        onChange={(e) => updateField(e.target.value, 'owner')}
                                        optionLabel="name" filter showClear filterBy="name"
                                        placeholder="Nhập email" tooltip="Nhập email" tooltipOptions={{ position: 'top' }}
                                        scrollHeight="7rem"
                                        valueTemplate={SelectedTemplate} itemTemplate={OptionTemplate}
                                        optionValue="code"
                                    />
                                    <span id="username2-help" className="p-invalid p-d-block">{errData.owner}</span>
                                </div>
                                <div className="p-col-12 p-ml-0 p-md-6 p-field">
                                    <label htmlFor="color" className="label-bold">Màu phòng họp</label>
                                    <ColorPicker
                                        value={adData.color}
                                        onChange={(e) => updateField(e.target.value, 'color')}
                                        defaultColor="000000"
                                        className="cs-input-color"
                                    />
                                    <span id="username2-help" className="p-invalid p-d-block">{errData.color}</span>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-field">
                                    <label htmlFor="note" className="label-bold">Ghi chú</label>
                                    <InputTextarea id="note" value={adData.note||""}
                                        placeholder="Ghi chú"
                                        onChange={(e) => updateField(e.target.value, 'note')} rows={3} cols={20}
                                    />
                                    <span id="username2-help" className="p-invalid p-d-block">{errData.note}</span>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Thông tin thiết bị" className="dialog-scrol">
                            {/* <div className="p-d-flex">
                                <div className="p-ml-auto add-btn">
                                    <Button className="p-button-rounded p-button-success" icon="pi pi-plus"
                                        onClick={handleAddFields}
                                        tooltip="Thêm thiết bị" />
                                </div>
                            </div> */}
                            <div className="p-grid p-mb-2 p-d-none p-d-md-flex">
                                <div className="p-col-12 p-md-4 label-bold">Tên thiết bị</div >
                                <div className="p-col-12 p-md-4 label-bold">Ghi chú</div >
                                <div className="p-col-12 p-md-2 label-bold">Số lượng</div >
                                <div className="p-col-12 p-md-2 label-bold">Xóa</div >
                            </div>
                            {inputFields.map(inputField => (
                                <div key={inputField.id} className="p-mb-2">
                                    <div className="p-grid">
                                        <div className="p-col-10 p-md-4">
                                            <Dropdown id="deviceInfo" value={inputField.deviceInfo} options={listDevice}
                                                onChange={(e) => handleChangeInput(inputField.id, e.target.value, 'deviceInfo')}
                                                optionLabel="name" filter showClear filterBy="name" required autoFocus
                                                placeholder="Chọn thiết bị" tooltip="Chọn thiết bị" tooltipOptions={{ position: 'top' }}
                                                className={classNames({ 'p-invalid': submitted && !inputField.deviceInfo })}
                                            />
                                            {submitted && !inputField.deviceInfo && <span className="p-invalid">Thiết bị không được bỏ trống.</span>}
                                        </div>
                                        <div className="p-col-10 p-md-4">
                                            <InputText
                                                tooltip="Ghi chú" tooltipOptions={{ position: 'top' }}
                                                placeholder="Ghi chú"
                                                value={inputField.note}
                                                onChange={e => handleChangeInput(inputField.id, e.target.value, 'note')}
                                            />
                                        </div>
                                        <div className="p-col-10 p-md-2">
                                            <InputNumber
                                                tooltip="Số lượng" tooltipOptions={{ position: 'top' }}
                                                placeholder="Số lượng"
                                                value={inputField.amount}
                                                onChange={e => handleChangeInput(inputField.id, e.value, 'amount')}
                                                min={1}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-2">
                                            {/* remove */}
                                            <Button className="p-button-rounded p-button-danger p-mr-2" icon="pi pi-minus"
                                                disabled={inputFields.length === 1} onClick={() => handleRemoveFields(inputField.id)}
                                                tooltip="Xóa"
                                            />
                                            <Button className="p-button-rounded p-button-success" icon="pi pi-plus"
                                                onClick={handleAddFields}
                                                tooltip="Thêm thiết bị" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </TabPanel>
                    </TabView>
                </form>
            </Dialog>
        </div>
    )
}

export default (RoomMeetingDialogAddEdit);

