import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";
class DeviceServices extends FetchAPI {
    public async getAll(data) {
        const url = `thiet-bi/getAllDevice`;
        return await this.request(url, { data }, TypeMethodConstantEnum.GET);
    }
    public async getFilter(data) {
        const url = `thiet-bi/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async get(data) {
        const url = `/thiet-bi/getDeviceById`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async create(data) {
        const url = `thiet-bi/create`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async update(data) {
        const url = `thiet-bi/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async delete(data) {
        const url = `thiet-bi/delete`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async deleteMultiple(data) {
        const url = `thiet-bi/deleteMultiple`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
}
export default DeviceServices;
