import React, { useEffect, useState } from "react";
import Chart from 'react-apexcharts';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from "moment";
import { useApp } from 'context/AppContext';
import queryString from 'query-string';
import notification from 'helpers/constant/notification';
const ColumnChart = props => { 
    const { showAlert } = useApp();
    const {filter, jsonDataSearch, queryColumn, history, serviceDashboard, setFilterColumn} = props;

    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const [startDayUseRoomRatio, setStartDayUseRoomRatio] = useState(thirtyDaysAgo);
    const [endDayUseRoomRatio, setEndDayUseRoomRatio] = useState(today);

    const [chartDataUseRoomRatio, setChartDataUseRoomRatio] = useState({
        categories: [],
        timeUsed: [],
        timeUnused: []
    });

    const [dataSearchColumn, setDataSearchColumn] = useState({
        start_day_room_ratio: "",
        end_day_room_ratio: "",
    });

    const handleChangeDataSearchColumn = (e) => {
        setDataSearchColumn({ ...dataSearchColumn, [e.target.name]: e.target.value });
    }

    const onSearchClickColumn = (e) => {
        e.preventDefault();
      
        const startDayFormattedUseRoomRatio = startDayUseRoomRatio ? moment(startDayUseRoomRatio).format('YYYY-MM-DD') : "";
        const endDayFormattedUseRoomRatio = endDayUseRoomRatio ? moment(endDayUseRoomRatio).format('YYYY-MM-DD') : "";

        const pramSearch = { 
            ...jsonDataSearch, 
            start_day_room_ratio: startDayFormattedUseRoomRatio, 
            end_day_room_ratio: endDayFormattedUseRoomRatio, 
        };
        const query = queryString.stringify(pramSearch);
        history.push({
            search: query,
        });
        setFilterColumn(query);
    }

    window.onload = () => {
        history.push({
            search: "",
        });
    };
    const getDashboardDataRoomRatio = async () => {
        try {
            const dataDate = {
                ...dataSearchColumn,
                ...queryColumn 
            }

            // ValidateTime Column Chart
            if(dataDate.start_day_room_ratio >= dataDate.end_day_room_ratio && dataDate.start_day_room_ratio && dataDate.end_day_room_ratio) {
                showAlert(
                    "error", 
                    notification.STATISTICAL_FAIL, 
                    notification.STATISTICAL_FAIL_FISRT
                );
                return
            }

            const presentTime = new Date();
            const startDayRoomRatio = new Date(dataDate.start_day_room_ratio);
            if(dataDate.start_day_room_ratio && !dataDate.end_day_room_ratio && startDayRoomRatio > presentTime) {
                showAlert(
                    "error", 
                    notification.STATISTICAL_FAIL, 
                    notification.STATISTICAL_FAIL_SECOND
                );
                return;
            }
           
            const response = await serviceDashboard.roomTimeUsed(dataDate);

            const { dataTimeUsed, nameRoom, daysCount } = response;

            // Time Used Convert To Hours
            const timeUsed = dataTimeUsed.map(time => (time / 60).toFixed(1));

            // Time Unused Convert To Hours
            const timeUnused = timeUsed.map(time => (daysCount * 8 - time).toFixed(1));
            
            if(response.status === 1 ){
                setChartDataUseRoomRatio({
                    categories: nameRoom,
                    timeUsed,
                    timeUnused
                });
            }
           
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getDashboardDataRoomRatio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const getLightThemeUseRoomRatio = () => { 
        const optionsUseRoomRatio = {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%'
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            xaxis: {
                categories: chartDataUseRoomRatio.categories,
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                offsetX: 0,
                offsetY: 0,
            },
        };

        return {
            optionsUseRoomRatio,
        }
    }
    
    const { optionsUseRoomRatio } = getLightThemeUseRoomRatio();

    const seriesUseRoomRatio = [
        {
            name: 'Thời gian sử dụng',
            data: chartDataUseRoomRatio.timeUsed
        },
        {
            name: 'Thời gian không sử dụng',
            data: chartDataUseRoomRatio.timeUnused
        },
    ];

    return (
        <div>
            <div className="p-d-flex p-justify-between">
                <h5 className="title-chart p-mb-0 p-col-6 p-md-6">
                    Tỷ lệ sử dụng các phòng <br/>
                    <span className="sub-title-chart-detail">
                        ( Mặc định hiển thị: <span>30 ngày gần nhất</span> )
                    </span>
                </h5>
                <form className="p-col-6 p-md-6 p-d-flex form-search-chart" onSubmit={(e) => onSearchClickColumn(e)}>
                    <div className="p-inputgroup p-col-5 p-md-5 p-p-0">
                        <Calendar
                            value={startDayUseRoomRatio} 
                            onChange={(e) => {
                                    setStartDayUseRoomRatio(e.value) 
                                    handleChangeDataSearchColumn(e);
                                }
                            } 
                            dateFormat="dd/mm/yy"
                            // maxDate={new Date()}
                            name="start_day_room_ratio"
                            className="color-input-custom"
                            placeholder="Từ ngày"
                        />
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-calendar" />
                        </span>
                    </div>
                    <div className="p-inputgroup p-col-5 p-md-5 p-p-0">
                        <Calendar
                            value={endDayUseRoomRatio} 
                            onChange={(e) => {
                                    setEndDayUseRoomRatio(e.value) 
                                    handleChangeDataSearchColumn(e);
                                }
                            } 
                            dateFormat="dd/mm/yy"
                            name="end_day_room_ratio"
                            className="color-input-custom"
                            placeholder="Đến ngày"
                        />
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-calendar" />
                        </span>
                    </div>
                    <div className="p-inputgroup p-col-2 p-md-2 p-p-0">
                        <Button icon="pi pi-search"                className="btn-search-home" 
                            severity="success" 
                            aria-label="Search" 
                            title="Tìm kiếm" 
                            onClick={(e) => onSearchClickColumn(e)} 
                        />  
                    </div>
                </form>
            </div>
            <p className="p-p-0 p-m-0 sub-title-chart">
                ( Thống kê hiển thị được tính theo: Giờ )
            </p>
            <Chart 
                options={optionsUseRoomRatio} 
                series={seriesUseRoomRatio} 
                type="bar" 
                height={450}
            />
        </div>        
    );
}

export default ColumnChart