import axiosClient from 'helpers/axiosClient';

const AuthService = {
    getUserInfo: (params) => {
        const url = `tai-khoan/getUserInfo`;
        return axiosClient.get(url, { params });
    },
};

export default AuthService;
