import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React from 'react'
import PropTypes from 'prop-types'
import notification from 'helpers/constant/notification';
import titleDefault from 'helpers/constant/titleDefault';



function DialogDelete(props) {
    const { dialogDelete, setDialogDelete, fetData, serviceDevice, showAlert, adData, typeDelete, onDeletes, selectedDatas } = props;

    const onHideDialog = () => {
        setDialogDelete(false);
    }
    let idDeletes = [];
    const handelChangeDelete = async () => {
        if (typeDelete === 1) {
            try {
                const response = await serviceDevice.delete(adData);
                const { status, message } = response;
                onHideDialog();
                if (status === 2) {
                    showAlert('error', notification.DELETE_FAIL, message);
                }
                if (status === 1) {
                    showAlert('success', notification.DELETE_SUCCESS, message);
                    fetData();
                }
            } catch (error) {
                onHideDialog();
                showAlert('error', notification.DELETE_FAIL, notification.MESSAGE_ERROR);
            }
        } else {
            try {
                selectedDatas.map((p) => idDeletes.push(p.id));
                let deletesData = {
                    id: idDeletes,
                }
                const response = await serviceDevice.deleteMultiple(deletesData);
                idDeletes = [];
                const { status, message } = response;
                onHideDialog();
                if (status === 2) {
                    showAlert('error', notification.DELETE_FAIL, message);
                }
                if (status === 1) {
                    showAlert('success', notification.DELETE_SUCCESS, message);
                    onDeletes();
                }
            } catch (error) {
                onHideDialog();
                showAlert('error', notification.DELETE_FAIL, notification.MESSAGE_ERROR);
            }
        }
    }
    const deleteDialogFooter = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-raised p-button-text p-button-danger" onClick={onHideDialog} />
            <Button label="Xóa" icon="pi pi-check" className="p-button-danger p-button-raised" onClick={handelChangeDelete} />
        </React.Fragment>
    );
    return (
        <div>
            <Dialog visible={dialogDelete}
            className="p-dialog-default"
            header={titleDefault.DEVICE_DELETE} modal footer={deleteDialogFooter} onHide={onHideDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {adData.name && typeDelete === 1 && <span>Bạn có chắc chắn muốn xóa thiết bị <b>{adData.name}</b>?</span>}
                    {typeDelete === 2 && <span>Bạn có chắc chắn muốn xóa nhiều thiết bị?</span>}
                </div>
            </Dialog>
        </div>
    )
}

DialogDelete.propTypes = {
    adData: PropTypes.object,
}
DialogDelete.defaultProps = {
    adData: {
        name: ""
    },
}
export default (DialogDelete);
