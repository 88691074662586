import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { useApp } from 'context/AppContext';
import DialogShowDetail from './DialogShowDetail';
import moment from "moment";
import './evaluate.scss';
import pages from 'helpers/constant/pages';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Rating from 'react-rating';
import { withRouter } from "react-router-dom";
import EvaluateServices from 'services/EvaluateServices';
import notification from 'helpers/constant/notification';
import { format } from 'date-fns';

export const Evaluate = (props) => {
    const { showAlert, pageLimit } = useApp();
    const serviceEvaluate = new EvaluateServices();

    // danh sách
    const emptyData = {
        title: "",
        room_id: null,
        host_id: null,
        start_time: null,
        end_time: null,
        description: "",
        repeat: false,
        type_repeat: null,
        end_date_repeat: null,
        request_device: "",
        user_id: null,
        date: null,
        user_list: [],
        user_list_other: [],
        status: 1,
        file_attach: [],
        typeView: 1,
        typeUpdate: 1,
        user_host_booking: {},
        room: {},
        evaluates: []
    };

    const [listData, setListData] = useState();
    const [selectedHost, setSelectedHost] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);

    const [selectedHostValue, setSelectedHostValue] = useState([]);
    const [selectedRoomValue, setSelectedRoomValue] = useState([]);
    const [visible, setVisible] = useState(false);
    const [dataShowDetailRating, setDataShowDetailRating] = useState(emptyData);

    const [date, setDate] = useState();

    // data phân trang
    const filter = props.history.location.search;
    const [first, setFirst] = useState(0);
    const [row, setRow] = useState(10);
    const [pagination, setPagination] = useState({
        from: 0,
        to: 0,
        row: 0,
        total: 0,
        page: 0,
    });

    const [dataSearch, setDataSearch] = useState({
        host_id: "",
        room_id: "",
        date_meeting: ""
    });

    const onShowDetailEvaluateRoom = (rowData) => {
        setDataShowDetailRating(rowData);
        setVisible(true);
    };

    const onHideDialog = () => {
        setDataShowDetailRating(emptyData);
        setVisible(false);
    };

    const handleChangeDataSearch = (e) => {
        setDataSearch({ ...dataSearch, [e.target.name]: e.target.value });
    }

    const onSearchClick = (e) => {
        e.preventDefault();
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const date_meeting = moment(dataSearch.date_meeting).format('YYYY-MM-DD');
        const pramSearch = { 
            ...jsonDataSearch, 
            host_id: dataSearch.host_id?.id, 
            room_id: dataSearch.room_id?.id, 
            date_meeting: date_meeting === 'Invalid date' ? '' : date_meeting, 
        };
        const query = queryString.stringify(pramSearch);
        props.history.push({
            search: query,
        });
    }

    const getHostAndRoom = async() => {
        const result = await serviceEvaluate.getHostAndRoom();
        if(!result) {
            return;
        }
        const { meetingRooms, groups, message, status} = result;
        if (status === 2) {
            showAlert("error", notification.CREATE_FAIL, message);
            return;
        }
        setSelectedHostValue(groups);
        setSelectedRoomValue(meetingRooms);
    }

    useEffect(() => {
        fetData();
        getHostAndRoom();
        // eslint-disable-next-line
    }, [filter])

    const fetData = async () => {
        const query = queryString.parse(props.location.search);
        const response = await serviceEvaluate.getEvaluateMeeting({ 
            ...dataSearch,
            ...query 
        });
        if (!response) {
            return;
        }
        const { data, message, status } = response;
        if (status === 2) {
            showAlert('error', notification.FAIL, message);
            return;
        }
            
        setListData(data.data);
        setPagination({
            from: data.from,
            to: data.to,
            row: data.per_page,
            total: data.total,
            page: data.current_page,
        })
    }

    // Phân trang
    const onPageChange = (event) => {
        setFirst(event.first);
        setRow(event.rows);
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const dataSearch = {
            ...jsonDataSearch,
            row: event.rows,
            page: (event.page + 1)
        }
        const query = queryString.stringify(dataSearch);
        props.history.push({
            search: query,
        });
    };

    // f5 lại trang xóa dữ liệu tìm kiếm trên URL
    window.onload = () => {
        props.history.push({
            search: "",
        });
    };

    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">STT</span>
                {column.rowIndex + 1 + first}
            </React.Fragment>
        );
    }

    const titleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Tiêu đề</div>
                    <div className="max-line-3" title={rowData.title}>
                        {rowData.title}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const emailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Email</div>
                    <div className="max-line-3" title={rowData.user_host_booking.email}>
                        {rowData.user_host_booking.email}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const hostBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Người chủ trì</div>
                    <div className="max-line-3" title={rowData.user_host_booking.fullname}>
                        {rowData.user_host_booking.fullname}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const roomMeetingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Phòng họp</div>
                    <div className="max-line-3" title={rowData.room.name}>
                        {rowData.room.name}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const timeMeetingBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex p-jc-center">
                    <div className="p-column-title title">Thời gian họp</div>
                    <div className="p-d-flex p-flex-column p-jc-center p-ai-center" title="Thời gian họp">
                        <p className="p-p-0 p-m-0">
                            {format(new Date(rowData.date), 'dd/MM/yyyy')}
                        </p>
                        <p className="p-p-0 p-m-0">{rowData.start_time} - {rowData.end_time}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const avaluateRating = (rowData) => {
        const evaluates = rowData.evaluates;

        let totalStars = 0;
        const numberOfEvaluates = evaluates.length;

        evaluates.forEach((evaluate) => {
            totalStars += evaluate.stars;
        });

        const averageStars = numberOfEvaluates > 0 ? totalStars / numberOfEvaluates : 0;
        const averageWithDecimal = averageStars.toFixed(1);
        
        return (
            <Rating
                className='pointer'
                readonly
                initialRating={averageWithDecimal}
                fractions={20}
                emptySymbol={<span className="icon">&#9734;</span>}
                fullSymbol={<span className="icon full">&#9733;</span>}
            /> 
        )
    }

    const RatingBarChart = (rowData) => {
        let totalStars = 0
        let countRating = 0;
        let fiveStar = 0;
        let fourStar = 0;
        let threeStar = 0;
        let twoStar = 0;
        let oneStar = 0;

        rowData.evaluates.forEach((item, index, array) => {
            totalStars += item.stars;
            countRating = array.length;
            if(item.stars === 5) {
                fiveStar++;
            } else if(item.stars === 4) {
                fourStar++;
            } else if(item.stars === 3) {
                threeStar++;
            } else if(item.stars === 2) {
                twoStar++;
            } else {
                oneStar++;
            }
        });

        const newData = {
            fiveStar,
            fourStar,
            threeStar,
            twoStar,
            oneStar,
            totalStar: totalStars,
            totalRating: countRating,
            avgStars: countRating > 0 ? (totalStars / countRating).toFixed(1) : 0,
        };
        
        if(newData.totalStar > 0) {
            return (
                <div className="box-star">
                    <div className="box-star--above">
                        <div className="point">
                            {/* Nếu phần thập phân của nó là 0 thì không lấy */}
                            <p>{newData.avgStars.split('.')[1] > 0 ? newData.avgStars : newData.avgStars.split('.')[0] }/5</p> 

                            {avaluateRating(rowData)}

                            <span 
                                className='pointer amount-evaluate'
                                style={{display: 'block'}}
                                onClick={() => onShowDetailEvaluateRoom(rowData)}
                            >
                                {newData.totalRating} đánh giá 
                            </span>
                        </div>
                        <ul className="rate-list">
                            <li style={{ listStyle: "none"}}>
                                <div className="number-star">
                                    5 <i className="pi pi-star pointer" />
                                </div>
                                <div className="timeline-star">
                                    <p className="timing" style={{width: `${(fiveStar / countRating) * 100}%`}} />
                                </div>
                                <span className="number-percent">{((fiveStar / countRating) * 100).toFixed(0)}%</span>
                            </li>
                            <li style={{ listStyle: "none"}}>
                                <div className="number-star">
                                    4 <i className="pi pi-star pointer" />
                                </div>
                                <div className="timeline-star">
                                    <p className="timing" style={{width: `${(fourStar / countRating) * 100}%`}} />
                                </div>
                                <span className="number-percent">{((fourStar / countRating) * 100).toFixed(0)}%</span>
                            </li>
                            <li style={{ listStyle: "none"}}>
                                <div className="number-star">
                                    3 <i className="pi pi-star pointer" />
                                </div>
                                <div className="timeline-star">
                                    <p className="timing" style={{width: `${(threeStar / countRating) * 100}%`}} />
                                </div>
                                <span className="number-percent">{((threeStar / countRating) * 100).toFixed(0)}%</span>
                            </li>
                            <li style={{ listStyle: "none"}}>
                                <div className="number-star">
                                    2 <i className="pi pi-star pointer" />
                                </div>
                                <div className="timeline-star">
                                    <p className="timing" style={{width: `${(twoStar / countRating) * 100}%`}} />
                                </div>
                                <span className="number-percent">{((twoStar / countRating) * 100).toFixed(0)}%</span>
                            </li>
                            <li style={{ listStyle: "none"}}>
                                <div className="number-star">
                                    1 <i className="pi pi-star pointer" />
                                </div>
                                <div className="timeline-star">
                                    <p className="timing" style={{width: `${(oneStar / countRating) * 100}%`}} />
                                </div>
                                <span className="number-percent">{((oneStar / countRating) * 100).toFixed(0)}%</span>
                            </li>

                        </ul>
                    </div>
                </div>
            )
        }
    };

    const evaluateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex p-jc-center">
                    <div className="p-column-title">Đánh giá</div>
                    <div 
                        className="p-d-flex p-flex-column p-jc-center p-ai-center parent-show"
                        style={{ padding: "10px 0" }}
                    >      
                        {avaluateRating(rowData)}
                        {RatingBarChart(rowData)}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const selectedRoomTemplate = (option, props) => {
        if (option) {
            return (
                <div className="p-d-flex align-items-center">
                    <div style={{ border: '9px solid', color: `#${option.color}` }} />
                    <div className='p-pl-2'>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const roomOptionTemplate = (option) => {
        return (
            <div className="p-d-flex align-items-center">
                <div style={{ border: '9px solid', color: `#${option.color}` }} />
                <div className='p-pl-2'>{option.name}</div>
            </div>
        );
    };

     // action sửa xóa
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <i className="pi pi-eye icon-medium pointer text-primary" 
                title='Chi tiết đánh giá'
                onClick={() => onShowDetailEvaluateRoom(rowData)} />
            </React.Fragment>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <Dropdown value={selectedHost} 
                        onChange={(e) => {
                                setSelectedHost(e.value);
                                handleChangeDataSearch(e);
                            }
                        } 
                        options={Object.values(selectedHostValue).map(({ id, fullname }) => ({ id, fullname }))}
                        optionLabel="fullname" filter 
                        filterBy="fullname"
                        name="host_id" showClear 
                        placeholder="Người chủ trì" className="w-full md:w-14rem p-mr-2" 
                    />
                    
                    <Dropdown value={selectedRoom} 
                        onChange={(e) => {
                                setSelectedRoom(e.value)
                                handleChangeDataSearch(e);
                            }
                        } 
                        options={selectedRoomValue.map(({ id, name, color }) => ({ id, name, color }))}
                        valueTemplate={selectedRoomTemplate} 
                        itemTemplate={roomOptionTemplate}
                        optionLabel="name" filter 
                        filterBy="name"
                        name="room_id" showClear 
                        placeholder="Phòng họp" className="w-full md:w-14rem p-mr-2"
                    />

                    <Calendar 
                        value={date} 
                        onChange={(e) => {
                                setDate(e.value) 
                                handleChangeDataSearch(e);
                            }
                        } 
                        showIcon 
                        className='p-mr-2'
                        placeholder="Ngày họp"
                        name="date_meeting"
                        dateFormat="dd/mm/yy"
                    />

                    <Button label="Tìm kiếm" 
                        icon="pi pi-search" 
                        type="submit"
                        className="p-button-primary w-xs-100 w-md-auto"
                        onClick={(e) => onSearchClick(e)} 
                    />
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className="category-evaluate">
            <div className="content-section implementation datatable-responsive-demo">
                <div className="card">
                    <div className="card-header">
                        <h5>LỊCH SỬ ĐÁNH GIÁ CUỘC HỌP</h5>
                    </div>
                    <form onSubmit={(e) => onSearchClick(e)}>
                        <Toolbar 
                            className="p-mb-4 p-d-flex p-flex-wrap p-pb-2"
                            left={leftToolbarTemplate}
                        />
                    </form>
                    <DataTable
                        rowHover
                        value={listData}
                        dataKey="id"
                        className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                        emptyMessage="Không có data"
                    >
                        <Column body={renderRowIndex} header="STT" className="stt-table"/>
                        <Column field="title" header="Tiêu đề" sortable body={titleBodyTemplate} />
                        <Column field="email" header="Email" sortable body={emailBodyTemplate} />
                        <Column field="host_name" header="Người chủ trì" sortable body={hostBodyTemplate} />
                        <Column field="room_meeting" header="Phòng họp" sortable body={roomMeetingBodyTemplate} />
                        <Column field="time_meeting" header="Thời gian họp" sortable body={timeMeetingBodyTemplate} className="action-table p-text-center"/>
                        <Column field="evaluate" header="Đánh giá" body={evaluateBodyTemplate} className="action-table p-text-center"/>
                        <Column header="Tác vụ" body={actionBodyTemplate}
                            className="action-table p-text-center"
                        />

                    </DataTable>
                    {/* phân trang */}
                    <div className="p-d-flex p-flex-wrap">
                        <div className="text-normal p-mt-2">
                            <span>Hiển thị từ <b>{pagination.from}</b> đến <b>{pagination.to}</b> / Tổng <b>{pagination.total}</b> bản ghi</span>
                        </div>
                        <div className="p-ml-auto p-mt-2">
                            <Paginator first={first} rows={row} totalRecords={pagination.total}
                                rowsPerPageOptions={pages} onPageChange={(event) => onPageChange(event)}
                                template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                                pageLinkSize={pageLimit}
                            />
                        </div>
                    </div>
                    <DialogShowDetail
                        visible={visible}
                        onHideDialog={onHideDialog}
                        dataShowDetailRating={dataShowDetailRating}
                        setDataShowDetailRating={setDataShowDetailRating}
                    />
                </div>
            </div>
        </div>
    );

}
export default withRouter(Evaluate);
