import React from 'react';
import { AppProvider } from 'context/AppProvider';
import MainPage from 'components/App/MainPage';

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/style/custom/custom.scss';
import { KeycloakProvider } from '@react-keycloak/web';
import keycloak from 'configs/keycloakConfig';
import store from 'app/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'routers/PrivateRoute';

const App = () => {
    return (
        <Provider store={store}>
            <AppProvider>
                <KeycloakProvider keycloak={keycloak}>
                    <AppProvider>
                        <BrowserRouter>
                            <Switch>
                                <PrivateRoute path="/" component={MainPage} />
                            </Switch>
                        </BrowserRouter>
                    </AppProvider>
                </KeycloakProvider>
            </AppProvider>
        </Provider>
    );
};
export default App;
