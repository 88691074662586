import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function SubmitWorkingDayConfigDialog(props) {
    const { visible, setVisible, workingDays, setWorkingDays, config, setConfig } = props;

    const [workingDayCurrent, setWorkingDayCurrent] = useState({});

    useEffect(() => {
        const foundObject = workingDays.find((item) => item.current === true);
        setWorkingDayCurrent(foundObject);
        // eslint-disable-next-line
    }, [workingDays]);

    const handleSubmitChangeStatusWorkingDay = () => {
        const index = workingDays.findIndex((item) => item.current === true);
        if (index !== -1) {
            const value = config?.working_day[index] === 1 ? 0 : 1;
            const copiedObject = { ...config };
            copiedObject.working_day[index] = value;
            setConfig(copiedObject);
            const resteCurrentData = workingDays.map((item) => ({
                ...item,
                current: false,
            }));
            setWorkingDays(resteCurrentData);
            setVisible(false);
        }
    };

    const deleteDialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-raised p-button-text p-button-danger"
                onClick={() => setVisible(false)}
            />
            <Button
                label="Xác nhận"
                icon="pi pi-check"
                className="p-button-primary p-button-raised"
                onClick={() => handleSubmitChangeStatusWorkingDay()}
            />
        </React.Fragment>
    );
    return (
        <div>
            <Dialog
                visible={visible}
                className="p-dialog-default"
                header={'Chuyển trạng thái ngày làm việc'}
                modal
                footer={deleteDialogFooter}
                onHide={() => setVisible(false)}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {workingDayCurrent && (
                        <span>
                            Bạn có chắc chắn muốn chuyển trạng thái làm việc của{' '}
                            <b>{workingDayCurrent?.name}</b> thành{' '}
                            <b>{workingDayCurrent?.value === 1 ? 'Nghỉ' : 'Đi làm'}</b>?
                        </span>
                    )}
                </div>
            </Dialog>
        </div>
    );
}

SubmitWorkingDayConfigDialog.propTypes = {
    adData: PropTypes.object,
};
SubmitWorkingDayConfigDialog.defaultProps = {
    adData: {
        name: '',
    },
};
export default SubmitWorkingDayConfigDialog;
