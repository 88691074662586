import { authenticationService } from 'services/AuthenticationService';
import { unsetAuthData } from 'app/actions';
import store from 'app/store';
import keycloak from 'configs/keycloakConfig';
import './error.scss';

const Page403Error = () => {
    const logout = async () => {
        authenticationService.logout();
        store.dispatch(unsetAuthData());
        keycloak.logout();
    };

    return (
        <div
            className="page-error page-error__403"
            style={{ backgroundColor: 'white', color: '#000' }}
        >
            <div className="pg-white p-text-center">
                <img
                    src="https://th.bing.com/th/id/OIP.EacohpEN9sJOQLxXSFPDPgHaBa?pid=ImgDet&rs=1"
                    alt="freya-layout"
                    style={{ marginBottom: '12px' }}
                />
                <div className="page-error__header">403</div>
                <div className="page-error__body">
                    Tài khoản của bạn chưa được cấp quyền sử dụng phần mềm CyberJoom. Vui lòng liên
                    hệ quản trị viên tổ chức của bạn.
                </div>
                <button onClick={() => logout()} className="button-9">
                    Đăng xuất
                </button>
            </div>
        </div>
    );
};
export default Page403Error;
