import { useApp } from 'context/AppContext';
import notification from 'helpers/constant/notification';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tree } from 'primereact/tree';
import Service from 'services/RoleServices';
import titleDefault from 'helpers/constant/titleDefault';

export const DialogRole = (props) => {
    const { showAlert } = useApp();
    const { permission } = props;
    const service = new Service();
    const emptyRole = {
        name: "",
        display_name: "",
        description: "",
        permission_id: "",
    }
    const [data, setData] = useState(emptyRole);
    const [dataError, setDataError] = useState();
    const [selectedKeys3, setSelectedKeys3] = useState([]);
    const handleChangeInput = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }
    const getDataEdit = async () => {
        if (props.dataEdit) {
            const listPermission = [];
            const test = {};
            await props.dataEdit.role_permission.forEach(answer => {
                listPermission.push(answer.permission_id);
                let demo = null;
                demo = answer.permission_id;
                test[demo] = { checked: true, partialChecked: false };
            })
            permission.forEach((index, value) => {
                let isAllChecked = 0;
                let isAllNotChecked = 0;
                index.children.forEach((item, key) => {
                    if (!listPermission.includes(item.key)) {
                        isAllChecked = 1;
                    }
                    if (listPermission.includes(item.key)) {
                        isAllNotChecked = 1;
                    }
                })
                if (isAllChecked === 0) {
                    test[index.key] = { checked: true, partialChecked: false };
                } else if (isAllChecked === 1 && isAllNotChecked === 1) {
                    test[index.key] = { checked: false, partialChecked: true };
                } else {
                    test[index.key] = { checked: false, partialChecked: false };
                }
            })
            setSelectedKeys3(test);
            await setData({
                id: props.dataEdit.id,
                name: props.dataEdit.name,
                display_name: props.dataEdit.display_name,
                description: props.dataEdit.description,
                permission_id: listPermission,
            })
            return;
        }
        setData(emptyRole);
        setSelectedKeys3(null);
        setDataError(null);
    }
    const onCreateClick = async () => {
        const result = await service.onCreateRole(data);
        try {
            const { status, message, error } = result;
            if (status === 2) {
                if (error) {
                    setDataError(error)
                    showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                } else {
                    showAlert('error', notification.CREATE_FAIL, message);
                }
                return;
            }
            if (status === 1) {
                showAlert('success', notification.CREATE_SUCCESS, message);
                clearData();
                props.onHideDialog();
                await props.feData();
            }
        } catch (error) {
            showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_ERROR);
        }
    }


    const onEditClick = async () => {
        const result = await service.onUpdateRole(data);
        try {
            const { status, message, error } = result;
            if (status === 2) {
                if (error){
                    setDataError(error)
                    showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_VALIDATE);
                }else{
                    showAlert('error', notification.CREATE_FAIL, message);
                }
                return;
            }
            if (status === 1) {
                showAlert('success', notification.UPDATE_SUCCESS, message);
                clearData();
                props.onHideDialog();
                await props.feData();
            }
        } catch (error) {
            showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
        }

    }

    const onSelected = async (e) => {
        const list_permission = [];
        Object.keys(e.value).map((key) => {
            if (!key.includes("k")) {
                list_permission.push(key)
            }
            return list_permission;
        });
        await setSelectedKeys3(e.value);
        await setData({ ...data, permission_id: list_permission })
    }

    const clearData = () => {
        setData(emptyRole)
        setSelectedKeys3(null);
    }

    const onHide = () => {
        setDataError(null);
        props.onHideDialog();
        clearData();
    }

    const productDialogFooter = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised" onClick={onHide} />
            <Button label="Lưu" icon="pi pi-check" className="p-button-raised" onClick={props.typeAd===1 ? onCreateClick : onEditClick} />
        </React.Fragment>
    );
    useEffect(() => {
        getDataEdit();
        // eslint-disable-next-line
    }, [props.dataEdit]);

    return (
        <div className="role-css">
            <Dialog visible={props.visible} 
            header={props.typeAd ===1 ? titleDefault.ROLE_CREATE : titleDefault.ROLE_UPDATE}
             modal footer={productDialogFooter} onHide={onHide}
                className="p-fluid p-dialog-default w--95 wr-md-40 p-dialog-xl wr-xl-50"
            >
                <div className="p-field">
                    <label htmlFor="name" className="label-bold">Tên vai trò <font className="text-danger">*</font></label>
                    <InputText id="name" value={data.name} onChange={handleChangeInput} autoFocus name="name" placeholder="Nhập tên vai trò" />
                    {dataError?.name ? <span className="p-invalid">{dataError.name}</span> : ""}
                </div>
                <div className="p-field">
                    <label htmlFor="display_name" className="label-bold">Tên hiển thị <font className="text-danger">*</font></label>
                    <InputText id="display_name" value={data.display_name} onChange={handleChangeInput} name="display_name" placeholder="Nhập tên hiển thị" />
                    {dataError?.display_name ? <span className="p-invalid">{dataError.display_name}</span> : ""}
                </div>
                <div className="p-field">
                    <label htmlFor="description" className="label-bold">Mô tả </label>
                    <InputTextarea id="description" value={data.description||""} onChange={handleChangeInput} name="description" placeholder="Nhập mô tả" />
                    {dataError?.description ? <span className="p-invalid">{dataError.description}</span> : ""}
                </div>
                <div className="p-field">
                    <label htmlFor="permission" className="label-bold">Quyền hạn <font className="text-danger">*</font></label>
                    <Tree id="checkTree" value={permission} selectionMode="checkbox" selectionKeys={selectedKeys3} onSelectionChange={onSelected} />
                    {dataError?.permission_id ? <span className="p-invalid">{dataError.permission_id}</span> : ""}
                </div>
            </Dialog>
        </div>
    )
}

DialogRole.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHideDialog: PropTypes.func.isRequired,
    dataEdit: PropTypes.object,
    feData: PropTypes.func.isRequired,
}
