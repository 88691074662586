import AppFooter from './AppFooter';
import AppRouter from 'routers/AppRouter';
import AppTopbar from './AppTopbar';
import { useSelector } from 'react-redux';

const MainPage = (props) => {
    const userPer = useSelector((state) => state.authReducer.userData);

    return (
        <>
            {userPer !== null ? (
                <div className="layout-wrapper">
                    <AppTopbar />
                    <div className="layout-content">
                        <AppRouter />
                        <AppFooter />
                    </div>
                </div>
            ) : (
                <div style={{ backgroundColor: '#efefef' }} />
            )}
        </>
    );
};

export default MainPage;
