import { Dialog } from 'primereact/dialog';
import { Rating } from "primereact/rating";
import { useApp } from "context/AppContext";
import { useState } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import EvaluateServices from "services/EvaluateServices";
import notification from "helpers/constant/notification";


        
const FormEvaluate = props => {
    const { showAlert } = useApp();
    const serviceEvaluate = new EvaluateServices();
    const {visibleEvaluate, onHideFormEvaluate, bookListId, hostName, hostEmail, roomName, userCreate, timeDate, timeStart, timeEnd } = props;
    const [stars, setStars] = useState(0);
    const [selectedValues, setSelectedValues] = useState([]);

    const onCreateClick = async () => {
        const evaluateToCreate = {
            contents: selectedValues.length === 0 ? "" : selectedValues.join(', '),
            stars,
            book_list_id: bookListId,
            host_name: hostName,
            host_email: hostEmail,
            room_name: roomName,
            user_create: userCreate,
            time_date: timeDate,
            time_start: timeStart,
            time_end: timeEnd,

        };
        const result = await serviceEvaluate.createEvaluate(evaluateToCreate);
        try {
            const { error, message, status } = result;
            if (status === 2) {
                if (error) {
                    showAlert("error", notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                } else {
                    showAlert("error", notification.CREATE_FAIL, message);
                }
                return;
            }
            if (status === 1) {
                showAlert("success", notification.CREATE_SUCCESS, message);
                // onHideFormEvaluate();
                window.location.reload();
            }
        } catch (error) {
            showAlert("error", notification.CREATE_FAIL, notification.MESSAGE_ERROR);
        }
    }

    const evaluateAvailable = (e) => {
        const selectedValue = e.target.textContent;
        const isSelected = selectedValues.includes(selectedValue);

        if (isSelected) {
            setSelectedValues(prevSelectedValues => prevSelectedValues.filter(value => value !== selectedValue));
            // e.target.style.backgroundColor = ''; 
        } else {
            setSelectedValues(prevSelectedValues => [...prevSelectedValues, selectedValue]);
            // e.target.style.backgroundColor = '#f1eaea';
        }
    };

    const isButtonDisabled = stars === 0;
    return (
        <Dialog visible={visibleEvaluate}
            header="Đánh giá cuộc họp"
            modal className="p-fluid"
            onHide={onHideFormEvaluate}
            style={{ backgroundColor: '#DFEDF8' }}
        >
            <h3 className='p-m-0 text-center'>Bạn thấy cuộc họp này thế nào</h3>
            <Rating 
                value={stars} 
                className='text-center p-m-3'
                onChange={(e) => setStars(e.value)} 
                cancel={false} 
            />
            <div 
                className="rating-description p-d-flex p-justify-center"
                style={{ gap: '20px', transform: "translateY(-10px)"}}
            >
                <p style={{ transform: "translateX(4px)"}}>Rất tệ</p>
                <p style={{ transform: "translateX(7px)"}}>Tệ</p>
                <p style={{ transform: "translateX(7px)"}}>Tạm ổn</p>
                <p style={{ transform: "translateX(1px)"}}>Tốt</p>
                <p>Rất tốt</p>
            </div>
            <div className='p-pb-3'>
                <InputTextarea autoResize value={selectedValues.join(' ')} 
                onChange={(e) => 
                    setSelectedValues([e.target.value])
                } rows={5} cols={30} 
                style={{ borderRadius: '5px', paddingLeft: '10px'}}
                className='box-shadow p-mb-3'
                placeholder='Nhập đánh giá...'
                />
            </div>
            <div className='row'>
                <div className='col-md-4 col-sm-6 p-mb-3'>
                    <p 
                        className='text-center box-shadow pointer p-m-0'
                        onClick={evaluateAvailable}
                    >
                        &#128513; Phòng gọn gàng sạch sẽ
                    </p> 
                </div>
                <div className='col-md-4 col-sm-6 p-mb-3'>
                    <p 
                        className='text-center box-shadow pointer p-m-0'
                        onClick={evaluateAvailable}
                    >
                        &#128525; Gọn gàng ngăn nắp
                    </p> 
                </div>
                <div className='col-md-4 col-sm-6 p-mb-3'>
                    <p 
                        className='text-center box-shadow pointer p-m-0'
                        onClick={evaluateAvailable}
                    >
                        &#128516; Bảng viết sạch
                    </p> 
                </div>
                <div className='col-md-4 col-sm-6 p-mb-3'>
                    <p 
                        className='text-center box-shadow pointer p-m-0'
                        onClick={evaluateAvailable}
                    >   
                        &#128534; Phòng họp bẩn
                    </p> 
                </div>
                <div className='col-md-4 col-sm-6 p-mb-3'>
                    <p 
                        className='text-center box-shadow pointer p-m-0'
                        onClick={evaluateAvailable}
                    >
                        &#128548; Bừa bộn, lộn xộn
                    </p> 
                </div>
                <div className='col-md-4 col-sm-6 p-mb-3'>
                    <p 
                        className='text-center box-shadow pointer p-m-0'
                        onClick={evaluateAvailable}
                    >
                        &#128560; Không tắt điều hòa
                    </p> 
                </div>
            </div>
            <Button 
                className="p-mt-3 p-mb-2 btn-send-evaluate"
                label="Gửi đánh giá" 
                style={{ backgroundColor: "#4b91e7"}}
                onClick={onCreateClick}
                disabled={isButtonDisabled}
            />
        </Dialog>
    );
}

export default FormEvaluate;