import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { PanelMenu } from 'primereact/panelmenu';
import { Sidebar } from 'primereact/sidebar';
import Logo from '../../assets/images/cyber-joom-logo2.png';
import { Link } from 'react-router-dom';

export const MenubarDemo = (props) => {
    const { menu, visibleRight, setVisibleRight } = props;
    const start = (
        <Link to="/">
            <img
                alt="logo"
                src={Logo}
                className="p-mr-2 logo-page pointer"
                onClick={() => setVisibleRight(false)}
                width="238"
                height="30"
            />
        </Link>
    );
    return (
        <div>
            <div className="layout-topbar">
                <div className="content-section implementation menu-customer">
                    <div className="card">
                        <Menubar model={menu} start={start} />
                    </div>
                </div>
                <div className="MenubarDemoMobile w--100">
                    <div className="p-d-flex ">
                        <div>{start}</div>
                        <div className="p-ml-auto">
                            <Button
                                icon="pi pi-bars"
                                onClick={() => setVisibleRight(true)}
                                className="p-button-text button-menu p-button-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="MenubarDemoMobile">
                <Sidebar
                    visible={visibleRight}
                    position="right"
                    baseZIndex={1_000_000}
                    onHide={() => setVisibleRight(false)}
                >
                    {start}
                    <PanelMenu model={menu} className="p-mt-4" />
                </Sidebar>
            </div>
        </div>
    );
};
