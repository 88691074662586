import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";

class UserService extends FetchAPI {
    //Lấy danh sách khách hàng
    public async getAll(data) {
        const url = `tai-khoan/getAllUser`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async getAllByType(data) {
        const url = `tai-khoan/getAllUserByType`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async getListUser(data) {
        const url = `tai-khoan/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async onCreateUser(data) {
        const url = `tai-khoan/create`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async onEditUser(data) {
        const url = `tai-khoan/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async onDeleteUser(data) {
        const url = `tai-khoan/delete`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async onResetPassword(data) {
        const url = `tai-khoan/reset-password`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async onSwitchStatusUser(data) {
        const url = `tai-khoan/switch-status-user`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async onDeleteMultiple(data) {
        const url = `tai-khoan/deleteMultiple`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async getListRole() {
        const url = `vai-tro/getAllRole`;
        return await this.request(url, {}, TypeMethodConstantEnum.GET);
    }

    public async getAllGroup() {
        const url = `nhom/index`;
        return await this.request(url, {}, TypeMethodConstantEnum.GET);
    }

    public async onChangePassword(data) {
        const url = `tai-khoan/change-password`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async getUserInfo(data = {}) {
        const url = `tai-khoan/getUserInfo`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async switchAgencyActive(data) {
        const url = `tai-khoan/switchAgencyActive?active_agency_id=${data}`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async updateCurrentUser(data) {
        const url = `tai-khoan/updateCurrentUser`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async getLogHistoryUser(data) {
        const url = `lich-su/current?page=${data}`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async logout() {
        const url = `tai-khoan/logout`;
        return await this.request(url, {}, TypeMethodConstantEnum.GET);
    }

    public async getMeetingParticipantOutside(data) {
        const url = `tai-khoan/suggest-meeting-participants-outside`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async importUser(data) {
        const url = `tai-khoan/import-excel`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async checkProcessImport(data) {
        const url = `tai-khoan/check-process-import`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async getImportUsers(data) {
        const url = `tai-khoan/get-import-users`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async asyncUsersOdoo() {
        const url = `tai-khoan/asyn-users-odoo`;
        return await this.request(url, {}, TypeMethodConstantEnum.POST);
    }
}
export default UserService;
