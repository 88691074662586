import React from 'react'
import PropTypes from 'prop-types'

const ViewImage = props => {
    const { dataImg, altImg, className } = props;
    return (
        <div className="ViewImage">
            <img id="image-preview" src={dataImg} alt={altImg} className={`img-preview ${className}`}
            // width="800" height="500"
            loading="lazy"
             />
        </div>
    )
}

ViewImage.propTypes = {
    dataImg:PropTypes.string,
    altImg:PropTypes.string,
    className:PropTypes.string,
}

ViewImage.defaultProps = {
    dataImg: "",
    altImg: "img-preview",
}

export default ViewImage
