import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MenubarDemo } from 'components/menubar/MenubarDemo';
import { useApp } from 'context/AppContext';
import { authenticationService } from 'services/AuthenticationService';
import store from 'app/store';
import { unsetAuthData } from 'app/actions';
import keycloak from 'configs/keycloakConfig';
import { useHistory } from 'react-router-dom';
import DialogAgency from './switch-agency/DialogAgency';
import './app-top-bar.scss';

const AppTopbar = () => {
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [visibleRight, setVisibleRight] = useState(false);
    const userPer = useSelector((state) => state.authReducer.userData);
    const { setStateSchedule } = useApp();

    const onHideDialog = () => {
        setVisible(false);
    };

    const MENU_START = [
        {
            label: 'Quản lý đặt phòng',
            icon: 'pi pi-fw pi-calendar-plus',
            items: [
                {
                    label: 'Đặt lịch',
                    icon: 'pi pi-fw pi-calendar-minus',
                    permission: 'schedule-list-personal',
                    command: () => {
                        setStateSchedule(true);
                        setVisibleRight(false);
                        history.push('/lich-dat-cua-toi/tao-moi');
                    },
                },
                {
                    label: 'Lịch đặt chung',
                    icon: 'pi pi-fw pi-calendar',
                    permission: 'schedule-list',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/lich-dat');
                    },
                },
                {
                    label: 'Lịch đặt của tôi',
                    icon: 'pi pi-fw pi-bookmark',
                    permission: 'schedule-list-personal',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/lich-dat-cua-toi');
                    },
                },
                {
                    label: 'Quản lý nhóm',
                    icon: 'pi pi-fw pi-users',
                    permission: 'group-list',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/nhom');
                    },
                },
            ],
        },
        {
            label: 'Quản lý danh mục',
            icon: 'pi pi-fw pi-folder',
            items: [
                {
                    label: 'Quản lý đánh giá',
                    icon: 'pi pi-fw pi-star',
                    permission: 'evaluate-group-list',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/danh-gia');
                    },
                },
                {
                    label: 'Quản lý địa điểm',
                    icon: 'pi pi-fw pi-directions',
                    permission: 'place-list',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/dia-diem');
                    },
                },
                {
                    label: 'Quản lý thiết bị',
                    icon: 'pi pi-fw pi-desktop',
                    permission: 'device-list',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/thiet-bi');
                    },
                },
                {
                    label: 'Quản lý phòng họp',
                    icon: 'pi pi-fw pi-home',
                    permission: 'meetingroom-list',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/phong-hop');
                    },
                },
                {
                    label: 'Quản lý mẫu mail',
                    icon: 'pi pi-fw pi-envelope',
                    permission: 'mail-list',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/mau-mail');
                    },
                },
            ],
        },
        {
            label: 'Hệ thống',
            icon: 'pi pi-fw pi-th-large',
            items: [
                {
                    label: 'Quản lý người dùng',
                    icon: 'pi pi-fw pi-user',
                    permission: 'user-list',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/nguoi-dung');
                    },
                },
                {
                    label: 'Quản lý vai trò',
                    icon: 'pi pi-fw pi-users',
                    permission: 'role-list',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/vai-tro');
                    },
                },
                {
                    label: 'Thiết lập chung',
                    icon: 'pi pi-fw pi-cog',
                    permission: 'config-view',
                    command: () => {
                        setVisibleRight(false);
                        history.push('/cau-hinh');
                    },
                },
            ],
        },
    ];
    const MENU_END = {
        label: 'Người dùng',
        icon: 'pi pi-fw pi-user',
        className: 'p-ml-auto infomation-user',
        items: [
             {
                label: userPer ? userPer.activeNameAgency : 'Doanh nghiệp',
                icon: 'pi pi-fw pi-briefcase',  
                command: () => {
                    setVisibleRight(false);
                    setVisible(true);
                },
                items:[
                    {
                        label:'',
                    },
                ]
            },
            {
                label: 'Quản lý tài khoản',
                icon: 'pi pi-fw pi-user-edit',
                command: () => {
                    setVisibleRight(false);
                    history.push('/ho-so');
                },
            },
            {
                label: 'Thay đổi mật khẩu',
                icon: 'pi pi-fw pi-unlock',
                command: () => {
                    setVisibleRight(false);
                    history.push('/doi-mat-khau');
                },
            },
            {
                label: 'Đăng xuất',
                icon: 'pi pi-fw pi-power-off',
                command: () => {
                    logout();
                },
            },
        ],
    };
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        if (userPer) {
            let menuUpdate = [];
            MENU_START.map((cate) => {
                if (userPer) {
                    cate.items = cate.items.filter((item) => {
                        return userPer.permission.includes(item.permission);
                    });
                    if (cate.items.length !== 0) {
                        menuUpdate.push(cate);
                    }
                }
                return menuUpdate;
            });
            const menuEnd = { ...MENU_END, label: userPer.data.fullname };
            menuUpdate.push(menuEnd);
            setMenu(menuUpdate);
        }
        // eslint-disable-next-line
    }, [userPer]);

    const logout = async () => {
        authenticationService.logout();
        store.dispatch(unsetAuthData());
        keycloak.logout();
    };

    return (
        <React.Fragment>
            <div className="topbar">
                <MenubarDemo
                    menu={menu}
                    visibleRight={visibleRight}
                    setVisibleRight={setVisibleRight}
                />
                <DialogAgency 
                    visible={visible} 
                    onHideDialog={onHideDialog}
                />
            </div>
            
        </React.Fragment>
    );
};

export default AppTopbar;
