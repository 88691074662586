import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";
class RoomMeetingServices extends FetchAPI {
    public async getAll(data) {
        const url = `phong-hop/getAllMeetingRoom`;
        return await this.request(url, { data }, TypeMethodConstantEnum.GET);
    }
    public async getFilter(data) {
        const url = `phong-hop/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async get(data) {
        const url = `/phong-hop/getMeetingRoomById/`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async create(data) {
        const url = `phong-hop/create`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async update(data) {
        const url = `phong-hop/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async delete(data) {
        const url = `phong-hop/delete`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async deleteMultiple(data) {
        const url = `phong-hop/deleteMultiple`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async lock(data) {
        const url = `phong-hop/lock`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async unLock(data) {
        const url = `phong-hop/unLock`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async importRoomMeeting(data) {
        const url = `phong-hop/import-excel`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async exportRoomMeetingSample(data) {
        const url = `phong-hop/export-excel`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

}
export default RoomMeetingServices;
