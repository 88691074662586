import React, { useEffect, useState, useRef } from 'react';
import MyBookServices from 'services/MyBookServices';
import UserServices from 'services/UserServices';
import InputUploadFile from 'components/upload-file/InputUploadFile';
import notification from 'helpers/constant/notification';
import titleDefault from 'helpers/constant/titleDefault';
import typeRepeat from 'helpers/constant/typeRepeat';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AiOutlineSearch } from 'react-icons/ai';
import './dialog-schedule.scss';

export const DialogSchedule = (props) => {
    //Variable
    const {
        time,
        visible,
        onHideDialog,
        typeAd,
        adData,
        setAdData,
        errData,
        setErrData,
        listMeeting,
        showAlert,
        fetData,
        edData,
        typeUpdate,
        hideRepeat,
        dataConfig,
        typeShow,
        files,
        showGroupUserModal,
        setShowGroupUserModal,
        users,
        userSelected,
        setUserSelected,
        groups,
    } = props;
    const serviceBook = new MyBookServices();
    const serviceUser = new UserServices();
    const refFileProcedure = useRef(null);
    const acceptFileString = '.pdf,.jpg,.png,.jpeg,.doc,.docx,.xls,.xlsx,.ppt,.pptx';
    const convertDataDropdownGroups = (groups) => {
        return [
            { code: null, name: 'Chọn nhóm người' }, // Mục đầu tiên
            ...groups.map((group) => {
                return { code: group.uuid, name: group.name };
            }),
        ];
    };
    const dataDropdownGroups = convertDataDropdownGroups(groups);
    const usersOptionMultiSelect = users.map((user) => {
        return {
            code: user.id,
            name: user.email,
        };
    });
    const usersSelectedOptionMultiSelect = userSelected.map((user) => {
        return {
            code: user.id,
            name: user.email,
        };
    });
    const inputRef = useRef();

    //State
    const [dialogDeleteS, setDialogDeleteS] = useState(false);
    const [dialogUpdateS, setDialogUpdateS] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchGroup, setSearchGroup] = useState({});
    const [userFilterTable, setUserFilterTable] = useState(users);
    const [userSelectCurrent, setUserSelectCurrent] = useState([]);
    const [optionsParticipantOutside, setOptionsParticipantOutside] = useState([]);
    const [searchKeyworkChip, setSearchKeyworkChip] = useState('');

    useEffect(() => {
        fetchDataParticipantOutside();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let updatedUsers = users.map((user) => ({ ...user, searchValid: true, groupValid: true }));

        if (searchKeyword.trim() !== '') {
            updatedUsers = updatedUsers.map((user) => {
                const fullname = user?.fullname ?? '';
                const email = user?.email ?? '';
                const isValid = fullname?.includes(searchKeyword) || email?.includes(searchKeyword);
                return { ...user, searchValid: isValid };
            });
        }

        if (searchGroup?.code) {
            updatedUsers = updatedUsers.map((user) => {
                const groupValid = user.groups.some((group) => group.uuid === searchGroup.code);
                return { ...user, groupValid };
            });
        }

        updatedUsers = updatedUsers.filter((user) => user.searchValid && user.groupValid);

        setUserFilterTable(updatedUsers);
    }, [searchKeyword, searchGroup, users]);

    useEffect(() => {
        setSearchKeyword('');
        setSearchGroup({});
        setUserFilterTable(users);
        setUserSelectCurrent([]);
        // eslint-disable-next-line
    }, [typeAd]);

    //Handle
    useEffect(() => {
        getEditData();
        // eslint-disable-next-line
    }, [edData]);

    useEffect(() => {
        if (!userSelectCurrent.length > 0) {
            const resultArray = userSelected.filter((item1) =>
                userSelectCurrent.some((item2) => item2.id === item1.id)
            );
            setUserSelected(resultArray);
        }
        const usersToAdd = userSelectCurrent.filter(
            (currentUser) =>
                !userSelected.some((selectedUser) => selectedUser.id === currentUser.id)
        );

        if (usersToAdd.length > 0) {
            setUserSelected((prevSelected) => [...prevSelected, ...usersToAdd]);
        }
        // eslint-disable-next-line
    }, [userSelectCurrent]);

    useEffect(() => {
        const containsAll = userFilterTable.every((subItem) =>
            userSelected.some((item) => item.id === subItem.id)
        );
        if (containsAll) {
            setUserSelectCurrent(userFilterTable);
        } else {
            setUserSelectCurrent(userSelected);
        }
        // eslint-disable-next-line
    }, [userFilterTable]);

    useEffect(() => {
        setUserFilterTable(users);
    }, [users]);

    useEffect(() => {
        setUserSelected([]);
        setUserFilterTable(users);
        setUserSelectCurrent([]);
        // eslint-disable-next-line
    }, [visible]);

    const updateField = (key, field) => {
        setAdData({
            ...adData,
            [field]: key,
        });
    };

    const onUploadFile = (file_upload) => {
        const file_attach = [];
        file_upload.map((e) => {
            file_attach.push(e.nameUp);
            return e;
        });
        setAdData({
            ...adData,
            file_attach,
        });
    };

    const getEditData = () => {
        const objectEdit = { ...edData };
        if (!objectEdit) {
            return;
        }
        const listUserRoom = objectEdit.user_list.length > 0 ? JSON.parse(objectEdit.user_list) : [];

        const usersOption = users.filter((item2) => listUserRoom.includes(item2.id));
        objectEdit.user_list = usersOption;

        const host = users.find((user) => user.id === objectEdit.host_id);
        objectEdit.host_id = { code: host?.id, name: host?.email };

        setAdData(objectEdit);
        setUserSelectCurrent(usersOption);
    };

    const saveData = async (e) => {
        let newAdData = { ...adData };
        // thông tin phòng
        if (adData.room_id) {
            newAdData = { ...newAdData, room_id: adData.room_id.code };
        }
        // thời gian bắt đầu
        if (adData.start_time) {
            newAdData = { ...newAdData, start_time: adData.start_time.code };
        }
        // thời gian kết thúc
        if (adData.end_time) {
            newAdData = { ...newAdData, end_time: adData.end_time.code };
        }
        // loại lặp
        if (adData.type_repeat) {
            newAdData = { ...newAdData, type_repeat: adData.type_repeat.code };
        }
        // ngày kết thúc lặp
        if (adData.end_date_repeat) {
            const newEndate = moment(adData.end_date_repeat).format('YYYY-MM-DD');
            newAdData = { ...newAdData, end_date_repeat: newEndate };
        }
        // ngày đặt lịch
        if (adData.date) {
            const newDate = moment(adData.date).format('YYYY-MM-DD');
            newAdData = { ...newAdData, date: newDate };
        }
        // danh sách đặt lịch
        if (adData.user_list) {
            // let listUser = adData.user_list.map((r) => r.code);
            const userSelectedIds = userSelected.map((user) => user.id);
            newAdData = { ...newAdData, user_list: userSelectedIds };
        }

        if (adData.host_id) {
            newAdData = { ...newAdData, host_id: adData.host_id.code };
        }

        console.log("data",newAdData);

        if (typeAd === 1) {
            try {
                const result = await serviceBook.create(newAdData);
                const { error, message } = result;
                if (result && result.status === 2) {
                    if (error) {
                        showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                        setErrData(error);
                    } else {
                        showAlert('error', notification.CREATE_FAIL, message);
                    }
                } else {
                    showAlert('success', notification.CREATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                    setUserSelected([]);
                    setUserFilterTable([]);
                    setUserSelectCurrent([]);
                    fetchDataParticipantOutside();
                }
            } catch (error) {
                showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_ERROR);
            }
        } else {
            newAdData = { ...newAdData, type_update: e };
            try {
                const result = await serviceBook.update(newAdData);
                const { error, message } = result;
                if (result && result.status === 2) {
                    if (error) {
                        showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_VALIDATE);
                        onHideDialogUpdateS();
                        setErrData(error);
                    } else {
                        showAlert('error', notification.UPDATE_FAIL, message);
                    }
                } else {
                    showAlert('success', notification.UPDATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                    onHideDialogUpdateS();
                    setUserSelected([]);
                    setUserFilterTable([]);
                    setUserSelectCurrent([]);
                }
            } catch (error) {
                showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
            }
        }
    };

    const deleteData = async (e) => {
        const newData = { ...adData, type_update: e };
        try {
            const response = await serviceBook.delete(newData);
            const { status, message } = response;
            onHideDialog();
            onHideDialogDeleteS();
            if (status === 2) {
                showAlert('error', notification.DELETE_FAIL, message);
            } else {
                showAlert('success', notification.DELETE_SUCCESS, message);
                fetData();
            }
        } catch (error) {
            onHideDialog();
            onHideDialogDeleteS();
            showAlert('error', notification.DELETE_FAIL, notification.MESSAGE_ERROR);
        }
    };

    const fetchDataParticipantOutside = async () => {
        const res = await serviceUser.getMeetingParticipantOutside();
        if (res?.status === 1) {
            setOptionsParticipantOutside(res?.data);
        }
    };

    const changeStartTime = (e) => {
        try {
            let minutesAdd = 30;
            if (dataConfig.default_time_meeting === 1) {
                minutesAdd = 30;
            } else if (dataConfig.default_time_meeting === 2) {
                minutesAdd = 60;
            } else if (dataConfig.default_time_meeting === 3) {
                minutesAdd = 90;
            } else if (dataConfig.default_time_meeting === 4) {
                minutesAdd = 120;
            }
            const endTime = moment(e.target.value.code, 'hh:mm:ss')
                .add(minutesAdd, 'minutes')
                .format('HH:mm');
            const end_time = { code: endTime, name: endTime };

            setAdData({
                ...adData,
                end_time,
                start_time: e.target.value,
            });
        } catch (e) {
            setAdData({
                ...adData,
                start_time: '',
            });
        }
    };

    const onShowDeleteDialogS = () => {
        setDialogDeleteS(true);
    };

    const onShowUpdateDialogS = () => {
        setDialogUpdateS(true);
    };

    const onHideDialogDeleteS = () => {
        setDialogDeleteS(false);
    };

    const onHideDialogUpdateS = () => {
        setDialogUpdateS(false);
    };

    const dialogFooterAdEdit = (
        <React.Fragment>
            <div className="p-d-flex">
                <div className="">
                    {typeAd !== 1 && (
                        <Button
                            label="Xóa"
                            icon="pi pi-trash"
                            className="p-button-raised p-button-danger"
                            onClick={onShowDeleteDialogS}
                        />
                    )}
                </div>
                <div className="p-ml-auto">
                    <Button
                        label="Hủy"
                        icon="pi pi-times"
                        className="p-button-text p-button-raised"
                        onClick={onHideDialog}
                    />
                    <Button
                        label={typeAd === 1 ? 'Lưu' : 'Cập nhật'}
                        icon="pi pi-check"
                        className="p-button-raised"
                        onClick={typeAd === 1 ? saveData : onShowUpdateDialogS}
                    />
                </div>
            </div>
        </React.Fragment>
    );

    //Template
    const FooterGroupUserDialogTemplate = (
        <div className="p-d-flex">
            <div className="p-ml-auto">
                <Button
                    label="Đóng"
                    icon="pi pi-times"
                    className="p-button-text p-button-raised"
                    onClick={() => setShowGroupUserModal(false)}
                />
                {/* <Button
                    label="Thêm"
                    icon="pi pi-check"
                    className="p-button-raised"
                    onClick={() => handleChooseUserGroup()}
                /> */}
            </div>
        </div>
    );

    const CodeColumnTableTemplate = (rowData) => (
        <div className="p-d-flex">
            <div className="max-line-3" title={rowData.code}>
                {rowData.code}
            </div>
        </div>
    );

    const NameColumnTableTemplate = (rowData) => (
        <div className="p-d-flex">
            <div className="max-line-3" title={rowData.fullname}>
                {rowData.fullname}
            </div>
        </div>
    );

    const EmailColumnTableTemplate = (rowData) => (
        <div className="p-d-flex">
            <div className="max-line-3 overflow-ellipsis" title={rowData.email}>
                {rowData.email}
            </div>
        </div>
    );

    const DialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={onHideDialogDeleteS}
            />
            <Button
                label="Xóa"
                icon="pi pi-check"
                className="p-button-danger p-button-raised"
                onClick={() => deleteData(1)}
            />
            {typeUpdate !== null ? (
                <Button
                    label="Xóa nhiều "
                    icon="pi pi-check-circle"
                    className="p-button-danger p-button-raised"
                    onClick={() => deleteData(2)}
                />
            ) : (
                ''
            )}
        </React.Fragment>
    );

    const DialogUpdateFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={onHideDialogUpdateS}
            />
            <Button
                label="Cập nhật"
                icon="pi pi-check"
                className="p-button-raised"
                onClick={() => saveData(1)}
            />
            {typeUpdate !== null ? (
                <Button
                    label="Cập nhật nhiều "
                    icon="pi pi-check-circle"
                    className="p-button-raised"
                    onClick={() => saveData(2)}
                />
            ) : (
                ''
            )}
        </React.Fragment>
    );

    const sortedUserFilterTable = [...userFilterTable];

    sortedUserFilterTable.sort((a, b) => {
        const isSelectedA = userSelectCurrent.some((selectedUser) => selectedUser.id === a.id);
        const isSelectedB = userSelectCurrent.some((selectedUser) => selectedUser.id === b.id);

        if (isSelectedA && !isSelectedB) return -1;
        if (!isSelectedA && isSelectedB) return 1;

        return 0;
    });

    const handleChangeKeySearchParticipantOutside = debounce((e) => {
        setSearchKeyworkChip(e.target.value);
    }, 1000);

    return (
        <div className="dialog-css multiselect-demo">
            <Dialog
                visible={visible}
                header={typeAd === 1 ? titleDefault.SCHEDULE_CREATE : titleDefault.SCHEDULE_UPDATE}
                modal
                className="p-fluid m-0 schedule__dialog"
                footer={typeShow === 2 && dialogFooterAdEdit}
                onHide={onHideDialog}
                maximizable
            >
                <div className="row">
                    <div className="col-12 schedule-left">
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6">
                                <div className=" p-field">
                                    {/* nhập tiêu đề */}
                                    <label htmlFor="title" className="align-sub-text label-bold">
                                        Tiêu đề <span className="text-danger">*</span>
                                    </label>
                                    <InputText
                                        id="title"
                                        value={adData.title}
                                        placeholder="Tiêu đề"
                                        onChange={(e) => updateField(e.target.value, 'title')}
                                    />
                                    <span id="username2-help" className="p-invalid p-d-block">
                                        {errData.title}
                                    </span>
                                </div>
                                <div className=" p-field">
                                    {/* chọn phòng họp */}
                                    <label htmlFor="room_id" className="align-sub-text label-bold">
                                        Phòng họp <span className="text-danger">*</span>
                                    </label>
                                    <Dropdown
                                        id="room_id"
                                        value={adData.room_id}
                                        options={listMeeting}
                                        onChange={(e) => updateField(e.value, 'room_id')}
                                        optionLabel="name"
                                        filter
                                        showClear
                                        filterBy="name"
                                        placeholder="Chọn phòng họp"
                                    />
                                    <span id="username2-help" className="p-invalid p-d-block">
                                        {errData.room_id}
                                    </span>
                                </div>
                                {/* thời gian bắt đầu */}
                                <div className="p-field">
                                    <label
                                        htmlFor="start_date"
                                        className="align-sub-text label-bold"
                                    >
                                        Bắt đầu <span className="text-danger">*</span>
                                    </label>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 ">
                                            <div className="p-inputgroup">
                                                <Calendar
                                                    id="start_date"
                                                    value={adData.date}
                                                    onChange={(e) => {
                                                        updateField(e.value, 'date');
                                                    }}
                                                    placeholder="Chọn ngày bắt đầu"
                                                    dateFormat="dd/mm/yy"
                                                />
                                                <span className="p-inputgroup-addon">
                                                    <i className="pi pi-calendar" />
                                                </span>
                                            </div>
                                            <span
                                                id="username2-help"
                                                className="p-invalid p-d-block"
                                            >
                                                {errData.date}
                                            </span>
                                        </div>
                                        <div className="p-col-12 p-md-6 ">
                                            <div className="p-inputgroup">
                                                <Dropdown
                                                    id="date"
                                                    value={adData.start_time}
                                                    options={time}
                                                    onChange={(e) => changeStartTime(e)}
                                                    optionLabel="name"
                                                    filter
                                                    showClear
                                                    filterBy="name"
                                                    placeholder="Chọn giờ bắt đầu"
                                                />
                                                <span className="p-inputgroup-addon">
                                                    <i className="pi pi-clock" />
                                                </span>
                                            </div>
                                            <span
                                                id="username2-help"
                                                className="p-invalid p-d-block"
                                            >
                                                {errData.start_time}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field">
                                    {/* thời gian kết thúc */}
                                    <label
                                        htmlFor="display_name"
                                        className="align-sub-text label-bold"
                                    >
                                        Kết thúc <span className="text-danger">*</span>
                                    </label>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 ">
                                            <div className="p-inputgroup">
                                                <Calendar
                                                    id="end_date"
                                                    value={adData.date}
                                                    onChange={(e) => {
                                                        updateField(e.value, 'date');
                                                    }}
                                                    placeholder="Chọn ngày kết thúc"
                                                    dateFormat="dd/mm/yy"
                                                />
                                                <span className="p-inputgroup-addon">
                                                    <i className="pi pi-calendar" />
                                                </span>
                                            </div>
                                            <span
                                                id="username2-help"
                                                className="p-invalid p-d-block"
                                            >
                                                {errData.date}
                                            </span>
                                        </div>
                                        <div className="p-col-12 p-md-6 ">
                                            <div className="p-inputgroup">
                                                <Dropdown
                                                    id="end_time"
                                                    value={adData.end_time}
                                                    options={time}
                                                    onChange={(e) =>
                                                        updateField(e.target.value, 'end_time')
                                                    }
                                                    optionLabel="name"
                                                    filter
                                                    showClear
                                                    filterBy="name"
                                                    placeholder="Chọn giờ kết thúc"
                                                />
                                                <span className="p-inputgroup-addon">
                                                    <i className="pi pi-clock" />
                                                </span>
                                            </div>
                                            <span
                                                id="username2-help"
                                                className="p-invalid p-d-block"
                                            >
                                                {errData.end_time}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    {/* Lặp lại lịch đặt */}
                                    {hideRepeat === 1 && (
                                        <>
                                            <label htmlFor="" className="" />
                                            <Checkbox
                                                inputId="repeat"
                                                checked={adData.repeat}
                                                onChange={(e) => updateField(e.checked, 'repeat')}
                                            />
                                            <label
                                                htmlFor="repeat"
                                                className="align-sub-text p-pl-2 label-bold"
                                            >
                                                Đặt lịch định kỳ
                                            </label>
                                            <span
                                                id="username2-help"
                                                className="p-invalid p-d-block"
                                            >
                                                {errData.repeat}
                                            </span>
                                            {!adData.repeat && <div className="p-mb-3" />}
                                            <div
                                                className={`p-grid p-pt-2 ${
                                                    adData.repeat ? 'on-show' : 'on-hide'
                                                }`}
                                            >
                                                <div className="p-col-12 p-md-6 p-field">
                                                    <label
                                                        className="align-sub-text label-bold"
                                                        htmlFor="type_repeat "
                                                    >
                                                        Lặp lại:{' '}
                                                    </label>
                                                    <Dropdown
                                                        id="type_repeat"
                                                        name="type_repeat"
                                                        value={adData.type_repeat}
                                                        placeholder="Chọn kiểu lặp"
                                                        options={typeRepeat}
                                                        onChange={(e) =>
                                                            updateField(
                                                                e.target.value,
                                                                'type_repeat'
                                                            )
                                                        }
                                                        optionLabel="name"
                                                        filter
                                                        showClear
                                                        filterBy="type_repeat"
                                                        tooltipOptions={{ position: 'top' }}
                                                    />
                                                    <span
                                                        id="username2-help"
                                                        className="p-invalid p-d-block"
                                                    >
                                                        {errData.type_repeat}
                                                    </span>
                                                </div>
                                                <div className="p-col-12 p-md-6 p-field">
                                                    <label
                                                        className="align-sub-text label-bold"
                                                        htmlFor="end_date_repeat "
                                                    >
                                                        Cho đến:{' '}
                                                    </label>
                                                    <div className="p-inputgroup">
                                                        <Calendar
                                                            id="end_date_repeat"
                                                            value={adData.end_date_repeat}
                                                            onChange={(e) => {
                                                                updateField(
                                                                    e.value,
                                                                    'end_date_repeat'
                                                                );
                                                            }}
                                                            placeholder="Chọn ngày kết thúc"
                                                        />
                                                        <span className="p-inputgroup-addon">
                                                            <i className="pi pi-calendar" />
                                                        </span>
                                                    </div>
                                                    <span
                                                        id="username2-help"
                                                        className="p-invalid p-d-block"
                                                    >
                                                        {errData.end_date_repeat}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className=" p-field">
                                    {/* mô tả phòng họp */}
                                    <label
                                        htmlFor="display_name"
                                        className="align-sub-text label-bold"
                                    >
                                        Nội dung cuộc họp
                                    </label>
                                    <InputTextarea
                                        id="description"
                                        value={adData.description || ''}
                                        placeholder="Nội dung cuộc họp"
                                        onChange={(e) => updateField(e.target.value, 'description')}
                                        rows={5}
                                        cols={30}
                                    />
                                    <span id="username2-help" className="p-invalid p-d-block">
                                        {errData.description}
                                    </span>
                                </div>
                            </div>
                            <div className="p-col-12 p-lg-6" id="schedule-users">
                                <div className=" p-field">
                                    <label htmlFor="user_id" className="align-sub-text label-bold">
                                        Người chủ trì cuộc họp
                                    </label>
                                    <Dropdown
                                        value={adData.host_id}
                                        options={usersOptionMultiSelect}
                                        onChange={(e) => updateField(e.value, 'host_id')}
                                        optionLabel="name"
                                        showClear
                                        placeholder="Chọn người chủ trì cuộc họp"
                                        filter
                                        filterBy="name"
                                    />
                                </div>
                                <div
                                    className=" p-field"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setShowGroupUserModal(true)}
                                >
                                    {/* Người tham gia cuộc họp */}
                                    <label htmlFor="user_id" className="align-sub-text label-bold">
                                        Người tham gia cuộc họp{' '}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <MultiSelect
                                        value={usersSelectedOptionMultiSelect}
                                        options={usersOptionMultiSelect}
                                        id="user_list"
                                        onChange={(e) => updateField(e.target.value, 'user_list')}
                                        optionLabel="name"
                                        placeholder="Chọn người tham gia họp"
                                        filter
                                        maxSelectedLabels={1}
                                        selectedItemsLabel="{0} người được chọn"
                                        className="hidden-arrow"
                                        disabled
                                        onClick={() => setShowGroupUserModal(true)}
                                    />
                                    {/* <HiOutlineUserGroup
                                        className="user-group-icon"
                                        fontSize={20}
                                        color={'#2970f6'}
                                        onClick={() => setShowGroupUserModal(true)}
                                    /> */}
                                    <span id="username2-help" className="p-invalid p-d-block">
                                        {errData.user_list}
                                    </span>
                                </div>
                                <div
                                    className=" p-field"
                                    style={{ position: 'relative' }}
                                    onChange={(e) => handleChangeKeySearchParticipantOutside(e)}
                                >
                                    {/* Người tham gia cuộc họp ngoài hệ thống*/}
                                    <label htmlFor="user_id" className="align-sub-text label-bold">
                                        Người tham gia cuộc họp ngoài hệ thống{' '}
                                    </label>
                                    <Chips
                                        value={adData.user_list_other}
                                        onChange={(e) => {
                                            updateField(e.value, 'user_list_other');
                                            setSearchKeyworkChip('');
                                        }}
                                        placeholder="Enter để thêm"
                                        className="input-scroll"
                                        inputRef={inputRef}
                                    />
                                    {searchKeyworkChip &&
                                        optionsParticipantOutside &&
                                        optionsParticipantOutside
                                            .filter((email) => email.includes(searchKeyworkChip))
                                            .filter(
                                                (email) => !adData.user_list_other.includes(email)
                                            ).length > 0 && (
                                            <div className="suggest-user-list-other col-md-12 d-flex flex-column">
                                                {optionsParticipantOutside
                                                    .filter((email) =>
                                                        email.includes(searchKeyworkChip)
                                                    )
                                                    .filter(
                                                        (email) =>
                                                            !adData.user_list_other.includes(email)
                                                    )
                                                    .map((email, index) => (
                                                        <span
                                                            key={index}
                                                            onClick={() => {
                                                                updateField(
                                                                    [
                                                                        ...adData.user_list_other,
                                                                        email,
                                                                    ],
                                                                    'user_list_other'
                                                                );
                                                                setSearchKeyworkChip('');
                                                                inputRef.current.value = '';
                                                            }}
                                                        >
                                                            {email}
                                                        </span>
                                                    ))}
                                            </div>
                                        )}
                                    <span id="username2-help" className="p-invalid p-d-block">
                                        {errData.user_list_other}
                                    </span>
                                </div>

                                <div className=" p-field">
                                    {/* tải lên file */}
                                    <label
                                        htmlFor="file_attach"
                                        className="align-sub-text label-bold"
                                    >
                                        Tải lên file
                                    </label>
                                    <InputUploadFile
                                        inputId="file_attach"
                                        nameInput="file_attach"
                                        onUploadFile={onUploadFile}
                                        multiple={true}
                                        maxFileSize={5}
                                        chooseLabel="Chọn file"
                                        acceptFile={acceptFileString}
                                        labelViewFile="Danh sách tài liệu"
                                        files={files}
                                        maxFile={5}
                                        refFile={refFileProcedure}
                                        disabled={adData.typeUpdate === 2}
                                    />
                                    <span id="username2-help" className="p-invalid p-d-block">
                                        {errData.file_attach}
                                    </span>
                                </div>
                                <div className=" p-field">
                                    {/* mô tả thiết bị trong phòng */}
                                    <label
                                        htmlFor="request_device"
                                        className="align-sub-text label-bold"
                                    >
                                        Yêu cầu chuẩn bị
                                    </label>
                                    <InputTextarea
                                        id="request_device"
                                        value={adData.request_device || ''}
                                        onChange={(e) =>
                                            updateField(e.target.value, 'request_device')
                                        }
                                        rows={5}
                                        cols={30}
                                        placeholder="Yêu cầu chuẩn bị"
                                    />
                                    <span id="username2-help" className="p-invalid p-d-block">
                                        {errData.request_device}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                visible={dialogDeleteS}
                style={{ width: '450px' }}
                header={titleDefault.SCHEDULE_DELETE}
                modal
                footer={DialogFooter}
                onHide={onHideDialogDeleteS}
                className="ok"
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span> Bạn có chắc chắn muốn xóa?</span>
                </div>
            </Dialog>
            <Dialog
                visible={dialogUpdateS}
                style={{ width: '450px' }}
                header={titleDefault.SCHEDULE_UPDATE}
                modal
                footer={DialogUpdateFooter}
                onHide={onHideDialogUpdateS}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span> Bạn có chắc chắn muốn cập nhật?</span>
                </div>
            </Dialog>
            <Dialog
                visible={showGroupUserModal}
                header={'Thêm người dùng'}
                footer={FooterGroupUserDialogTemplate}
                modal
                className="p-fluid m-0 group-user__modal"
                style={{ height: '100%' }}
                onHide={() => {
                    setShowGroupUserModal(false);
                }}
                closable={true}
                maximizable
            >
                <div className="schedule__member-popover-search p-2">
                    <div className="schedule__member-popover-search__form">
                        <div className="schedule__member-popover-search__icon dx-icon dx-icon-search">
                            <AiOutlineSearch fontSize={20} color={'#999'} />
                        </div>
                        <input
                            className="schedule__member-popover-search__input input-lg"
                            type="text"
                            placeholder="Tìm kiếm nhân viên"
                            value={searchKeyword}
                            onChange={(e) => setSearchKeyword(e.target.value.toLowerCase())}
                        />
                    </div>
                    <div className="schedule__member-popover-search__form">
                        <Dropdown
                            value={searchGroup}
                            options={dataDropdownGroups}
                            onChange={(e) => setSearchGroup(e.value)}
                            optionLabel="name"
                            showClear
                            filter
                            placeholder="Chọn nhóm người"
                            style={{ width: '210px' }}
                        />
                    </div>
                </div>
                <div className="ps-2" style={{ fontWeight: '500' }}>
                    Tìm thấy {sortedUserFilterTable.length} kết quả.
                </div>
                <div className="schedule__group-user__table-container">
                    <DataTable
                        value={sortedUserFilterTable}
                        dataKey="id"
                        rowHover
                        className="p-datatable-gridlines table-word-break p-datatable-responsive-demo table-theme px-2 mt-2"
                        emptyMessage="Không có data"
                        selection={userSelectCurrent}
                        onSelectionChange={(e) => {
                            setUserSelectCurrent(e.value);
                        }}
                    >
                        <Column
                            selectionMode="multiple"
                            className="stt-table checkbox-table table-theme__header table-theme__header-start"
                        />
                        <Column
                            field="id"
                            header="Mã NV"
                            body={CodeColumnTableTemplate}
                            className="table-theme__header"
                            style={{ width: '120px' }}
                        />
                        <Column
                            field="fullname"
                            header="Họ tên"
                            body={NameColumnTableTemplate}
                            className="table-theme__header"
                            style={{ width: '240px' }}
                        />
                        <Column
                            field="email"
                            header="Email"
                            body={EmailColumnTableTemplate}
                            className="table-theme__header table-theme__header-end"
                        />
                    </DataTable>
                </div>
            </Dialog>
        </div>
    );
};
