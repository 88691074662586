import axios from 'axios';
import { loading } from './loading/Loading';
import keycloak from 'configs/keycloakConfig';
import queryString from 'query-string';
const windowEnvConfig = window['runConfig'];

const axiosClient = axios.create({
    baseURL: windowEnvConfig.REACT_APP_BASE_API_URL,
    headers: {
        'content-type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
    const token = keycloak.authenticated ? keycloak.token : '';
    loading.runLoadingBlockUI();

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

axiosClient.interceptors.response.use(
    (response) => {
        loading.stopRunLoading();
        if (response?.data) {
            return response.data;
        }
        loading.stopRunLoading();

        return response;
    },
    (error) => {
        // Handle errors
        loading.stopRunLoading();

        const response = error.response;
        if (response && response.status === 401) {
            // window.location = '/403';
            console.log('ko load dc');
        }
        if (response && response.status === 500) {
            // console.log('vailol');
        }
        if (response && response.status === 403) {
            // authenticationService.logout();
        }
        // throw error;
    }
);

export default axiosClient;
