import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { GrFormClose } from 'react-icons/gr';
import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './import-user-dialog.scss';
import Excel from '../../assets/file/ImportUser.xlsx';

function ImportUserDialog(props) {
    const { userService, visible, setVisible, showAlert, notification } = props;
    const fileInputRef = useRef(null);
    const history = useHistory();

    const [file, setFile] = useState({});
    const [errors, setErrors] = useState([]);

    const handleFileUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmitImportUser = async () => {
        const formData = new FormData();
        formData.append('file', file);
        const result = await userService.importUser(formData);
        if (result?.status === 1) {
            showAlert(
                'success',
                notification.SUCCESS,
                'Danh sách người dùng import từ file đã được thêm vào hàng đợi!'
            );
            setFile(null);
            setVisible(false);
            return;
        }
        setErrors(result?.errors);
    };

    const deleteDialogFooter = (
        <React.Fragment>
            <div className="import-user__footer">
                <div className="import-user__link-download">
                    <span className="import-user-dialog__header-text">
                        Tải về file mẫu:{' '}
                        <a href={Excel} download>
                            Excel file
                        </a>
                    </span>
                </div>
                <div className="import-user__btn">
                    <Button
                        label="Hủy"
                        icon="pi pi-times"
                        className="p-button-raised p-button-text p-button-danger"
                        onClick={() => setVisible(false)}
                    />
                    <Button
                        label="Xác nhận"
                        icon="pi pi-check"
                        className="p-button-primary p-button-raised"
                        onClick={() => handleSubmitImportUser()}
                    />
                </div>
            </div>
        </React.Fragment>
    );
    return (
        <div>
            <Dialog
                visible={visible}
                className="p-dialog-default import-user-dialog"
                header={
                    <>
                        <strong className="import-user-dialog__header-title">
                            Nhập người dùng từ file dữ liệu&nbsp;
                        </strong>
                        <span
                            className="import-user-dialog__header-text"
                            style={{ color: 'blue' }}
                            onClick={() => history.push('/import-nguoi-dung')}
                        >
                            (xem tiến trình)
                        </span>
                    </>
                }
                modal
                footer={deleteDialogFooter}
                onHide={() => setVisible(false)}
            >
                <div className="import-user-dialog__content">
                    <button
                        className="import-user-dialog__import-btn"
                        onClick={() => handleFileUpload()}
                    >
                        Chọn file dữ liệu
                    </button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e)}
                    />
                </div>
                {file?.name && (
                    <>
                        <div className="import-user-dialog__import-file">
                            <span>{file?.name}</span>
                            <GrFormClose
                                size={15}
                                onClick={() => {
                                    setFile({});
                                    setErrors([]);
                                }}
                            />
                        </div>
                        <div className="import-user-dialog__import-file--errors">
                            {errors && errors?.length > 0 && <div>Import file lỗi:</div>}
                            {errors &&
                                errors?.length > 0 &&
                                errors?.map((item) => (
                                    <div className="import-user-dialog__import-file--errors-item">
                                        <div>Dòng {item?.row}</div>
                                        {item?.error?.map((error) => (
                                            <div>- {error}</div>
                                        ))}
                                    </div>
                                ))}
                        </div>
                    </>
                )}
            </Dialog>
        </div>
    );
}

ImportUserDialog.propTypes = {
    adData: PropTypes.object,
};
ImportUserDialog.defaultProps = {
    adData: {
        name: '',
    },
};
export default ImportUserDialog;
