import { Dialog } from 'primereact/dialog';
import { Rating } from "primereact/rating";
import { useApp } from "context/AppContext";
import { useState, useEffect } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import EvaluateServices from "services/EvaluateServices";
import notification from "helpers/constant/notification";


const DialogEditEvaluate = props => {
    const { showAlert } = useApp();
    const serviceEvaluate = new EvaluateServices();
    const {visibleEvaluate, onHideFormEvaluate, dataEdit } = props;
    const [stars, setStars] = useState(0);
    const [selectedValues, setSelectedValues] = useState("");
    useEffect(() => {
        if (dataEdit) {
          setStars(dataEdit.stars);
          setSelectedValues(dataEdit.content);
        }
      }, [dataEdit]);

    const onEditClick = async () => {
        const evaluateToCreate = {
            id: dataEdit.id,
            contents: selectedValues,
            stars,
        };
        const result = await serviceEvaluate.updateEvaluate(evaluateToCreate);
        try {
            const { error, message, status } = result;
            if (status === 2) {
                if (error) {
                    showAlert("error", notification.UPDATE_FAIL, notification.MESSAGE_VALIDATE);
                } else {
                    showAlert("error", notification.UPDATE_FAIL, message);
                }
                return;
            }
            if (status === 1) {
                showAlert("success", notification.UPDATE_SUCCESS, message);
                // onHideFormEvaluate();
                window.location.reload();
            }
        } catch (error) {
            showAlert("error", notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
        }
    }
    return (
        <Dialog visible={visibleEvaluate}
            header="Chỉnh sửa đánh giá"
            modal className="p-fluid"
            onHide={onHideFormEvaluate}
            style={{ backgroundColor: '#DFEDF8', width: '50%' }}
        >
            <h3 className='p-m-0 text-center'>Bạn thấy cuộc họp này thế nào</h3>
            <Rating 
                value={stars} 
                className='text-center p-m-3'
                onChange={(e) => setStars(e.value)} 
                cancel={false} 
            />
            <div 
                className="rating-description p-d-flex p-justify-center"
                style={{ gap: '20px', transform: "translateY(-10px)"}}
            >
                <p style={{ transform: "translateX(4px)"}}>Rất tệ</p>
                <p style={{ transform: "translateX(7px)"}}>Tệ</p>
                <p style={{ transform: "translateX(7px)"}}>Tạm ổn</p>
                <p style={{ transform: "translateX(1px)"}}>Tốt</p>
                <p>Rất tốt</p>
            </div>
            <div className='p-pb-3'>
                <InputTextarea autoResize value={selectedValues} 
                onChange={(e) => setSelectedValues(e.target.value)} 
                rows={5} cols={30} 
                style={{ borderRadius: '5px', paddingLeft: '10px'}}
                className='box-shadow p-mb-3'
                placeholder='Nhập đánh giá...'
                />
            </div>
            <Button 
                className="p-mt-3 p-mb-2 btn-send-evaluate"
                label="Gửi đánh giá" 
                style={{ backgroundColor: "#4b91e7"}}
                onClick={onEditClick}
            />
        </Dialog>
    );
}

export default DialogEditEvaluate;