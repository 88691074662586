import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";

class ConfigServices extends FetchAPI {
    public async getAll(data) {
        const url = `cau-hinh/index`;
        return await this.request(url, { data }, TypeMethodConstantEnum.GET);
    }
    public async update(data) {
        const url = `cau-hinh/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

}
export default ConfigServices;
