import { Dialog } from 'primereact/dialog';
import { format } from 'date-fns';
import DataEvaluate from './DataEvaluete';

const DialogShowDetail = props => {
    
    const {visible, onHideDialog, dataShowDetailRating } = props;

    const formatDate = (dataShowDetailRating) => {
        return format(new Date(dataShowDetailRating), 'dd/MM/yyyy');
    }

    return (
        <Dialog visible={visible}
            header={
                    "Chi tiết đánh giá: " +
                    dataShowDetailRating.room.name
                } 
            modal className="p-fluid"
            onHide={onHideDialog}
        >
            <div className="">
                <div>
                    <div className=" p-field">
                        <label htmlFor="room_title" className="align-sub-text">
                            <strong>Tiêu đề phòng họp:</strong> {dataShowDetailRating.title}
                        </label>
                    </div>
                    
                </div>
                <div className='p-d-flex p-justify-between' style={{ gap: '20px'}}>
                    <div>
                        <div className=" p-field">
                            <label htmlFor="room_id" className="align-sub-text">
                                <strong>Người chủ trì:</strong> {dataShowDetailRating.user_host_booking.fullname}
                            </label>
                        </div>
                        <div className="p-field">
                            <label htmlFor="start_date" className="align-sub-text">
                                <strong>Bắt đầu:</strong> {formatDate(dataShowDetailRating.date)} lúc {dataShowDetailRating.start_time} 
                            </label>
                        </div>
                    </div>
                    <div>
                        <div className=" p-field">
                            <label htmlFor="email" className="align-sub-text">
                                <strong>Email:</strong> {dataShowDetailRating.user_host_booking.email}
                            </label>
                        </div>
                        <div className="p-field">
                            <label htmlFor="display_name" className="align-sub-text">
                                <strong>Kết thúc:</strong> {formatDate(dataShowDetailRating.date)} lúc {dataShowDetailRating.end_time} 
                            </label>
                        </div>
                    </div>
                </div>
                <DataEvaluate 
                    dataShowDetailRating={dataShowDetailRating} 
                />
            </div>
        </Dialog>
    )
}
export default (DialogShowDetail);
