import { useApp } from 'context/AppContext';
import checkPermission from 'helpers/checkPermisson';
import pages from 'helpers/constant/pages';
import permissions from 'helpers/constant/permissions';
import titleDefault from 'helpers/constant/titleDefault';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Service from 'services/RoleServices';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toolbar } from 'primereact/toolbar';
import { DialogRole } from './DialogRole';
import notification from 'helpers/constant/notification';
import queryString from 'query-string';

export const Role = (props) => {
    const { showAlert, pageLimit } = useApp();
    const services = new Service();
    const [data, setData] = useState();
    const [first, setFirst] = useState(0);
    const [dataSearch, setDataSearch] = useState({
        text_search: '',
    });
    const [row, setRow] = useState(10);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [idDelete, setIdDelete] = useState();
    const [permission, setPermission] = useState([]);
    const [idDeleteMultiple, setIdDeleteMultiple] = useState({
        id: [],
    });
    // check quyền
    const [typeRole, setTypeRole] = useState(1);
    const [dataEdit, setDataEdit] = useState(null);
    const [pagination, setPagination] = useState({
        from: 0,
        to: 0,
        row: 0,
        total: 0,
        page: 0,
    });
    const [deleteRoleDialog, setDeleteRoleDialog] = useState(false);
    const [typeDelete, setTypeDelete] = useState(null);
    const [selectedRole, setSelectedRole] = useState([]);
    const [typeAd, setTypeAd] = useState(1);

    const fetData = async () => {
        const query = queryString.parse(props.location.search);
        const result = await services.getListRole({ ...dataSearch, ...query });
        if (!result) {
            return;
        }
        const { data, status, message } = result;
        if (status === 2) {
            showAlert('error', notification.FAIL, message);
            return;
        }
        setData(result.data.data);
        setPagination({
            from: data.from,
            to: data.to,
            row: data.per_page,
            total: data.total,
            page: data.current_page,
        });
    };
    const getPermission = async () => {
        const result = await services.getAllPermission();
        if (!result) {
            return;
        }
        const { data, status, message } = result;
        if (status === 2) {
            showAlert('error', notification.FAIL, message);
            return;
        }
        const abc = [];
        let i = 0;
        Object.values(data).forEach((answer) => {
            abc.push({
                key: `k${i}`,
                label: answer[0].parent_name,
                children: answer.map((index, value) => {
                    i++;
                    return {
                        key: index.id,
                        label: index.description,
                    };
                }),
            });
        });
        setPermission(abc);
    };
    useEffect(() => {
        getPermission();
        const typePer = checkPermission(permissions.ROLE_UPDATE);
        setTypeRole(typePer);
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        fetData();
        // eslint-disable-next-line
    }, [props.history.location.search]);

    const onEditRole = (rowData) => {
        setDataEdit(rowData);
        setTypeAd(2);
        setVisibleDialog(true);
    };

    const onDeleteRole = (data) => {
        setDeleteRoleDialog(true);
        setIdDelete(data);
        setTypeDelete(1);
    };

    const onSearchClick = (e) => {
        e.preventDefault();
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const pramSearch = { ...jsonDataSearch, text_search: dataSearch.text_search };
        const query = queryString.stringify(pramSearch);
        props.history.push({
            search: query,
        });
    };

    const onCreateClick = () => {
        setTypeAd(1);
        setDataEdit(null);
        setVisibleDialog(true);
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRow(event.rows);
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const dataSearch = {
            ...jsonDataSearch,
            row: event.rows,
            page: event.page + 1,
        };
        const query = queryString.stringify(dataSearch);
        props.history.push({
            search: query,
        });
    };

    const handleChangeDataSearch = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({ ...dataSearch, [name]: value });
    };

    const onHideDialog = () => {
        setDataEdit(null);
        setVisibleDialog(false);
    };

    const hideDeleteRoleDialog = () => {
        setDeleteRoleDialog(false);
        setSelectedRole([]);
    };

    const deleteRole = async (type) => {
        if (type === 1) {
            const result = await services.onDeleteRole(idDelete);
            if (result && result.status === 1) {
                showAlert('success', 'Xóa thành công', result.message);
                hideDeleteRoleDialog();
                await fetData();
            } else {
                showAlert('error', 'Xóa thất bại', result.message);
                hideDeleteRoleDialog();
            }
        } else {
            const result = await services.onDeleteMultiple(idDeleteMultiple);
            if (result && result.status === 1) {
                showAlert('success', 'Xóa thành công', result.message);
                hideDeleteRoleDialog();
                await fetData();
            } else {
                showAlert('error', 'Xóa thất bại', result.message);
                hideDeleteRoleDialog();
            }
        }
    };

    const onDeleteMultiple = async () => {
        setDeleteRoleDialog(true);
        setTypeDelete(2);
        const listIdRole = [];
        selectedRole.forEach((answer) => {
            listIdRole.push(answer.id);
        });
        setIdDeleteMultiple({ id: listIdRole });
    };
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <InputText
                    value={dataSearch.text_search}
                    onChange={(e) => handleChangeDataSearch(e)}
                    placeholder={'Tên vai trò'}
                    className=" w-xs-100 w-md-auto p-mb-2"
                    name="text_search"
                />
            </React.Fragment>
        );
    };
    const deleteRoleDialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={hideDeleteRoleDialog}
            />
            <Button
                label="Xóa"
                icon="pi pi-check"
                className="p-button-danger p-button-raised"
                onClick={() => deleteRole(typeDelete)}
            />
        </React.Fragment>
    );
    const rightToolbarTemplate = () => {
        const classSearch = typeRole === 1 ? 'p-mr-2' : '';
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <Button
                        label="Tìm kiếm"
                        icon="pi pi-search"
                        type="submit"
                        className={`p-button-primary w-xs-100 w-md-auto p-mb-2 ${classSearch}`}
                        onClick={(e) => onSearchClick(e)}
                    />
                    {typeRole === 1 && (
                        <Button
                            label="Xóa"
                            icon="pi pi-trash"
                            className="p-button-danger p-mr-2 p-mb-2  w-xs-100 w-md-auto"
                            onClick={onDeleteMultiple}
                            disabled={selectedRole.length === 0}
                        />
                    )}
                    {typeRole === 1 && (
                        <Button
                            label="Thêm mới"
                            icon="pi pi-plus"
                            className="p-button-success p-mb-2  w-xs-100 w-md-auto"
                            onClick={onCreateClick}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    };
    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">#</span>
                {column.rowIndex + 1 + first}
            </React.Fragment>
        );
    };
    const nameBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Tên</div>
                    <div className="max-line-3" title={rowData.name}>
                        {rowData.name}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const displayNameBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Tên hiển thị</div>
                    <div className="max-line-3" title={rowData.display_name}>
                        {rowData.display_name}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const descriptionBodyTemplate = (rowData, column) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Mô tả</div>
                    <div className="max-line-3" title={rowData.description}>
                        {rowData.description}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <i className="pi pi-pencil p-mr-2 icon-medium pointer text-success action-table-task"
                onClick={() => onEditRole(rowData)} />
                <i className="pi pi-trash icon-medium text-danger pointer action-table-task"
                onClick={() => onDeleteRole(rowData)} />
            </React.Fragment>
        );
    };
    return (
        <div className="role-css">
            <div className="content-section implementation datatable-responsive-demo">
                <div className="card">
                    <div className="card-header">
                        <h5>QUẢN LÝ VAI TRÒ</h5>
                    </div>
                    <form onSubmit={(e) => onSearchClick(e)}>
                        <Toolbar className="p-mb-4 p-d-flex p-flex-wrap"
                        left={leftToolbarTemplate}
                        right={rightToolbarTemplate} />
                    </form>
                    <DataTable
                        rowHover
                        value={data}
                        selection={selectedRole}
                        className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                        emptyMessage="Không có data"
                        onSelectionChange={(e) => {
                            if (
                                !e?.originalEvent?.target?.classList.contains('action-table-task')
                            ) {
                                setSelectedRole(e.value);
                            }
                        }}
                    >
                        <Column selectionMode="multiple" className="stt-table checkbox-table" />
                        <Column body={renderRowIndex} header="#" className="stt-table" />
                        <Column field="name" header="Tên" sortable body={nameBodyTemplate} />
                        <Column
                            field="display_name"
                            header="Tên hiển thị"
                            sortable
                            body={displayNameBodyTemplate}
                        />
                        <Column
                            field="description"
                            header="Mô tả"
                            sortable
                            body={descriptionBodyTemplate}
                        />
                        {typeRole === 1 && (
                            <Column
                                header="Tác vụ"
                                body={actionBodyTemplate}
                                className="p-text-center action-table"
                            />
                        )}
                    </DataTable>
                    <div className="p-d-flex p-flex-wrap">
                        <div className="text-normal p-mt-2">
                            <span>
                                Hiển thị từ <b>{pagination.from}</b> đến <b>{pagination.to}</b> /
                                Tổng <b>{pagination.total}</b> bản ghi
                            </span>
                        </div>
                        <div className="p-ml-auto p-mt-2">
                            <Paginator
                                first={first}
                                rows={row}
                                totalRecords={pagination.total}
                                rowsPerPageOptions={pages}
                                onPageChange={(event) => onPageChange(event)}
                                template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                                pageLinkSize={pageLimit}
                            />
                        </div>
                    </div>
                </div>
                <DialogRole
                    visible={visibleDialog}
                    onHideDialog={onHideDialog}
                    typeAd={typeAd}
                    feData={fetData}
                    dataEdit={dataEdit}
                    permission={permission}
                />
                <Dialog
                    visible={deleteRoleDialog}
                    header={titleDefault.ROLE_DELETE}
                    typeDelete={typeDelete}
                    modal
                    footer={deleteRoleDialogFooter}
                    onHide={hideDeleteRoleDialog}
                    className="p-dialog-default"
                >
                    <div className="confirmation-content">
                        <i
                            className="pi pi-exclamation-triangle p-mr-3"
                            style={{ fontSize: '2rem' }}
                        />
                        <span> Bạn có chắc chắn muốn xóa vai trò này?</span>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};
export default withRouter(Role);
