import { ColorPicker } from 'primereact/colorpicker';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';

const ListMeetingRoom = (props) => {
    const { listMeeting } = props;

    const colorBody = (rowData) => {
        return <ColorPicker value={rowData.color} ></ColorPicker>;
    };
    return (
        <div>
            <div className="list-meeeting-room">
                <div className="card">
                    <h5>Danh sách phòng</h5>
                    <DataTable value={listMeeting}
                        style={{ width: '100%' }}
                        scrollable scrollHeight="86.5vh"
                        emptyMessage="Không có data"
                    >
                        <Column field="name" header="Tên phòng"  />
                        <Column field="color" header="Màu"
                        body={colorBody} />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default ListMeetingRoom;
