import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom';
import { authenticationService } from "services/AuthenticationService";
import { useApp } from 'context/AppContext';

export const Page500 = () => {
    const { isAuth } = useApp();
    const currentUser = authenticationService.currentUserValue;
    if (!isAuth) {
        if (!currentUser) {
            window.location.hash = "/login";
        }
    }
    return (
        <div className="page-error page-error__500">
            <div className="pg-white p-text-center">
                <div className="page-error__header">500</div>
                <div className="page-error__body">
                    Lỗi hệ thống
                        <div className="p-mt-5">
                        <Link to='/'>
                            <Button
                                className="p-button-lg btn-err "
                                icon="pi pi-home"
                                label="Trang chủ"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
