import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";
class PlaceServices extends FetchAPI {
    //Lấy danh sách khách hàng
    public async getAll(data) {
        const url = `dia-diem/getAllPlace`;
        return await this.request(url, { data }, TypeMethodConstantEnum.GET);
    }
    public async getFilter(data) {
        const url = `dia-diem/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async get(data) {
        const url = `/dia-diem/getPlaceById`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async create(data) {
        const url = `dia-diem/create`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async update(data) {
        const url = `dia-diem/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async delete(data) {
        const url = `dia-diem/delete`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async deleteMultiple(data) {
        const url = `dia-diem/deleteMultiple`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
}
export default PlaceServices;
