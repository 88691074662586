import React, { useEffect, useState } from "react";
import { useApp } from 'context/AppContext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Service from 'services/UserServices';
import notification from 'helpers/constant/notification';

export default function DialogAgency(props) {
    const { showAlert } = useApp();
    const {visible,  onHideDialog} = props;
    const service = new Service();

    const [agencyData, setAgencyData] = useState([]);

    const handleClick = e => {
        const currentTarget = e.currentTarget;
        const agencyItems = document.querySelectorAll('.agency-item');
        agencyItems.forEach(item => {
            item.classList.add('agency-uncheck');
            item.classList.remove('agency-checked');
        });
        currentTarget.classList.add('agency-checked');
        currentTarget.classList.remove('agency-uncheck');
    };

    const saveData = async () => {
        const userAgencyId = document.querySelector('.agency-checked');
        const classNameAgency = userAgencyId.classList;
        const agencyId = classNameAgency[1];
        const [, valueUpdateAgency] = agencyId.split("-");
        const result = await service.switchAgencyActive(valueUpdateAgency);
        try {
            const { message, status } = result;
            if (status === 2) {
                showAlert('error', notification.CREATE_FAIL, message);
                return;
            }
            showAlert('success', notification.SUCCESS, message);
            window.location.reload();
        } catch (error) {
            showAlert('error', notification.FAIL, notification.MESSAGE_ERROR);
        }
    }

    const fetchData = async () => {
        const result = await service.getUserInfo({});
        const { agency, data } = result;
        const activeAgency = data.active_agency_id;
        const sortedAgency = [...agency];
        sortedAgency.sort((a, b) => {
            if (a.id === activeAgency) {
                return -1; 
            } else if (b.id === activeAgency) {
                return 1;
            }
            return 0;
        });
        const agencyElements = sortedAgency.map((item, index) => {
            const agencyChecked = item.id === activeAgency ? 'agency-checked' : 'agency-uncheck';
            return ( 
                <div key={item.id}
                    className="col-3"
                >
                    <div 
                        className={`agency-item agencyCode-${item.id} ${agencyChecked}`}
                        onClick={handleClick}
                    >
                        <div className="p-3 w-100 h-100">
                            <p style={{ fontSize: '13px' }}>{item.fullname}</p>
                            <p className="mb-3">
                                { item.id === activeAgency ? "( Đang sử dụng )" : ""}
                            </p>
                        </div>
                    </div>
                </div>
            );
        });
        setAgencyData(agencyElements);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
   
    const dialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={onHideDialog}
            />
            <Button label="Lưu" icon="pi pi-check" className="p-button-raised" onClick={saveData} />
        </React.Fragment>
    );

    return (
        <div className="card flex justify-content-center">
            <Dialog 
                header="Doanh nghiệp của bạn" 
                visible={visible} 
                maximizable  
                style={{ width: '55vw' }}
                footer={dialogFooter}
                onHide={onHideDialog}
            >
               <div className="row">
                    { agencyData }
               </div>
            </Dialog>
        </div>
    )
}
       