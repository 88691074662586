import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";

class DashboardServices extends FetchAPI {

    public async roomTimeUsed(data) {
        const url = `dashboard/room-time-used`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async pieChartBooking(data) {
        const url = `dashboard/pie-chart-booking`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
}
export default DashboardServices;
