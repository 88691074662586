const notification = {
    CREATE_SUCCESS: 'Tạo mới thành công',
    CREATE_FAIL: 'Tạo mới thất bại',
    CREATE_SEND: 'Gửi thông báo thất bại',
    UPDATE_SUCCESS: 'Cập nhật thành công',
    UPDATE_FAIL: 'Cập nhật thất bại',
    DELETE_SUCCESS: 'Xóa thành công',
    STATISTICAL_FAIL: 'Thống kê thất bại',
    STATISTICAL_FAIL_FISRT: 'Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc',
    STATISTICAL_FAIL_SECOND: 'Thời gian kết thúc không được để trống',
    FILE_EXCEL: 'Vui lòng chọn một file Excel (.xlsx, xls)',
    DELETE_FAIL: 'Xóa thất bại',
    SUCCESS: 'Thành công',
    FAIL: 'Thất bại',
    MESSAGE_ERROR: 'Có lỗi xảy ra',
    MESSAGE_VALIDATE: 'Kiểm tra lại dữ liệu nhập',
};
export default notification;
