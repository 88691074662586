import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React from 'react'
import PropTypes from 'prop-types'
import titleDefault from 'helpers/constant/titleDefault';



function ChangeLock(props) {
    const { dialogLock, setDialogLock, adData, serviceRoomMeetting, showAlert, fetData } = props;

    const onHideDialog = () => {
        setDialogLock(false);
    }
    const handelChangeLock = async () => {
        try {
            const response = await serviceRoomMeetting.lock(adData);
            const { status, message } = response;
            onHideDialog();
            if (status === 2) {
                showAlert('error', 'Cập nhật thất bại', message);
            }
            if (status === 1) {
                fetData();
                showAlert('success', 'Cập nhật thành công', message);
                onHideDialog();
            }
        } catch (error) {
            onHideDialog();
            showAlert('error', 'Cập nhật thất bại', 'Có lỗi xảy ra');
        }
    };
    const handelChangeUnLock = async () => {
        try {
            const response = await serviceRoomMeetting.unLock(adData);
            const { status, message } = response;
            onHideDialog();
            if (status === 2) {
                showAlert('error', 'Cập nhật thất bại', message);
            }
            if (status === 1) {
                fetData();
                showAlert('success', 'Cập nhật thành công', message);
                onHideDialog();
            }
        } catch (error) {
            onHideDialog();
            showAlert('error', 'Cập nhật thất bại', 'Có lỗi xảy ra');
        }
    }
    const lockDialogFooter = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-raised p-button-text p-button-danger" onClick={onHideDialog} />
            <Button label="Khóa" icon="pi pi-check"
                className="p-button-danger p-button-raised" onClick={handelChangeLock} />
        </React.Fragment>
    );
    const unLockDialogFooter = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text p-button-raised" onClick={onHideDialog} />
            <Button label="Mở khóa" icon="pi pi-check"
                className="p-button-primary p-button-raised" onClick={handelChangeUnLock} />
        </React.Fragment>
    );
    return (
        <div>
            <Dialog visible={dialogLock} header={titleDefault.ROOM_MEETING_STATUS} modal
                footer={adData.status === 1 ? lockDialogFooter : unLockDialogFooter} onHide={onHideDialog}
                className="p-dialog-default">
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {adData.name && <span>Bạn có chắc chắn muốn {adData.status === 1 ? 'khóa' : 'mở khóa'} phòng <b>{adData.name}</b>?</span>}
                </div>
            </Dialog>
        </div>
    )
}

ChangeLock.propTypes = {
    adData: PropTypes.object,
}
ChangeLock.defaultProps = {
    adData: {
        name: ""
    },
}

export default (ChangeLock);
