import axios from "axios";
import { Component } from "react";
import { authenticationService } from "../services/AuthenticationService";
import { loading } from "./loading/Loading";

const windowEnvConfig = window['runConfig'];
class FetchAPI extends Component {
    public BASE_URL = windowEnvConfig.REACT_APP_BASE_API_URL;
    private axiosInstance: any;
    constructor(props: any) {
        super(props);
     
        let token = JSON.parse(localStorage.getItem("currentUser")!)?.token;

        this.axiosInstance = axios.create({
            baseURL: this.BASE_URL,
            timeout: 30_000,
            headers: {
                Authorization: `Bearer ${token}`,
                "X-Requested-With": "XMLHttpRequest",
            },
            httpAgent: true,
        });
    }

    public async request(path: string, data: object, method: string) {
        const dynamicKeyData = method === "GET" ? "params" : "data";
        const configRequest = {
            url: path,
            method,
            [dynamicKeyData]: data,
        };
        try {
            loading.runLoadingBlockUI();
            const result = await this.axiosInstance
                .request(configRequest)
                .catch((response) => this.handleResponse(response));
            loading.stopRunLoading();
            return result.data;
        } catch (error) {
            loading.stopRunLoading();
            console.log(JSON.stringify(error));
        } finally {
            loading.stopRunLoading();
        }
    }
    public handleResponse(error) {
        const response = error.response;
        if (response === undefined) {
            window.location.hash = "/500";
        }
        if (response && response.status === 401) {
            authenticationService.logout();
            // window.location.hash = "/login";
        }
        if (response && response.status === 403) {
            window.location.hash = "/403";
        }
        if (response && response.status === 500) {
            window.location.hash = "/500";
        }
        throw new Error(error);
    }
}

export default FetchAPI;
