import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";
class GroupServices extends FetchAPI {
    public async get(data) {
        const url = `nhom/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async getById(data) {
        const url = `nhom/get-by-uuid`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }
    public async create(data) {
        const url = `nhom/create`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async update(data) {
        const url = `nhom/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async delete(data) {
        const url = `nhom/delete`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async deleteMultiple(data) {
        let url = `nhom/delete-multiple`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async synchronizedGroupWithOdoo(data) {
        const url = `nhom/synchronized-group-odoo`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async getAll() {
        const url = `nhom/get-all`;
        return await this.request(url, {}, TypeMethodConstantEnum.GET);
    }
}
export default GroupServices;
