import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Toolbar } from 'primereact/toolbar';
import { SelectedTemplate } from 'components/dropdow-value/SelectedTemplate';
import { OptionTemplate } from 'components/dropdow-value/OptionTemplate';
import { useApp } from 'context/AppContext';
import checkPermission from 'helpers/checkPermisson';
import pages from 'helpers/constant/pages';
import permissions from 'helpers/constant/permissions';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PlaceServices from 'services/PlaceServices';
import RoomMeetingServices from 'services/RoomMeetingServices';
import { v4 as uuidv4 } from 'uuid';
import ChangeLock from './ChangeLock';
import DetailRoomMeeting from './DetailRoomMeeting';
import RoomMeetingDialogAddEdit from './RoomMeetingDialogAddEdit';
import DialogDelete from './DialogDelete';
import notification from 'helpers/constant/notification';
import { ImportRoomMeeting } from './ImportRoomMeeting';

export const RoomMeeting = (props) => {
    const emptyData = {
        name: '',
        place_id: null,
        capacity: 1,
        owner: null,
        note: '',
        device: null,
        color: '2196f3',
    };
    const { showAlert, pageLimit } = useApp();
    const emptyMeetingDevice = {
        id: uuidv4(),
        id_device: null,
        deviceInfo: null,
        note: '',
        amount: 1,
    };
    const serviceRoomMeetting = new RoomMeetingServices();
    const servicePlace = new PlaceServices();

    //danh sách
    const [listData, setListData] = useState();
    //danh sách địa điểm
    const [listPlace, setListPlace] = useState([]);
    // dialog xóa
    const [dialogDelete, setDialogDelete] = useState(false);
    // check quyền
    const [typeRole, setTypeRole] = useState(1);
    // loại xóa nhiều xóa 1
    const [typeDelete, setTypeDelete] = useState(1);
    // loại thêm mới hay sửa
    const [typeAd, setTypeAd] = useState(1);
    // dialog thay đổi trạng thái
    const [dialogLock, setDialogLock] = useState(false);
    // dialog chi tiết
    const [dialogDetail, setDialogDetail] = useState(false);
    // data thêm mới xóa
    const [adData, setAdData] = useState(emptyData);
    // data chi tiết
    const [detailData, setDetailData] = useState(emptyData);
    // data lỗi validate
    const [errData, setErrData] = useState({});
    //submit form
    const [submitted, setSubmitted] = useState(false);
    const [visibleImportRoomMeetingDialog, setVisibleImportRoomMeetingDialog] = useState(false);
    // data phân trang
    const filter = props.history.location.search;
    const [pagination, setPagination] = useState({
        from: 0,
        to: 0,
        row: 0,
        total: 0,
        page: 0,
    });
    const [dataSearch, setDataSearch] = useState({
        text_search: '',
        place_id: null,
    });
    const [row, setRow] = useState(10);
    const [first, setFirst] = useState(0);
    // data xóa nhiều
    const [selectedDatas, setSelectedDatas] = useState([]);
    // trạng thái tab 0  hoặc 1
    const [activeIndex, setActiveIndex] = useState(0);
    const [meetingDevice, setMeetingDevice] = useState([emptyMeetingDevice]);
    // dialog thêm, sửa
    const [visibleDialog, setVisibleDialog] = useState(false);
    useEffect(() => {
        fetData();
        // eslint-disable-next-line
    }, [filter]);
    useEffect(() => {
        getPlaces();
        const typePer = checkPermission(permissions.MEETINGROOM_UPDATE);
        setTypeRole(typePer);
        // eslint-disable-next-line
    }, []);
    // lấy danh sách địa điểm
    const getPlaces = async () => {
        const response = await servicePlace.getAll();
        if (!response) {
            return;
        }
        const { data } = response;
        const arr = [];
        data.map((e) => arr.push({ code: e.id, name: e.name }));
        setListPlace(arr);
    };
    const fetData = async () => {
        const query = queryString.parse(props.location.search);
        const response = await serviceRoomMeetting.getFilter({ ...dataSearch, ...query });
        if (!response) {
            return;
        }
        const { data, status, message } = response;
        if (status === 2) {
            showAlert('error', notification.FAIL, message);
            return;
        }
        if (status === 1) {
            setListData(data.data);
            setPagination({
                from: data.from,
                to: data.to,
                row: data.per_page,
                total: data.total,
                page: data.current_page,
            });
        }
    };

    // tìm kiếm
    const onSearchClick = async (e) => {
        e.preventDefault();
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const pramSearch = {
            ...jsonDataSearch,
            text_search: dataSearch.text_search,
            place_id: dataSearch.place_id,
        };
        const query = queryString.stringify(pramSearch);
        await props.history.push({
            search: query,
        });
    };
    // Phân trang
    const onPageChange = (event) => {
        setFirst(event.first);
        setRow(event.rows);
        const jsonDataSearch = queryString.parse(props.history.location.search);
        const dataSearch = {
            ...jsonDataSearch,
            row: event.rows,
            page: event.page + 1,
        };
        const query = queryString.stringify(dataSearch);
        props.history.push({
            search: query,
        });
    };
    // f5 lại trang xóa dữ liệu tìm kiếm trên URL
    window.onload = () => {
        props.history.push({
            search: '',
        });
    };
    const onShowClick = (rowData) => {
        try {
            setDialogDetail(true);
            setActiveIndex(0);
            const newDetail = { ...rowData };
            setDetailData({
                ...newDetail,
                owner: rowData.meeting_room_user.email,
                place: rowData.meeting_room_place.name,
                deviceinFo: rowData.meeting_room_device,
            });
        } catch (error) {
            showAlert('error', notification.SUCCESS, notification.MESSAGE_ERROR);
        }
    };
    // dialog thêm mới
    const onCreateClick = () => {
        setAdData(emptyData);
        setSubmitted(false);
        setVisibleDialog(true);
        setMeetingDevice([emptyMeetingDevice]);
        setActiveIndex(0);
        setTypeAd(1);
    };
    // dialog sửa
    const onEditClick = (rowData) => {
        try {
            setActiveIndex(0);
            const newRowData = { ...rowData };
            setAdData({
                ...newRowData,
            });
            const deviceIn = rowData.meeting_room_device;
            deviceIn.map(
                (e) =>
                    (e.deviceInfo = {
                        code: e.device.id,
                        name: e.device.name,
                    })
            );
            setTypeAd(2);
            setMeetingDevice(deviceIn);
            setVisibleDialog(true);
        } catch (error) {
            showAlert('error', notification.FAIL, notification.MESSAGE_ERROR);
        }
    };
    //Ẩn dialog thêm mới, cập nhật
    const onHideDialog = () => {
        setErrData({});
        setSubmitted(false);
        setVisibleDialog(false);
    };
    // dialog thay đổi status khóa
    const onChangeLockClick = (rowData) => {
        setAdData(rowData);
        setDialogLock(true);
    };
    // bật dialog xóa và tìm data
    const confirmDelete = (rowData) => {
        setTypeDelete(1);
        setAdData(rowData);
        setDialogDelete(true);
    };
    // bật dialog xóa nhiều
    const confirmDeletes = (data) => {
        setTypeDelete(2);
        setDialogDelete(true);
    };
    // xóa nhiều thiết bị và render lại data
    const handelChangeDeletes = () => {
        setSelectedDatas([]);
        fetData();
    };

    const onChangeDataSearch = async (data, field) => {
        setDataSearch({
            ...dataSearch,
            [field]: data,
        });
    };

    // tìm kiếm
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <InputText
                        value={dataSearch.text_search}
                        onChange={(e) => onChangeDataSearch(e.target.value, 'text_search')}
                        placeholder={'Tìm theo tên'}
                        className="p-mr-2 w-xs-100 w-md-auto p-mb-2"
                        name="text_search"
                    />
                    <Dropdown
                        id="place_id"
                        value={dataSearch.place_id}
                        options={listPlace}
                        onChange={(e) => onChangeDataSearch(e.value, 'place_id')}
                        name="place_id"
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder="Tìm theo địa điểm"
                        className="w-xs-100 w-md-auto p-mb-2"
                        optionValue="code"
                        valueTemplate={SelectedTemplate}
                        itemTemplate={OptionTemplate}
                    />
                </div>
            </React.Fragment>
        );
    };
    // Thêm mới, xóa,...
    const rightToolbarTemplate = () => {
        const classSearch = typeRole === 1 ? 'p-mr-2' : '';
        return (
            <React.Fragment>
                <div className="p-flex-wrap w--100">
                    <Button
                        label="Import"
                        style={{
                            background: 'rgb(123 78 116)',
                            border: '1px solid rgb(123 78 116)',
                        }}
                        icon="pi pi-file-excel"
                        className={` w-xs-100 w-md-auto p-mb-2 p-mr-2 p-d-none`}
                        onClick={(e) => {
                            e.preventDefault();
                            setVisibleImportRoomMeetingDialog(true);
                        }}
                    />
                    <Button
                        label="Tìm kiếm"
                        icon="pi pi-search"
                        type="submit"
                        className={`p-button-primary w-xs-100 w-md-auto p-mb-2 ${classSearch}`}
                        onClick={(e) => onSearchClick(e)}
                    />
                    {typeRole === 1 && (
                        <Button
                            label="Xóa"
                            icon="pi pi-trash"
                            className="p-button-danger p-mr-2 w-xs-100 w-md-auto p-mb-2"
                            onClick={confirmDeletes}
                            disabled={selectedDatas.length === 0}
                        />
                    )}
                    {typeRole === 1 && (
                        <Button
                            label="Thêm mới"
                            icon="pi pi-plus"
                            className="p-button-success w-xs-100 w-md-auto p-mb-2"
                            onClick={onCreateClick}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    };
    // index của table
    const renderRowIndex = (rowData, column) => {
        return (
            <React.Fragment>
                <span className="p-column-title">#</span>
                {column.rowIndex + 1 + first}
            </React.Fragment>
        );
    };
    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Tên phòng</div>
                    <div className="max-line-3" title={rowData.name}>
                        {rowData.name}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    // body địa điểm
    const placeBodyTemplate = (rowData) => {
        const placeName = rowData.meeting_room_place
            ? rowData.meeting_room_place.name
            : 'K tồn tại';
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Địa điểm</div>
                    <div className="max-line-3" title={placeName}>
                        {placeName}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    const capacityBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Sức chứa</div>
                    <div>{rowData.capacity}</div>
                </div>
            </React.Fragment>
        );
    };
    // status body
    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="p-d-flex">
                    <div className="p-column-title">Trạng thái</div>
                    <div className={`p-tag p-tag-${rowData.status === 1 ? 'success' : 'danger'}`}>
                        {rowData.status === 1 ? 'Hoạt động' : 'Khóa'}
                    </div>
                </div>
            </React.Fragment>
        );
    };
    // action sửa xóa
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <i className="pi pi-eye p-mr-2 icon-medium pointer text-primary action-table-task"
                onClick={() => onShowClick(rowData)} />
                <i className="pi pi-pencil p-mr-2 icon-medium pointer text-success action-table-task"
                onClick={() => onEditClick(rowData)} />
                <i className={`pi icon-medium p-mr-2 text-warning pointer action-table-task pi-${
                        rowData.status === 1 ? 'lock' : 'unlock'
                    }`}
                onClick={() => onChangeLockClick(rowData)} />
                <i className="pi pi-trash icon-medium text-danger pointer action-table-task"
                onClick={() => confirmDelete(rowData)} />
            </React.Fragment>
        );
    };
    // show toast hiện thông báo
    return (
        <div className="category-room-meeting">
            <div className="content-section implementation datatable-responsive-demo">
                <div className="card">
                    <div className="card-header">
                        <h5>QUẢN LÝ PHÒNG HỌP</h5>
                    </div>
                    <form onSubmit={(e) => onSearchClick(e)}>
                        <Toolbar
                            className="p-mb-4 p-d-flex p-flex-wrap"
                            left={leftToolbarTemplate}
                            right={rightToolbarTemplate}
                        />
                    </form>
                    <DataTable
                        rowHover
                        value={listData}
                        dataKey="id"
                        className="p-datatable-gridlines table-word-break p-datatable-responsive-demo"
                        emptyMessage="Không có data"
                        selection={selectedDatas}
                        onSelectionChange={(e) => {
                            if (
                                !e?.originalEvent?.target?.classList.contains('action-table-task')
                            ) {
                                setSelectedDatas(e.value);
                            }
                        }}
                    >
                        <Column selectionMode="multiple" className="stt-table checkbox-table" />
                        <Column body={renderRowIndex} header="#" className="stt-table" />
                        <Column field="name" header="Tên phòng" sortable body={nameBodyTemplate} />
                        <Column
                            field="place_id"
                            header="Địa điểm"
                            sortable
                            body={placeBodyTemplate}
                        />
                        <Column
                            field="capacity"
                            header="Sức chứa"
                            sortable
                            body={capacityBodyTemplate}
                        />
                        <Column
                            field="status"
                            header="Trạng thái"
                            body={statusBodyTemplate}
                            sortable
                        />
                        {typeRole === 1 && (
                            <Column
                                header="Tác vụ"
                                body={actionBodyTemplate}
                                className="action-table p-text-center action-table-task"
                            />
                        )}
                    </DataTable>
                    {/* phân trang */}
                    <div className="p-d-flex p-flex-wrap">
                        <div className="text-normal p-mt-2">
                            <span>
                                Hiển thị từ <b>{pagination.from}</b> đến <b>{pagination.to}</b> /
                                Tổng <b>{pagination.total}</b> bản ghi
                            </span>
                        </div>
                        <div className="p-ml-auto p-mt-2">
                            <Paginator
                                first={first}
                                rows={row}
                                totalRecords={pagination.total}
                                rowsPerPageOptions={pages}
                                onPageChange={(event) => onPageChange(event)}
                                template=" RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  "
                                pageLinkSize={pageLimit}
                            />
                        </div>
                    </div>
                </div>
                <RoomMeetingDialogAddEdit
                    visible={visibleDialog}
                    onHideDialog={onHideDialog}
                    adData={adData}
                    setAdData={setAdData}
                    emptyData={emptyData}
                    serviceRoomMeetting={serviceRoomMeetting}
                    showAlert={showAlert}
                    errData={errData}
                    setErrData={setErrData}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    meetingDevice={meetingDevice}
                    fetData={fetData}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    listPlace={listPlace}
                    typeAd={typeAd}
                />
                {/* thay đổi trạng thái khóa  */}
                <ChangeLock
                    dialogLock={dialogLock}
                    setDialogLock={setDialogLock}
                    adData={adData}
                    detailData={detailData}
                    serviceRoomMeetting={serviceRoomMeetting}
                    showAlert={showAlert}
                    fetData={fetData}
                />
                {/* Xóa thiết bị */}
                <DialogDelete
                    dialogDelete={dialogDelete}
                    setDialogDelete={setDialogDelete}
                    adData={adData}
                    serviceRoomMeetting={serviceRoomMeetting}
                    showAlert={showAlert}
                    typeDelete={typeDelete}
                    selectedDatas={selectedDatas}
                    onDeletes={handelChangeDeletes}
                    fetData={fetData}
                />
                <DetailRoomMeeting
                    detailData={detailData}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    dialogDetail={dialogDetail}
                    setDialogDetail={setDialogDetail}
                />
                <ImportRoomMeeting
                    serviceRoomMeetting={serviceRoomMeetting}
                    servicePlace={servicePlace}
                    visible={visibleImportRoomMeetingDialog}
                    setVisible={setVisibleImportRoomMeetingDialog}
                    showAlert={showAlert}
                    notification={notification}
                />
            </div>
        </div>
    );
};
export default withRouter(RoomMeeting);
