import React, { Component } from 'react';
import Device from 'pages/category-device/Device';
import { Page404 } from 'pages/errors/Page404';
import { Route, Switch } from 'react-router-dom';
import Location from 'pages/category-location/Location';
import Evaluate from 'pages/category-evaluate/Evaluate';
import RoomMeeting from 'pages/category-room-meeting/RoomMeeting';
import { Role } from 'pages/role/Role';
import { Users } from 'pages/users/User';
import { Schedule } from 'pages/my-booking-schedule/Schedule';
import { Config } from 'pages/config/Config';
import { ScheduleAll } from 'pages/category-schedule/ScheduleAll';
import { Page500 } from 'pages/errors/Page500';
import { MailTemplate } from 'pages/category-mail/MailTemplate';
import Home from 'pages/home/Home';
import Profile from 'pages/users/profile/Profile';
import ChangePassWord from 'pages/users/change-pass/ChangePassWord';
import Group from 'pages/category-group/Group';
import ImportUser from 'pages/users/ImportUser';
class AppRouter extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/danh-gia" component={Evaluate} />
                <Route exact path="/dia-diem" component={Location} />
                <Route exact path="/thiet-bi" component={Device} />
                <Route exact path="/phong-hop" component={RoomMeeting} />
                <Route exact path="/nguoi-dung" component={Users} />
                <Route exact path="/doi-mat-khau" component={ChangePassWord} />
                <Route exact path="/ho-so" component={Profile} />
                <Route exact path="/vai-tro" component={Role} />
                <Route exact path="/lich-dat-cua-toi" component={Schedule} />
                <Route exact path="/lich-dat-cua-toi/:route" component={Schedule} />
                <Route exact path="/lich-dat" component={ScheduleAll} />
                <Route exact path="/mau-mail" component={MailTemplate} />
                {/* <Route exact path="/403" component={Page403} /> */}
                <Route exact path="/500" component={Page500} />
                <Route exact path="/cau-hinh" component={Config} />
                <Route exact path="/nhom" component={Group} />
                <Route exact path="/import-nguoi-dung" component={ImportUser} />
                <Route component={Page404} />
            </Switch>
        );
    }
}

export default AppRouter;
