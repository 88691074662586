import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { GrFormClose } from 'react-icons/gr';
import React, { useState, useRef } from 'react';
import './import-room-meeting-dialog.scss';
import { useHistory } from 'react-router-dom';

export const ImportRoomMeeting = (props) => {
    const { serviceRoomMeetting, visible, setVisible, showAlert, notification } = props;

    const fileInputRef = useRef(null);
    const history = useHistory();

    const [file, setFile] = useState({});
    const [errors, setErrors] = useState([]);

    const handleFileUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (
                selectedFile.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                setFile(selectedFile);
            } else {
                showAlert('error', notification.MESSAGE_VALIDATE, notification.FILE_EXCEL);
            }
        }
    };

    const handleSubmitImportRoomMeeting = async () => {
        const formData = new FormData();
        formData.append('file', file);
        const result = await serviceRoomMeetting.importRoomMeeting(formData);

        if (result?.status === 1) {
            showAlert(
                'success',
                notification.SUCCESS,
                'Danh sách phòng họp import từ file đã được thêm vào hàng đợi!'
            );
            setFile(null);
            setVisible(false);
            return;
        }
        setErrors(result?.errors);
    };
    const exportExcelSample = async () => {
        try {
            // Gửi yêu cầu API để tạo và trả về tệp Excel
            const response = await serviceRoomMeetting.exportRoomMeetingSample('');
            const a = document.createElement('a');
            a.href = response.path;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            showAlert('error', 'Download failed', 'Download failed');
        }
    };

    const deleteDialogFooter = (
        <React.Fragment>
            <div className="p-d-flex p-justify-between p-align-center">
                <p className="p-my-0 pointer sample-file" onClick={exportExcelSample}>
                    Tải xuống file mẫu
                </p>
                <div>
                    <Button
                        label="Hủy"
                        icon="pi pi-times"
                        className="p-button-raised p-button-text p-button-danger"
                        onClick={() => setVisible(false)}
                    />
                    <Button
                        label="Xác nhận"
                        icon="pi pi-check"
                        className="p-button-primary p-button-raised"
                        onClick={() => handleSubmitImportRoomMeeting()}
                    />
                </div>
            </div>
        </React.Fragment>
    );
    return (
        <div>
            <Dialog
                visible={visible}
                className="p-dialog-default import-room-meeting-dialog"
                header={
                    <>
                        <strong className="import-room-meeting-dialog__header-title">
                            Nhập phòng họp từ file dữ liệu&nbsp;
                        </strong>
                        <span
                            className="import-room-meeting-dialog__header-text"
                            style={{ color: 'blue' }}
                            onClick={() => history.push('/import-nguoi-dung')}
                        >
                            ( Xem tiến trình )
                        </span>
                    </>
                }
                modal
                footer={deleteDialogFooter}
                onHide={() => setVisible(false)}
            >
                <div className="import-room-meeting-dialog__content">
                    <button
                        className="import-room-meeting-dialog__import-btn"
                        onClick={() => handleFileUpload()}
                    >
                        Chọn file dữ liệu
                    </button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        className="p-d-none"
                        onChange={(e) => handleFileChange(e)}
                    />
                </div>
                {file?.name && (
                    <>
                        <div className="import-room-meeting-dialog__import-file">
                            <span>{file?.name}</span>
                            <GrFormClose
                                size={15}
                                onClick={() => {
                                    setFile({});
                                    setErrors([]);
                                }}
                            />
                        </div>
                        <div className="import-room-meeting-dialog__import-file--errors">
                            {errors && errors?.length > 0 && <div>Import file lỗi:</div>}
                            {errors &&
                                errors?.length > 0 &&
                                errors?.map((item) => (
                                    <div className="import-room-meeting-dialog__import-file--errors-item">
                                        <div>Dòng {item?.row}</div>
                                        {item?.error?.map((error) => (
                                            <div>- {error}</div>
                                        ))}
                                    </div>
                                ))}
                        </div>
                    </>
                )}
            </Dialog>
        </div>
    );
};
