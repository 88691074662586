import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";

class UploadFileService extends FetchAPI {
    //Lấy danh sách khách hàng
    public async uploadFile(data) {
        const url = `tai-file/uploadLocal`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async uploadFileMinio(data) {
        const url = `tai-file/upload-minio`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async deleteFileMinio(path) {
        const url = `tai-file/delete-minio`;
        return await this.request(url, {path: path}, TypeMethodConstantEnum.POST);
    }
}
export default UploadFileService;
