import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import notification from 'helpers/constant/notification';
import titleDefault from 'helpers/constant/titleDefault';

const DialogAddEdit = (props) => {
    //Const
    const {
        showAlert,
        fetData,
        visible,
        onHideDialog,
        serviceGroup,
        typeAd,
        adData,
        setAdData,
        errData,
        setErrData,
        listUser,
    } = props;

    //Handle
    const updateField = (data, field) => {
        setAdData({
            ...adData,
            [field]: data,
        });
    };

    const saveData = async () => {
        if (typeAd === 1) {
            try {
                const userCodes = adData.group_users.map((user) => user.code);
                adData.user_ids = userCodes;
                const result = await serviceGroup.create(adData);
                const { error, message, status } = result;
                if (status === 2) {
                    if (error) {
                        showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_VALIDATE);
                        setErrData(error);
                    } else {
                        showAlert('error', notification.CREATE_FAIL, message);
                    }
                    return;
                }
                if (status === 1) {
                    showAlert('success', notification.CREATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert('error', notification.CREATE_FAIL, notification.MESSAGE_ERROR);
            }
        } else {
            try {
                const userCodes = adData.group_users.map((user) => user.code);
                adData.user_ids = userCodes;

                const result = await serviceGroup.update(adData);

                const { error, message, status } = result;
                if (status === 2) {
                    if (error) {
                        showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_VALIDATE);
                        setErrData(error);
                    } else {
                        showAlert('error', notification.UPDATE_FAIL, message);
                    }
                    return;
                }
                if (status === 1) {
                    showAlert('success', notification.UPDATE_SUCCESS, message);
                    fetData();
                    onHideDialog();
                }
            } catch (error) {
                showAlert('error', notification.UPDATE_FAIL, notification.MESSAGE_ERROR);
            }
        }
    };

    //Template
    const dialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-text p-button-raised"
                onClick={onHideDialog}
            />
            <Button label="Lưu" icon="pi pi-check" className="p-button-raised" onClick={saveData} />
        </React.Fragment>
    );

    return (
        <div>
            <Dialog
                visible={visible}
                header={typeAd === 1 ? titleDefault.GROUP_CREATE : titleDefault.GROUP_UPDATE}
                modal
                className="p-fluid p-dialog-default wr--30"
                footer={dialogFooter}
                onHide={onHideDialog}
            >
                <div className="p-field">
                    <label htmlFor="name" className="label-bold">
                        Tên nhóm <span className="text-danger">*</span>
                    </label>
                    <InputText
                        id="name"
                        value={adData.name || ''}
                        placeholder="Nhập tên nhóm"
                        onChange={(e) => updateField(e.target.value, 'name')}
                    />
                    <span id="username2-help" className="p-invalid p-d-block">
                        {errData.name}
                    </span>
                </div>
                <div className="p-field">
                    <label htmlFor="name" className="label-bold">
                        Tên hiển thị nhóm <span className="text-danger">*</span>
                    </label>
                    <InputText
                        id="display_name"
                        value={adData.display_name}
                        placeholder="Nhập tên hiển thị nhóm"
                        onChange={(e) => updateField(e.target.value, 'display_name')}
                    />
                    <span id="username2-help" className="p-invalid p-d-block">
                        {errData.display_name}
                    </span>
                </div>
                <div className="p-field">
                    <label htmlFor="description" className="label-bold">
                        Mô tả
                    </label>
                    <InputTextarea
                        id="description"
                        value={adData.description || ''}
                        placeholder="Nhập mô tả"
                        onChange={(e) => updateField(e.target.value, 'description')}
                        rows={3}
                        cols={20}
                    />
                    <span id="username2-help" className="p-invalid p-d-block">
                        {errData.description}
                    </span>
                </div>
                <div className=" p-field">
                    {/* Thành viên nhóm */}
                    <label htmlFor="user_id" className="align-sub-text label-bold">
                        Thành viên nhóm <span className="text-danger">*</span>
                    </label>
                    <MultiSelect
                        value={adData.group_users}
                        options={listUser}
                        id="user_ids"
                        onChange={(e) => updateField(e.target.value, 'group_users')}
                        optionLabel="name"
                        placeholder="Chọn người tham gia họp"
                        filter
                        maxSelectedLabels={1}
                        selectedItemsLabel="{0} người được chọn"
                    />
                    <span id="username2-help" className="p-invalid p-d-block">
                        {errData.user_ids}
                    </span>
                </div>
            </Dialog>
        </div>
    );
};
export default DialogAddEdit;
