import React, { useEffect, useState } from "react";
import Chart from 'react-apexcharts';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import moment from "moment";
import { useApp } from 'context/AppContext';
import queryString from 'query-string';
import notification from 'helpers/constant/notification';
const PieChart = props => {
    const { showAlert } = useApp();
    const {filter, jsonDataSearch, queryPie, history, serviceDashboard, setFilterPie} = props;

    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const [startDayUsePieChart, setStartDayUsePieChart] = useState(thirtyDaysAgo);
    const [endDayUsePieChart, setEndDayUsePieChart] = useState(today);

    const [dataPieChart, setDataPieChart] = useState({
        room: [],
        countBookingRoom: [], 
        totalAmountBookingRoom: 0,
        colorRoom: []
    });
    const [dataSearchPie, setDataSearchPie] = useState({
        start_day_pie_chart: "",
        end_day_pie_chart: "",
    });

    const handleChangeDataSearchPie = (e) => {
        setDataSearchPie({ ...dataSearchPie, [e.target.name]: e.target.value });
    }

    const onSearchClickPie = (e) => {
        e.preventDefault();

        const startDayFormattedPieChart = startDayUsePieChart ? moment(startDayUsePieChart).format('YYYY-MM-DD') : "";
        const endDayFormattedPieChart = endDayUsePieChart ? moment(endDayUsePieChart).format('YYYY-MM-DD') : "";

        const pramSearch = { 
            ...jsonDataSearch, 
            start_day_pie_chart: startDayFormattedPieChart,
            end_day_pie_chart: endDayFormattedPieChart,
        };
        const query = queryString.stringify(pramSearch);
        history.push({
            search: query,
        });
        setFilterPie(query);
    }

    window.onload = () => {
        history.push({
            search: "",
        });
    };
    const getDashboardDataPieChart = async () => {
        try {
            const dataDate = {
                ...dataSearchPie,
                ...queryPie 
            }
            // ValidateTime Donut Chart
            if(dataDate.start_day_pie_chart >= dataDate.end_day_pie_chart && dataDate.start_day_pie_chart && dataDate.end_day_pie_chart) {
                showAlert(
                    "error", 
                    notification.STATISTICAL_FAIL, 
                    notification.STATISTICAL_FAIL_FISRT
                );
                return;
            }

            const presentTime = new Date();
            const startDayRoomRatio = new Date(dataDate.start_day_pie_chart);
            if(dataDate.start_day_pie_chart && !dataDate.end_day_pie_chart && startDayRoomRatio > presentTime) {
                showAlert(
                    "error", 
                    notification.STATISTICAL_FAIL, 
                    notification.STATISTICAL_FAIL_SECOND
                );
                return;
            }
           
            const response = await serviceDashboard.pieChartBooking(dataDate);

            const { room, countBookingRoom, totalAmountBookingRoom, colorRoom } = response;
           
            if(response.status === 1 ){
                setDataPieChart({
                    room,
                    countBookingRoom,
                    totalAmountBookingRoom,
                    colorRoom
                });
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const pieChart = {
        series: dataPieChart.countBookingRoom,
        options: {
            chart: {
                type: 'donut',
            },
            labels: dataPieChart.room,
            plotOptions: {
                pie: {
                    donut: {
                        size: '40%',
                        labels: {
                            show: true,
                            total: {
                                showAlways: true,
                                show: true,
                            }
                        }
                    },
                },
              },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 300
                    },
                    legend: {
                        position: 'bottom'
                    }
                },
            }],
            colors: dataPieChart.colorRoom,
            legend: {
                position: 'bottom',
                offsetX: 0,
                offsetY: 0,
            },
        },
    };

    useEffect(() => {
        getDashboardDataPieChart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);


    return (
        <div className="p-col-12 p-xl-6 p-py-3 chart-home pie-chart">
            <div className="p-d-flex p-justify-between p-pb-4">
                <h5 className="title-chart p-mb-0 p-col-6 p-md-6">
                    Thống kê số lượt đặt phòng <br/>
                    <span className="sub-title-chart-detail">
                        ( Mặc định hiển thị: <span>30 ngày gần nhất</span> )
                    </span>
                </h5>
                <form className="p-col-6 p-md-6 p-d-flex form-search-chart" onSubmit={(e) => onSearchClickPie(e)}>
                    <div className="p-inputgroup p-col-5 p-md-5 p-p-0 ">
                        <Calendar
                            value={startDayUsePieChart} 
                            onChange={(e) => {
                                    setStartDayUsePieChart(e.value) 
                                    handleChangeDataSearchPie(e);
                                }
                            } 
                            dateFormat="dd/mm/yy"
                            // maxDate={new Date()}
                            name="start_day_pie_chart"
                            className="color-input-custom"
                            placeholder="Từ ngày"
                        />
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-calendar" />
                        </span>
                    </div>
                    <div className="p-inputgroup p-col-5 p-md-5 p-p-0">
                        <Calendar
                            value={endDayUsePieChart} 
                            onChange={(e) => {
                                    setEndDayUsePieChart(e.value) 
                                    handleChangeDataSearchPie(e);
                                }
                            } 
                            dateFormat="dd/mm/yy"
                            name="end_day_pie_chart"
                            className="color-input-custom"
                            placeholder="Đến ngày"
                        />
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-calendar" />
                        </span>
                    </div>
                    <div className="p-inputgroup p-col-2 p-md-2 p-p-0">
                        <Button icon="pi pi-search"
                            className="btn-search-home" 
                            severity="success" 
                            aria-label="Search" 
                            title="Tìm kiếm" 
                            onClick={(e) => onSearchClickPie(e)} 
                        />  
                    </div>
                </form>
            </div>
            <div className="p-d-flex p-justify-center">
                <Chart
                    options={pieChart.options} 
                    series={pieChart.series} 
                    type="donut" 
                    width={550}
                    height={500}
                />
            </div>
        </div>           
    );
}

export default PieChart