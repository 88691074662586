import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types';
import notification from 'helpers/constant/notification';
import titleDefault from 'helpers/constant/titleDefault';

function DialogDelete(props) {
    //Const
    const {
        dialogDelete,
        setDialogDelete,
        fetData,
        serviceGroup,
        showAlert,
        adData,
        typeDelete,
        onDeletes,
        selectedDatas,
    } = props;
    let idDeletes = [];

    //Handle
    const onHideDialog = () => {
        setDialogDelete(false);
    };

    const handelChangeDelete = async () => {
        if (typeDelete === 1) {
            try {
                const response = await serviceGroup.delete(adData);
                const { status, message } = response;
                onHideDialog();
                if (status === 2) {
                    showAlert('error', notification.DELETE_FAIL, message);
                }
                if (status === 1) {
                    showAlert('success', notification.DELETE_SUCCESS, message);
                    fetData();
                }
            } catch (error) {
                onHideDialog();
                showAlert('error', notification.DELETE_FAIL, notification.MESSAGE_ERROR);
            }
        } else {
            try {
                selectedDatas.map((p) => idDeletes.push(p.uuid));
                let deletesData = {
                    uuids: idDeletes,
                };
                const response = await serviceGroup.deleteMultiple(deletesData);
                idDeletes = [];
                const { status, message } = response;
                onHideDialog();
                if (status === 2) {
                    showAlert('error', notification.DELETE_FAIL, message);
                }
                if (status === 1) {
                    showAlert('success', notification.DELETE_SUCCESS, message);
                    onDeletes();
                }
            } catch (error) {
                onHideDialog();
                showAlert('error', notification.DELETE_FAIL, notification.MESSAGE_ERROR);
            }
        }
    };

    //Template
    const deleteDialogFooter = (
        <React.Fragment>
            <Button
                label="Hủy"
                icon="pi pi-times"
                className="p-button-raised p-button-text p-button-danger"
                onClick={onHideDialog}
            />
            <Button
                label="Xóa"
                icon="pi pi-check"
                className="p-button-danger p-button-raised"
                onClick={handelChangeDelete}
            />
        </React.Fragment>
    );

    return (
        <div>
            <Dialog
                visible={dialogDelete}
                className="p-dialog-default"
                header={titleDefault.GROUP_DELETE}
                modal
                footer={deleteDialogFooter}
                onHide={onHideDialog}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {adData.name && typeDelete === 1 && (
                        <span>
                            Bạn có chắc chắn muốn xóa nhóm <b>{adData.name}</b>?
                        </span>
                    )}
                    {typeDelete === 2 && <span>Bạn có chắc chắn muốn xóa nhiều nhóm?</span>}
                </div>
            </Dialog>
        </div>
    );
}

DialogDelete.propTypes = {
    adData: PropTypes.object,
};
DialogDelete.defaultProps = {
    adData: {
        name: '',
    },
};
export default DialogDelete;
