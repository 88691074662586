

const hour = 24;
let minutes = 0;

function getMinutes(default_min_time_split) {
    switch (default_min_time_split) {
        case 1:
            minutes = 5;
            break;
        case 2:
            minutes = 10;
            break;
        case 3:
            minutes = 15;
            break;
        default:
            minutes = 30;
            break;
    }
    return minutes;
}
function getTimePlace(value, listConfig) {
    if (value.length > 0) {
        return value;
    }
    const { default_min_time_split } = listConfig;
    getMinutes(default_min_time_split);
    const phep_tinh = 60 / minutes;
    const arr_minutes = [];
    for (let i = 0; i < phep_tinh; i++) {
        arr_minutes.push(i * minutes);
    }
    const arr_hour = [];
    for (let i = 0; i < hour; i++) {
        arr_hour.push(i);
    }
    const timePlace = [];
    for (const h of arr_hour) {
        for (const m of arr_minutes) {
            const gio = `0${h}`.slice(-2);
            const phut = `0${m}`.slice(-2);
            const time = `${gio}:${phut}`;
            timePlace.push({ code: time, name: time })
        }
    }
    return timePlace;

}

export default getTimePlace;

