import FetchAPI from "../helpers/FetchAPI";
import { TypeMethodConstantEnum } from "../helpers/MethodAPIConstant";

class RoleServices extends FetchAPI {
    //Lấy danh sách khách hàng
    public async getListRole(data) {
        const url = `vai-tro/index`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async getAllPermission(data) {
        const url = `vai-tro/getAllPermission`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async onCreateRole(data) {
        const url = `vai-tro/create`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async onUpdateRole(data) {
        const url = `vai-tro/update`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }

    public async onDeleteRole(data) {
        const url = `vai-tro/delete`;
        return await this.request(url, data, TypeMethodConstantEnum.GET);
    }

    public async onDeleteMultiple(data) {
        const url = `vai-tro/deleteMultiple`;
        return await this.request(url, data, TypeMethodConstantEnum.POST);
    }
    public async getAll() {
        const url = `vai-tro/getAllRole`;
        return await this.request(url, {}, TypeMethodConstantEnum.GET);
    }
}
export default RoleServices;
