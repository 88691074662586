import React from 'react';

const AppFooter = () => {
    return (
        <div className="layout-footer p-d-flex p-flex-wrap p-text-fw-600">
            <div className="layout-footer-left p-d-flex p-flex-wrap">
                <div className="p-mb-2">Copyright © 2020 <a href="https://cyberlotus.com" target="_blank" rel="noopener noreferrer">CyberLotus</a> , All rights reserved.</div>
            </div>

            <div className="layout-footer-right p-d-flex p-flex-wrap">
                <div className="p-mr-2 p-mb-2">
                     CyberJoom
                </div>
                <div className="p-mb-2">Phiên bản{" "}{require('../../../package.json') && require('../../../package.json').version}</div>
            </div>
        </div>
    )
}

export default AppFooter
