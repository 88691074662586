import { APP_ECONTRACT } from 'constants/common/global';
import Keycloak from 'keycloak-js';
const windowEnvConfig = window.runConfig;

const check = window.location.host.includes(APP_ECONTRACT);

const BASE_URL_KC = windowEnvConfig.REACT_APP_KC_URL;
const REAL_KC = windowEnvConfig.REACT_APP_KC_REALM;
const CLIENT_ID_KC = check
    ? windowEnvConfig.REACT_APP_KC_CLIENT_ID_CYBERCONTRACT
    : windowEnvConfig.REACT_APP_KC_CLIENT_ID;

const keycloakConfig = {
    url: BASE_URL_KC,
    realm: REAL_KC,
    clientId: CLIENT_ID_KC,
};
const keycloak = new Keycloak(keycloakConfig);
export default keycloak;
